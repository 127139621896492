import { useState } from 'react';
import useDidMountEffect from './useDidMountEffect';

type Validator = {
  validate: (inputValue: string) => boolean;
  validationText: string;
};

type Validation = {
  isValid: boolean;
  validationText: string;
};

const useValidation = (validators: Array<Validator>, inputText: string = '') => {
  const [inputState, setInputState] = useState(inputText);
  const [isValid, setIsValid] = useState(true);
  const [validationText, setValidationText] = useState('');

  const validInput = (): boolean => {
    const validText = validators.find((v) => !v.validate(inputState))?.validationText;
    if (validText) {
      setIsValid(false);
      setValidationText(validText);
      return false;
    }
    setIsValid(true);
    setValidationText('');
    return true;
  };

  useDidMountEffect(() => {
    validInput();
  }, [inputState]);

  const validation: Validation = {
    isValid,
    validationText,
  };

  return {
    inputState,
    setInputState,
    validInput,
    validation,
  };
};

export { useValidation };
export type { Validation, Validator };
