import React, { useEffect, useState } from 'react';
import store from '@store/index';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import getSortedServices, {
  SortedMusicService,
} from '@store/slices/music-services/musicService.utils';
import { beginTransferActions } from '@store/slices/begin-transfer/beginTransferSlice';
import SelectDestinationServiceButton from './SelectDestinationServiceButton';
import styles from './DestinationServicesList.module.scss';

type Props = {
  query: string;
};

/**
 * List of destination service, where transfer to is allowed.
 *
 * @param query - to show services due to search query
 */
const DestinationServicesList = ({ query }: Props) => {
  const { selectedService, selectedTab } = store.getState().library;
  if (!selectedService) {
    throw Error('Source service not selected');
  }
  if (!selectedTab) {
    throw Error('Source service tab not selected');
  }

  const loggedServices = useAppSelector((state) => state.musicServices.loggedServices);
  const [destList, setDestList] = useState<SortedMusicService[]>([]);
  useEffect(() => {
    const sortedServices = getSortedServices().filter(
      (s) => s.data.type !== selectedService.type
        && s.data.destinationData.includes(selectedTab)
        && s.data.title.toLowerCase().includes(query.toLowerCase()),
    );
    setDestList(sortedServices);
  }, [query, loggedServices]);

  const dispatch = useAppDispatch();
  dispatch(beginTransferActions.selectSourceService(selectedService));

  return (
    <div className={styles.grid_tiles}>
      {destList.map((service) => (
        <SelectDestinationServiceButton
          key={service.data.title}
          service={service.data}
          email={service.isConnected ? service.loggedData.email : undefined}
          isConnected={service.isConnected}
        />
      ))}
    </div>
  );
};
export default DestinationServicesList;
