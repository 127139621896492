import React from 'react';
import MarqueeText from '@components/MarqueeText';
import connectedIcon from '@features/modal-windows/assets/success-icon.svg';
import { $allServicesModalText as text } from '@data/text.constants';
import { useGlobalModalContext } from 'src/contexts';
import { useServiceAuthorization } from '@features/service-authorization/hooks';
import { useAppNavigation } from '@hooks/index';
import { MusicServiceData } from '@store/slices/music-services/musicService.types';
import styles from './styles/SourceServiceButton.module.scss';

type Props = {
  service : MusicServiceData;
  isConnected: boolean;
  email?: string;
};

/**
 * Button of source service.
 *
 * @param service - source service.
 * @param email - user service email.
 * @param isConnected - is service connected
 */
const SourceServiceButton = ({
  service, email, isConnected,
}: Props) => {
  const { hideModal } = useGlobalModalContext();
  const navigate = useAppNavigation();
  const { authorizeMusicService } = useServiceAuthorization();

  const selectService = async () => {
    if (isConnected) {
      navigate.toLibrary(service.type);
    } else {
      await authorizeMusicService(service.type);
    }
    hideModal();
  };

  return (
    <div
      className={styles.button}
      role="presentation"
      onClick={selectService}
    >
      <img className={styles.service_icon} src={service.logo} alt={service.logo.toString()} />
      {email && (
        <div className={styles.marquee_container}>
          <MarqueeText text={email} />
        </div>
      )}
      {isConnected && (
      <div className={styles.connected_service}>
        <img className={styles.connected_img} src={connectedIcon} alt="icon" />
        <div className={styles.connected_text}>{text.connected}</div>
      </div>
      )}
    </div>
  );
};

export default SourceServiceButton;
