import React from 'react';
import { $transferProcessModalText } from '@data/text.constants';
import styles from './CancelButton.module.scss';

type Props = {
  onClick: () => void;
};

const CancelButton = ({ onClick }: Props) => (
  <div className={styles.container_border} onClick={onClick} role="presentation">
    <div className={styles.text}>{$transferProcessModalText.cancel}</div>
    <div className={styles.button} />
  </div>
);

export default CancelButton;
