import {
  createContext, useContext,
} from 'react';
import { ModalType } from './ModalContext.types';

type ModalContextState = {
  showModal: (modalType: ModalType) => void;
  hideModal: () => void;
};

const initialState: ModalContextState = {
  showModal: () => {
  },
  hideModal: () => {
  },
};

const ModalContext = createContext(initialState);
const useGlobalModalContext = () => useContext(ModalContext);

export default ModalContext;
export { useGlobalModalContext };
