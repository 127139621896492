import React from 'react';
import { useNavigate } from 'react-router-dom';
import { $passwordResetSuccessText } from '@data/text.constants';
import $route from '@data/routing.constants';
import { StyledButton } from '@components/index';
import animation from '@assets/animations/password_reset_success.json';
import styles from './styles/PasswordResetSuccessForm.module.scss';
import IconContainer from '../IconContainer';

const PasswordResetSuccessForm = () => {
  const navigate = useNavigate();
  return (
    <IconContainer icon={animation} title={$passwordResetSuccessText.title}>
      <div className={styles.info}>
        {$passwordResetSuccessText.info}
        <br />
        <br />
        {$passwordResetSuccessText.note}
      </div>
      <div className={styles.to_home_btn}>
        <StyledButton
          title={$passwordResetSuccessText.backToHome}
          onClick={() => { navigate($route.home); }}
        />
      </div>
    </IconContainer>
  );
};

export default PasswordResetSuccessForm;
