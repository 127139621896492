import React from 'react';
import styles from './styles/StyledCheckBox.module.scss';

interface StyledCheckBoxProps {
  isChecked: boolean;
  onChange: () => void;
}

const StyledCheckBox = ({ isChecked, onChange } : StyledCheckBoxProps) => (
  <input
    className={styles.checkbox}
    type="checkbox"
    checked={isChecked}
    onChange={onChange}
  />
);

export default StyledCheckBox;
