import React from 'react';
import {
  LoggedMusicService,
} from '@store/slices/music-services/musicService.types';
import { libraryActions } from '@store/slices/library/librarySlice';
import { useAppDispatch } from '@store/hooks';
import { getLoggedServiceData } from '@store/slices/music-services/musicService.utils';
import { useAppNavigation } from '@hooks/index';
import styles from './styles/LoggedServices.module.scss';
import LoggedServiceButton from '../buttons/LoggedServiceButton';

type Props = {
  loggedServices: Array<LoggedMusicService>;
};

const LoggedServicesList = ({ loggedServices }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useAppNavigation();
  return (
    <div className={styles.scroller}>
      <div className={styles.services_container}>
        {loggedServices.map((service) => {
          const serviceData = getLoggedServiceData(service);
          return (
            <LoggedServiceButton
              key={serviceData.title}
              email={service.email}
              serviceIcon={serviceData.logo}
              onClick={() => {
                dispatch(libraryActions.selectService(service));
                navigate.toLibrary(service.type);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default LoggedServicesList;
