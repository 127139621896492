import React, { ReactNode } from 'react';
import ReactModal from 'react-modal';
import { ModalProps } from '../types/modalProps';
import styles from './ModalStyleBase.module.scss';

interface Props extends ModalProps {
  /** Any of the HTML elements inside modal */
  children: ReactNode;
}

/**
 * Base Modal window realization.
 *
 * Use it to show modal window and pass children element inside.
 * @public
 */
const Modal = ({
  isOpen, isClosing, closeModal, children,
}: Props) => {
  if (!isOpen) {
    return null;
  }
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      overlayClassName={`${styles.overlay} ${isClosing ? styles.overlay_fade_out : ''}`}
      className={`${styles.modal} ${isClosing ? styles.modal_fade_out : ''}`}
      ariaHideApp={false}
    >
      {children}
    </ReactModal>
  );
};

export default Modal;
