import React from 'react';
import { Navigate } from 'react-router-dom';
import $route from '@data/routing.constants';
import { useAppSelector } from '@store/hooks';

type Props = {
  children: JSX.Element;
};

const LoggedRoute = ({ children }: Props) => {
  const isAuth = useAppSelector((s) => s.user.isAuth);
  return (
    (isAuth
      ? children
      : <Navigate to={$route.login} />
    )
  );
};

export default LoggedRoute;
