import { useState } from 'react';

/**
 * Modal hook to manage modal states (open \ close)
 */
export default function useModal() {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);

  const [isClosing, setIsClosing] = useState(false);
  const closeModal = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsOpen(false);
      setIsClosing(false);
    }, 150);
  };

  return {
    isOpen,
    isClosing,
    openModal,
    closeModal,
  };
}
