import React from 'react';
import { useMobile } from '@hooks/index';
import { $mainPageText } from '@data/text.constants';
import { useAppSelector } from '@store/hooks';
import PreviousButton from './buttons/PreviousButton';
import NextButton from './buttons/NextButton';
import styles from './styles/SiteNavigationForm.module.scss';

const SiteNavigationForm = () => {
  const { isMobile } = useMobile();
  const { license } = useAppSelector((state) => state.user);
  return (
    <div className={styles.container}>
      {!isMobile && (
        <>
          <PreviousButton />
          <NextButton />
        </>
      )}
      <div className={styles.text_container}>
        <div className={styles.text}>{$mainPageText.main}</div>
        <div className={styles.license_text}>{license}</div>
      </div>
    </div>
  );
};

export default SiteNavigationForm;
