import React from 'react';
import SidebarModal from '@features/main-page/components/sidebar/SidebarModal';
import MenuButton from '@features/main-page/components/buttons/MenuButton';
import { useTablet } from '@hooks/responsive';
import { useLocation, useParams } from 'react-router-dom';
import ServiceSidebarModal from '@features/library-page/service-sidebar/ServiceSidebarModal';
import TransferHeader from '@features/transfer-page/components/TransferHeader';
import { useAppSelector } from '@store/hooks';
import { getLoggedServiceData } from '@store/slices/music-services/musicService.utils';
import useModal from '@features/modal-windows/hooks/useModal';
import isTrialLicense from '@store/slices/user/user.utils';
import { PremiumButton, SiteNavigationForm, UserDropdown } from '../../index';
import styles from './styles/DesktopHeader.module.scss';

const DesktopHeader = () => {
  const {
    isOpen,
    isClosing,
    openModal,
    closeModal,
  } = useModal();
  const { isVerticalTablet } = useTablet();
  const isLibraryPage = useLocation().pathname.includes('library');
  const { service } = useParams();
  const selectedService = useAppSelector((state) => state.musicServices.loggedServices
    .find((loggedService) => loggedService.type === service));
  // const isTransferring = useAppSelector((state) => state.transfer.status === 'inProgress');
  return (
    <>
      {isVerticalTablet && (
        <>
          <SidebarModal isOpen={isOpen} isClosing={isClosing} closeModal={closeModal} />
          {isLibraryPage && selectedService && (
            <ServiceSidebarModal
              isOpen={isOpen}
              isClosing={isClosing}
              closeModal={closeModal}
              serviceLogo={getLoggedServiceData(selectedService).logo}
              userAvatar={selectedService.avatar}
              email={selectedService.email}
            />
          )}
          <div className={styles.menu_container}>
            <MenuButton onClick={openModal} />
          </div>
        </>
      )}

      <SiteNavigationForm />
      <div className={styles.buttons_container}>
        {/* transfer header for desktop
        {!isVerticalTablet && isTransferring && (
          <div className={styles.transfer_header_container}>
            <TransferHeader />
          </div>
        )} */}
        {isTrialLicense()
          && <PremiumButton />}
        <UserDropdown />
      </div>
    </>
  );
};

export default DesktopHeader;
