import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  ErrorMessage,
} from '@components/index';
import SiteLoader from '@components/SiteLoader';
import { $callbackLoaderText } from '@data/text.constants';
import useServiceCallback from '../../hooks/useServiceCallback';
import styles from './styles/CallbackForm.module.scss';

const CallbackForm = () => {
  const { service } = useParams();
  const [searchParams] = useSearchParams();

  const {
    status,
    error,
    serviceCallback,
  } = useServiceCallback();

  useEffect(() => {
    serviceCallback(service as string, searchParams);
  }, []);

  return (
    <>
      { status !== 'loading' && (
        <div className={styles.container}>
          <SiteLoader
            headerText={$callbackLoaderText.authProgressing}
            subText={$callbackLoaderText.plsWait}
          />
        </div>
      ) }
      { status === 'error' && window.close() }
      {/* && <ErrorMessage message={error} /> } */}
      { status === 'success' && window.close() }
    </>
  );
};

export default CallbackForm;
