enum MusicService {
  unknown = 'Unknown',
  spotify = 'Spotify',
  deezer = 'Deezer',
  amazonMusic = 'AmazonMusic',
  soundCloud = 'SoundCloud',
  tidal = 'Tidal',
  appleMusic = 'AppleMusic',
  youTube = 'YouTube',
  youTubeMusic = 'YouTubeMusic',
  napster = 'Napster',
}

export default MusicService;
