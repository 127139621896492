import { RootState } from '@store/index';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CredentialsModel } from '@services/types/webapi.types';
import MusicService from '@data/enums/musicService';
import {
  LoggedMusicService,
} from '@store/slices/music-services/musicService.types';
import $allMusicServices from '@store/slices/music-services/musicService.data';
import { getMusicServiceUserService } from '../../../inversify.config';

type MusicServicesConfig = {
  rejectValue: string;
  state: RootState;
};

type ReturnType = {
  selectedService: LoggedMusicService;
};

type UserInfo = {
  email?: string;
  nickName?: string;
  avatar?: string;
};

const refreshLoggedServiceAsync = createAsyncThunk<
LoggedMusicService[],
void,
MusicServicesConfig>(
  'music-service/refreshServices',
  async (arg, thunkAPI) => {
    const musicServicesService = getMusicServiceUserService();
    const loggedServicesResponse = await musicServicesService.getAuthorizedServices();
    if (loggedServicesResponse.type === 'error') {
      return thunkAPI.rejectWithValue(`Server error: ${loggedServicesResponse.message}`);
    }

    const loggedServicesCredentials = loggedServicesResponse.credentials;
    let loggedServicesData: LoggedMusicService[] = [];
    // eslint-disable-next-line consistent-return
    await Promise.all(loggedServicesCredentials.map(async (credential) => {
      const response = await musicServicesService.getUserProfile(credential);
      if (response.type === 'error') {
        return thunkAPI.rejectWithValue(response.message);
      }

      loggedServicesData = [...loggedServicesData, {
        userId: credential.serviceUserId,
        type: credential.musicService,
        email: response.email,
        nickName: response.displayName,
        avatar: response.imageLink,
      } as LoggedMusicService,
      ];
    }));
    const sortedLoggedServices: LoggedMusicService[] = [];
    for (let i = 0; i < $allMusicServices.length; i++) {
      const service = $allMusicServices[i];
      const foundService = loggedServicesData
        .find((loggedService) => loggedService.type === service.type);
      if (foundService) {
        sortedLoggedServices.push(foundService);
      }
    }

    return sortedLoggedServices;
  },
);

const logInServiceAsync = createAsyncThunk<UserInfo, CredentialsModel, MusicServicesConfig>(
  'music-services/logInService',
  async (arg, thunkAPI) => {
    const musicServicesService = getMusicServiceUserService();
    const userInfo = await musicServicesService.getUserProfile(arg);

    if (userInfo.type === 'error') {
      return thunkAPI.rejectWithValue(userInfo.message);
    }

    return {
      email: userInfo.email,
      nickName: userInfo.displayName,
      avatar: userInfo.imageLink,
    };
  },
);

const logOutServiceAsync = createAsyncThunk< ReturnType, void, MusicServicesConfig>(
  'music-services/logOutService',
  async (arg, thunkAPI) => {
    const state: RootState = thunkAPI.getState();
    const musicServicesService = getMusicServiceUserService();
    const { selectedService } = state.library;
    if (!selectedService) {
      throw Error('No service is selected.');
    }
    const serviceCreds = {
      musicService: selectedService.type,
      serviceUserId: selectedService.userId,
    } as CredentialsModel;
    await musicServicesService.removeCredentials([serviceCreds]);

    if (selectedService.type === MusicService.appleMusic) {
      // Clearing local storage to log out Apple
      Object.keys(localStorage)
        .filter((key) => key.startsWith('music.'))
        .forEach((key) => localStorage.removeItem(key));
    }

    return {
      selectedService,
    };
  },
);

export {
  logInServiceAsync,
  logOutServiceAsync,
  refreshLoggedServiceAsync,
};
