import React from 'react';
import defaultItemImage from '@assets/icons/default-playlist-icon.svg';
import formatTrackCountString from 'src/utils/formatTrackCountString';
import { Tooltip } from 'react-tooltip';
import { useDesktop } from '@hooks/responsive';
import styles from './styles/MediaItemsList.module.scss';

export type MediaItem = {
  id: string;
  imageLink?: string;
  title: string;
  tracksCount?: number;
  albumName?: string;
  artist?: string;
  type: string;
  statusImage?: string;
};

type MediaItemsListProps = {
  items: MediaItem[];
};

const MediaItemsList = ({ items }: MediaItemsListProps) => {
  const { isDesktop } = useDesktop();
  return (
    <div className={styles.list_container}>
      <ul className={styles.list}>
        {items.map((x) => (
          <li className={styles.list_item} key={x.id}>
            <img
              className={styles.item_img}
              alt="avatar"
              src={x.imageLink ?? defaultItemImage}
              onError={(event) => {
                event.currentTarget.src = defaultItemImage;
                return event.currentTarget.src;
              }}
            />
            <div className={styles.item_info}>
              <div
                data-tooltip-id={x.title}
                className={styles.main_info}
                data-tooltip-content={x.title}
                data-tooltip-hidden={!isDesktop}
              >
                {x.title}
              </div>
              <Tooltip id={x.title} className={styles.tooltip} />
              <div className={styles.add_info}>
                {x.tracksCount !== null && (x.tracksCount || x.tracksCount === 0) && (
                <div>
                  {formatTrackCountString(x.tracksCount!)}
                </div>
                )}
                <div>
                  {x.artist}
                </div>
                <div>
                  {x.albumName}
                </div>
                <div>
                  {x.type}
                </div>
              </div>
            </div>
            {x.statusImage && (
            <img className={styles.status_img} alt="status" src={x.statusImage} />
            )}
          </li>
        ))}
      </ul>
    </div>

  );
};

export default MediaItemsList;
