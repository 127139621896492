import React from 'react';
import styles from './styles/DropdownItem.module.scss';

type Props = {
  text: string;
  link: string;
  onClick?: () => void;
  openNewTab?: boolean;
};

const DropdownItem = ({
  text, link, openNewTab, onClick,
}: Props) => {
  const target = openNewTab ? '_blank' : '';
  const rel = openNewTab ? 'noreferrer' : '';

  return (
    <li className={styles.dropdownItem}>
      <a className={styles.item} target={target} rel={rel} href={link} onClick={onClick}>
        {text}
      </a>
    </li>
  );
};

export default DropdownItem;
