import React from 'react';
import { useNavigate } from 'react-router-dom';
import { $resetPasswordText } from '@data/text.constants';
import $route from '@data/routing.constants';
import { StyledButton, StyledInput } from '@components/index';
import animation from '@assets/animations/password_reset.json';
import { useResetPassword } from '@features/authorization/hooks';
import styles from './styles/ResetPasswordForm.module.scss';
import IconContainer from '../IconContainer';
import { usePasswordValidation } from '../../hooks';
import { useConfirmPasswordValidation } from '../../hooks/validations';
import { useGlobalModalContext } from '../../../../contexts';

type Props = {
  email: string;
  secret: string;
};

const ResetPassword = ({ email, secret }: Props) => {
  const navigate = useNavigate();

  const {
    passwordField,
    setPasswordField,
    validPasswordField,
    passwordValidation,
  } = usePasswordValidation();

  const {
    confirmPasswordField,
    setConfirmPasswordField,
    validConfirmPasswordField,
    confirmPasswordValidation,
  } = useConfirmPasswordValidation(passwordField);

  const { showModal } = useGlobalModalContext();

  const {
    status,
    send,
    error,
  } = useResetPassword();

  const resetPassword = async () => {
    if (!validPasswordField() || !validConfirmPasswordField()) {
      return;
    }

    await send(email, passwordField, secret);
    if (status === 'success') {
      navigate($route.resetPasswordSuccess);
      return;
    }

    if (status === 'error') {
      showModal(
        {
          type: 'message_box',
          props: {
            title: 'Error',
            description: error,
          },
        },
      );
    }
  };

  return (
    <IconContainer icon={animation} title={$resetPasswordText.title}>
      <div className={styles.reset_password_container}>
        <StyledInput
          type="password"
          title={$resetPasswordText.password}
          placeholder={$resetPasswordText.password}
          value={passwordField}
          onChange={(e) => setPasswordField(e.target.value)}
          validation={passwordValidation}
        />
        <div className={styles.confirm_input}>
          <StyledInput
            type="password"
            title={$resetPasswordText.confirmPassword}
            placeholder={$resetPasswordText.confirmPassword}
            value={confirmPasswordField}
            onChange={(e) => setConfirmPasswordField(e.target.value)}
            validation={confirmPasswordValidation}
          />
        </div>
        <div className={styles.reset_btn}>
          <StyledButton title={$resetPasswordText.resetPassword} onClick={resetPassword} />
        </div>
      </div>
    </IconContainer>
  );
};

export default ResetPassword;
