import React from 'react';
import burgerIcon from '@features/main-page/assets/burger-menu.svg';
import styles from './styles/MenuButton.module.scss';

type Props = {
  onClick: () => void;
};
const MenuButton = ({ onClick }: Props) => (
  <button className={styles.menu} type="button" onClick={onClick}>
    <img className={styles.icon} src={burgerIcon} alt="menu" />
  </button>
);

export default MenuButton;
