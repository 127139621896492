import React, { useEffect } from 'react';
import { EmailConfirmForm, MusConvHeaderAuth } from '@features/authorization';
import store from '@store/index';
import { useNavigate } from 'react-router-dom';
import styles from './styles/Header.module.scss';

const EmailConfirm = () => {
  const { email } = store.getState().register;
  const navigation = useNavigate();
  useEffect(() => {
    if (email === undefined) {
      navigation('/not-found');
    }
  }, []);

  return (
    <>
      <div className={styles.header}>
        <MusConvHeaderAuth height="52" width="170" />
      </div>
      <EmailConfirmForm email={email ?? ''} />
    </>
  );
};
export default EmailConfirm;
