import { createAsyncThunk } from '@reduxjs/toolkit';
import { CredentialsModel } from '@services/types/webapi.types';
import { ILibraryService } from '@services/library/LibraryService.interface';
import { ITransferService } from '@services/transfer/TransferService.interface';
import { getTransferMediaItems } from '@store/slices/transfer/transfer.utils';
import store, { RootState } from '@store/index';
import { InitTransferModel } from '@services/transfer/TransferService.types';
import { AlbumSimplified, Artist, MusicItemMinimal } from '@services/types/music.items.types';
import {
  getLibraryService,
  getTransferService,
  getTransferStatusService,
} from '../../../inversify.config';

type TransferConfig = {
  rejectValue: string;
  state: RootState;
};

type ReturnType = {
  transferId: string;
};

/**
 * Method to transfer selected tracks in media item.
 */
const transferItemWithTracks = async (
  transferService: ITransferService,
  sourceCreds: CredentialsModel,
  destinationCreds: CredentialsModel,
  mediaItemType: string,
) => {
  const mediaItem = store.getState().libraryItem.items;
  let transferIdResponse;
  switch (mediaItemType) {
    case 'playlist': {
      /* transferIdResponse = await transferService.transferPlaylists(
              sourceCreds,
              destinationCreds,
              mediaItem.map((i) => ({
                id: i.id,
                title: i.title,
                description: i.description,
                tracks: i.tracks.filter((track) => track.isSelected).map((t) => ({
                  id: t.id,
                } as MusicItemMinimal)),
              } as PlaylistMinimal)),
            ); */
      break;
    }
    case 'album': {
      transferIdResponse = await transferService.transferAlbumModels(
        sourceCreds,
        destinationCreds,
        mediaItem.map((i) => ({
          id: i.id,
          title: i.title,
          artist: i.tracks[0].artist,
          artistId: i.tracks[0].artistId,
        } as AlbumSimplified)),
      );
      break;
    }
    case 'artist': {
      transferIdResponse = await transferService.transferArtistModels(
        sourceCreds,
        destinationCreds,
        mediaItem.map((i) => ({
          id: i.id,
          title: i.title,
        } as Artist)),
      );
      break;
    }
    default:
      throw Error('Unsupported type transfer');
  }
  if (!transferIdResponse) {
    throw Error('Failed to get transfer id');
  }
  if (transferIdResponse.type === 'error') {
    throw Error(transferIdResponse.message);
  }
  return transferIdResponse.transferId;
};

const transferItems = async (
  libraryService: ILibraryService,
  transferService: ITransferService,
  sourceCreds: CredentialsModel,
  destinationCreds: CredentialsModel,
) => {
  const mediaItems = getTransferMediaItems();
  const mediaItemType = mediaItems.map((mediaItem) => mediaItem.type)[0];
  const { openedMediaItemId } = store.getState().libraryItem;
  if (openedMediaItemId) {
    return transferItemWithTracks(
      transferService,
      sourceCreds,
      destinationCreds,
      mediaItemType,
    );
  }
  let transferIdResponse: InitTransferModel;
  switch (mediaItemType) {
    case 'playlist': {
      transferIdResponse = await transferService.transferPlaylists(
        sourceCreds,
        destinationCreds,
        mediaItems.map((statePlaylist) => ({
          id: statePlaylist.id,
        })),
      );
      if (transferIdResponse.type === 'error') {
        throw Error(transferIdResponse.message);
      }
      return transferIdResponse.transferId;
    }
    case 'album': {
      transferIdResponse = await transferService.transferAlbums(
        sourceCreds,
        destinationCreds,
        mediaItems.map((stateAlbum) => ({
          id: stateAlbum.id,
        })),
      );
      if (transferIdResponse.type === 'error') {
        throw Error(transferIdResponse.message);
      }
      return transferIdResponse.transferId;
    }
    case 'artist': {
      transferIdResponse = await transferService.transferArtists(
        sourceCreds,
        destinationCreds,
        mediaItems.map((stateArtist) => ({
          id: stateArtist.id,
        })),
      );
      if (transferIdResponse.type === 'error') {
        throw Error(transferIdResponse.message);
      }
      return transferIdResponse.transferId;
    }
    case 'track': {
      transferIdResponse = await transferService.transferTracks(
        sourceCreds,
        destinationCreds,
        mediaItems.map((mediaItem) => ({ id: mediaItem.id }) as MusicItemMinimal),
      );
      if (transferIdResponse.type === 'error') {
        throw Error(transferIdResponse.message);
      }
      return transferIdResponse.transferId;
    }
    default:
      break;
  }

  throw Error('Unknown media item type');
};

const startTransferAsync = createAsyncThunk<ReturnType, void, TransferConfig>(
  'transfer/transferMediaItems',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState();
    const transferState = state.beginTransfer;
    const transferService = getTransferService();
    const libraryService = getLibraryService();
    if (!transferState.sourceService || !transferState.destinationService) {
      throw Error('Source or destination service not selected.');
    }

    const sourceCreds = {
      musicService: transferState.sourceService.type,
      serviceUserId: transferState.sourceService.userId,
    } as CredentialsModel;
    const destinationCreds = {
      musicService: transferState.destinationService.type,
      serviceUserId: transferState.destinationService.userId,
    } as CredentialsModel;
    let transferId;
    try {
      transferId = await transferItems(
        libraryService,
        transferService,
        sourceCreds,
        destinationCreds,
      );
    } catch (error) {
      let message;
      if (error instanceof Error) {
        message = error.message;
      } else {
        message = String(error);
      }
      return thunkAPI.rejectWithValue(message);
    }
    if (!transferId) {
      throw Error('No transfer id from server');
    }

    const currentTransferState = thunkAPI.getState().beginTransfer;
    if (currentTransferState.status === 'canceled') {
      await transferService.cancelTransfer(transferId);
      return thunkAPI.rejectWithValue('Transfer was canceled.');
    }

    return {
      transferId,
    };
  },
);

const cancelTransferAsync = createAsyncThunk<void, Array<string> | undefined, TransferConfig>(
  'transfer/cancelTransfer',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState();
    const transferService = getTransferService();
    if (!arg) {
      return;
    }
    for (let i = 0; i < arg?.length; i++) {
      // eslint-disable-next-line no-await-in-loop
      await transferService.cancelTransfer(arg[i]);
    }
  },
);

export { startTransferAsync, cancelTransferAsync };
