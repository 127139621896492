import React from 'react';
import RecentTransfers from '@features/transfer-page/recent-transfers/components/RecentTransfers';
import { $recentTransfersPageText } from '@data/text.constants';
import styles from './styles/DesktopRecentTransfersPage.module.scss';

const DesktopRecentTransfersPage = () => (
  <div className={styles.container}>
    <div className={styles.header_text}>
      {$recentTransfersPageText.recentTransfers}
    </div>
    <div className={styles.recent_transfers}>
      <RecentTransfers />
    </div>
  </div>
);

export default DesktopRecentTransfersPage;
