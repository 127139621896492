import React from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useAppNavigation } from '@hooks/index';
import { useServiceAuthorization } from '@features/service-authorization/hooks';
import { libraryActions } from '@store/slices/library/librarySlice';
import getSortedServices, {
  getLoggedService,
  SortedMusicService,
} from '@store/slices/music-services/musicService.utils';
import BlankButton from '@features/main-page/components/buttons/BlankButton';
import { $availableServicesText } from '@data/text.constants';
import $urls from '@data/url.constants';
import AvailableServicesDescriptionComponent
  from '@features/main-page/components/other/AvailableServicesDescriptionComponent';
import styles from './styles/ServiceList.module.scss';
import ServiceButton from './buttons/ServiceButton';
import dotsIcon from '../assets/triple-dots.svg';
import { useGlobalModalContext } from '../../../contexts';

const ServiceList = () => {
  useAppSelector((s) => s.musicServices);
  const filteredServices = getSortedServices();
  const dispatch = useAppDispatch();
  const navigate = useAppNavigation();
  const { service: urlService } = useParams();
  const { authorizeMusicService } = useServiceAuthorization();
  const { showModal } = useGlobalModalContext();

  const serviceOnClick = async (musicService: SortedMusicService) => {
    if (musicService.isConnected) {
      const loggedService = getLoggedService(musicService.data);
      dispatch(libraryActions.selectService(loggedService!));
      navigate.toLibrary(musicService.data.type);
      return;
    }
    await authorizeMusicService(musicService.data.type);
  };
  const servicesList = filteredServices.map((service) => {
    const serviceData = service.data;
    const isSelected = urlService === serviceData.type;

    return (
      <ServiceButton
        key={serviceData.title}
        icon={serviceData.icon}
        isConnected={service.isConnected}
        isSelected={isSelected}
        onClick={() => serviceOnClick(service)}
      />
    );
  });
  return (
    <div className={styles.column}>
      {servicesList}
      <BlankButton
        icon={dotsIcon}
        onClick={() => showModal(
          {
            type: 'message_box',
            props: {
              title: $availableServicesText.thanksForUsingMusConv,
              description: <AvailableServicesDescriptionComponent />,
              additionalOnClick: () => window.open($urls.download),
            },
          },
        )}
      />
    </div>
  );
};

export default ServiceList;
