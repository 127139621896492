import React from 'react';
import { Column } from 'react-table';
import styles from '@pages/library/styles/LibraryPage.module.scss';
import { useAppSelector } from '@store/hooks';
import $columnIds from '@data/columns.constants';
import LibraryItemTitleButton from '@features/library-page/buttons/LibraryItemTitleButton';
import LibraryItemText from '@features/library-page/components/LibraryItemText';

const playlistsColumns = Array<Column>(
  {
    Header: 'Title',
    accessor: $columnIds.title,
    Cell: ({ row }: any) => (
      <LibraryItemTitleButton row={row} />
    ),
  },
  {
    Header: 'Tracks',
    accessor: $columnIds.tracks,
    Cell: ({ row }: any) => row.original.tracksCount,
  },
);

const albumsColumns = Array<Column>(
  {
    Header: 'Title',
    accessor: $columnIds.title,
    Cell: ({ row }: any) => (
      <LibraryItemTitleButton row={row} />
    ),
  },
  {
    Header: 'Artists',
    accessor: $columnIds.artists,
    Cell: ({ row }: any) => (
      <LibraryItemText id={row.original.id} text={row.original.artist} />
    ),
  },
  {
    Header: 'Tracks',
    accessor: $columnIds.tracks,
    Cell: ({ row }: any) => row.original.tracksCount,
  },
);

const artistsColumns = Array<Column>(
  {
    Header: 'Title',
    accessor: $columnIds.title,
    Cell: ({ row }: any) => (
      row.original.tracksCount
        ? (
          <LibraryItemTitleButton row={row} />
        ) : (
          <LibraryItemText id={row.original.id} text={row.original.title} />
        )
    ),
  },
  // TODO Get fans from server
  /*  {
      Header: 'Fans',
      accessor: 'fans' as const,
      Cell: ({ row }: any) => row.original.fansCount,
    }, */
);

const tracksColumns = Array<Column>(
  {
    Header: 'Title',
    accessor: $columnIds.title,
    Cell: ({ row }: any) => (
      row.original.url
        ? (
          <a href={row.original.url} className={styles.track_title_href} target="_blank" rel="noreferrer">
            <LibraryItemText id={row.original.id} text={row.original.title} />
          </a>
        ) : (
          <LibraryItemText id={row.original.id} text={row.original.title} />
        )
    ),
  },
  {
    Header: 'Album',
    accessor: $columnIds.album,
    Cell: ({ row }: any) => (
      <LibraryItemText id={row.original.id} text={row.original.albumName} />
    ),
  },
  {
    Header: 'Artists',
    accessor: $columnIds.artists,
    Cell: ({ row }: any) => (
      <LibraryItemText id={row.original.id} text={row.original.artist} />
    ),
  },
  {
    Header: 'Duration',
    accessor: $columnIds.duration,
    Cell: ({ row }: any) => row.original.duration,
  },
);

const getDesktopColumns = (): Array<Column> => {
  const selectedTab = useAppSelector((state) => state.library.selectedTab);
  const { openedMediaItemId } = useAppSelector((state) => state.libraryItem);
  if (openedMediaItemId) {
    return tracksColumns;
  }
  switch (selectedTab) {
    case $columnIds.playlists:
      return playlistsColumns;
    case $columnIds.albums:
      return albumsColumns;
    case $columnIds.artists:
      return artistsColumns;
    case $columnIds.tracks:
      return tracksColumns;
    default:
      return playlistsColumns;
  }
};

const useLibraryColumns = () => {
  const mobileColumns = Array<Column>(
    {
      Header: 'Title',
      accessor: $columnIds.title,
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ row }: any) => (
        <LibraryItemTitleButton row={row} />
      ),
    },
  );
  const desktopColumns = getDesktopColumns();
  return {
    desktopColumns,
    mobileColumns,
  };
};

export default useLibraryColumns;
