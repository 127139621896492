import React from 'react';
import {
  useGoogleLogin,
} from '@react-oauth/google';
import styles from './styles/GoogleButton.module.scss';
import TransparentButton from './TransparentButton';
import googleLogo from '../../assets/google-login-logo.svg';

type Props = {
  title: string;
  onSuccess: (token: string) => void;
};

const GoogleButton = ({ title, onSuccess } : Props) => {
  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      onSuccess(tokenResponse.access_token);
    },
  });
  return (
    <TransparentButton onClick={login}>
      <img className={styles.icon} src={googleLogo} alt="Google logo" height="21" width="21" />
      {title}
    </TransparentButton>
  );
};

export default GoogleButton;
