import { createSlice } from '@reduxjs/toolkit';
import { LoggedMusicService } from '@store/slices/music-services/musicService.types';
import {
  logInServiceAsync,
  logOutServiceAsync, refreshLoggedServiceAsync,
} from '@store/slices/music-services/musicServicesThunks';

type MusicServicesStatus = {
  loggedServices: Array<LoggedMusicService>;
  errorMessage?: string;
};

const initialState: MusicServicesStatus = {
  loggedServices: [],
};

const musicServicesSlice = createSlice({
  name: 'music-services',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(logInServiceAsync.fulfilled, (state, action) => {
      const service = state.loggedServices.find((s) => s.type === action.meta.arg.musicService);
      if (service) {
        return;
      }
      state.loggedServices.push({
        type: action.meta.arg.musicService,
        userId: action.meta.arg.serviceUserId,
        email: action.payload.email,
        nickName: action.payload.nickName,
        avatar: action.payload.avatar,
      });
    });
    builder.addCase(logInServiceAsync.rejected, (state, action) => {
      state.errorMessage = action.payload;
    });
    builder.addCase(logOutServiceAsync.fulfilled, (state, action) => {
      state.loggedServices = state.loggedServices.filter(
        (s) => s.type !== action.payload.selectedService.type,
      );
    });
    builder.addCase(logOutServiceAsync.rejected, (state, action) => {
      state.errorMessage = action.payload;
    });
    builder.addCase(refreshLoggedServiceAsync.fulfilled, (state, action) => {
      state.loggedServices = action.payload;
    });
    builder.addCase(refreshLoggedServiceAsync.rejected, (state, action) => {
      state.errorMessage = action.payload;
    });
  },
});

export const musicServicesActions = musicServicesSlice.actions;
export default musicServicesSlice.reducer;
