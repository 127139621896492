import { TransferStatus } from '@store/slices/transfer/transfer.types';
import { LoggedMusicService } from '@store/slices/music-services/musicService.types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { startTransferAsync, cancelTransferAsync } from '@store/slices/begin-transfer/beginTransferThunks';

type TransferState = {
  transferId?: string;
  status: TransferStatus;
  sourceService?: LoggedMusicService;
  destinationService?: LoggedMusicService;
  actionStatus: string;
  trialTransferred?: number;
  totalItems: number;
  foundItems: number;
  notFoundItems: number;
  errorMessage?: string;
};

const initialState: TransferState = {
  status: 'none',
  sourceService: undefined,
  destinationService: undefined,
  actionStatus: '',
  totalItems: 0,
  foundItems: 0,
  notFoundItems: 0,
  errorMessage: '',
};

const beginTransferSlice = createSlice({
  name: 'transfer-begin',
  initialState,
  reducers: {
    selectSourceService(state, action: PayloadAction<LoggedMusicService>) {
      state.sourceService = action.payload;
    },
    selectDestinationService(state, action: PayloadAction<LoggedMusicService>) {
      state.destinationService = action.payload;
    },
    finishTransfer(state) {
      state.status = 'finished';
    },
    cancelTransfer(state) {
      state.status = 'canceled';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(startTransferAsync.pending, (state) => {
      state.actionStatus = 'Preparing transfer items...';
      state.status = 'none';
      state.totalItems = 0;
      state.foundItems = 0;
      state.notFoundItems = 0;
    });
    builder.addCase(startTransferAsync.fulfilled, (state, action) => {
      state.status = 'inProgress';
      state.transferId = action.payload.transferId;
    });
    builder.addCase(startTransferAsync.rejected, (state, rejectedAction) => {
      if (state.status !== 'canceled') {
        state.status = 'error';
        state.errorMessage = rejectedAction.payload;
      }
    });
    builder.addCase(cancelTransferAsync.fulfilled, (state) => {
      state.status = 'canceled';
    });
    builder.addCase(cancelTransferAsync.rejected, (state, action) => {
      state.status = 'error';
      state.errorMessage = action.payload;
    });
  },
});

export const beginTransferActions = beginTransferSlice.actions;

export default beginTransferSlice.reducer;
