import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './styles/NavigationButton.module.scss';

type Props = {
  text: string;
  icon: string;
  destinationRoute: string;
};

const NavigationButton = ({
  text,
  icon,
  destinationRoute,
}: Props) => (

  <NavLink
    className={({ isActive }) => (isActive ? styles.button_active : styles.button_pending)}
    to={destinationRoute}
    key={text}
  >
    <img className={styles.button_image} src={icon} alt="home" />
    <div className={styles.button_text}>
      {text}
    </div>
  </NavLink>

);

export default NavigationButton;
