import { useEffect, useState } from 'react';
import { getAccountService } from 'src/inversify.config';
import { useParams } from 'react-router-dom';
import $responseTypes from '@data/response.types.constants';
import { $activationAccountErrorText as text } from '@data/text.constants';

const parseError = (type: string | undefined): string => {
  if (type === undefined) {
    return text.unknown;
  }

  const { accountNotFound, secretNotMatch } = $responseTypes.account.activate;
  switch (type) {
    case accountNotFound:
      return text.notFound;
    case secretNotMatch:
      return text.notMatch;
    default:
      return text.unknownType;
  }
};

type Status = 'init' | 'loading' | 'success' | 'error';
const useActivateAccount = () => {
  const [status, setStatus] = useState<Status>('init');
  const [error, setError] = useState<string>('');

  const send = async (secret: string) => {
    setStatus('loading');
    const accountService = getAccountService();
    const resp = await accountService.activate(secret);

    if (resp.status === 'ok') {
      setStatus('success');
      return;
    }

    setStatus('error');
    setError(parseError(resp.type));
  };

  const { secret } = useParams();
  useEffect(() => {
    if (secret) {
      send(secret).then();
      return;
    }

    setStatus('error');
    setError('Invalid link.');
  }, []);

  return {
    status,
    error,
  };
};

export default useActivateAccount;
