import { useAppSelector } from '@store/hooks';
import { useMemo } from 'react';

const useSelectedItemsCount = (isLibraryItem: boolean) => {
  const items = useAppSelector((state) => (isLibraryItem
    ? state.libraryItem.items[0].tracks : state.library.items));

  return useMemo<number>(
    () => items.filter((x) => x.isSelected).length,
    [items],
  );
};

export default useSelectedItemsCount;
