import React, { ReactNode } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import styles from './styles/IconContainer.module.scss';

type Props = {
  icon: string | object;
  title: string;
  children: ReactNode;
};

const IconContainer = ({
  icon, title, children,
}: Props) => (
  <div className={styles.container}>
    <div className={styles.circle}>
      <Player className={styles.icon} src={icon} autoplay loop />
    </div>
    <div className={styles.title}>{title}</div>
    {children}
  </div>
);

export default IconContainer;
