import React, { ReactNode } from 'react';
import styles from './styles/AuthContainer.module.scss';

type Props = {
  title: string;
  children: ReactNode;
};

const AuthContainer = ({ title, children } : Props) => (
  <div className={styles.auth_container}>
    <div className={styles.title}>{title}</div>
    {children}
  </div>
);

export default AuthContainer;
