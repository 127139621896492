import { inject, injectable } from 'inversify';
import $serviceType from '@data/service.types.constant';
import { type ISignalRClient } from '@lib/index';
import $api from '@data/api.constants';
import { TransferItem } from '@services/types/transfer.types';
import store from '@store/index';
import { beginTransferActions } from '@store/slices/begin-transfer/beginTransferSlice';
import { activeTransfersActions } from '@store/slices/active-transfers/activeTransfersSlice';
import { useAppDispatch } from '@store/hooks';
import loadActiveTransfersAsync from '@store/slices/active-transfers/activeTransfersThunks';
import { ITransferProgressService } from './TransferProgressService.interface';

@injectable()
export default class TransferProgressService implements ITransferProgressService {
  @inject($serviceType.signalr) private signalr: ISignalRClient;

  async startMonitoring(
    transferId: string,
  ): Promise<void> {
    await this.signalr.start($api.transfer.transferHub);

    this.signalr.receive('TransferNotFound', this.transferNotFound);
    this.signalr.receive('TransferCompleted', this.transferCompleted);
    this.signalr.receive('TransferCanceled', this.transferCanceled);
    this.signalr.receive('TransferUpdated', this.updateTransfer);

    await this.signalr.send('StartMonitoring', transferId);
  }

  stopMonitoring(): Promise<void> {
    return this.signalr.stop();
  }

  transferNotFound(
    transferId: string,
  ): void {
    // TODO maybe store.dispatch(transferActions.updateTransferInfo('Transfer not found'))
  }

  transferCanceled(
    transferId: string,
  ): void {
    store.dispatch(beginTransferActions.cancelTransfer());
    store.dispatch(activeTransfersActions.cancelActiveTransfer(transferId));
  }

  transferCompleted(
    transferId: string,
  ): void {
    store.dispatch(beginTransferActions.finishTransfer());
    store.dispatch(activeTransfersActions.finishActiveTransfer(transferId));
  }

  updateTransfer(
    transferItem: TransferItem,
  ): void {
    store.dispatch(activeTransfersActions.updateTransferInfo(transferItem));
  }
}
