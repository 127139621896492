import React from 'react';
import { useMobile } from '@hooks/index';
import styles from './ProgressBar.module.scss';

type Props = {
  completedPercent: number
};

const ProgressBar = ({ completedPercent }: Props) => {
  const { isMobile } = useMobile();
  return (
    <div className={styles.progress_bar}>
      <div
        className={styles.progress_color}
        style={{ width: isMobile ? completedPercent : completedPercent * 1.46 }}
      />
    </div>
  );
};

export default ProgressBar;
