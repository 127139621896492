import { inject, injectable } from 'inversify';
import $serviceType from '@data/service.types.constant';
import $api from '@data/api.constants';
import type { IHttpClient } from '@lib/index';
import { IKeysService } from './KeysService.interface';
import { KeysModel, KeysResponse } from './KeysService.types';

@injectable()
export default class KeysService implements IKeysService {
  @inject($serviceType.http) private http: IHttpClient;

  async getAppleDeveloperToken(): Promise<KeysModel> {
    const response = await this.http.post<KeysResponse>($api.keys.appleDevToken);
    return {
      type: 'ok',
      key: response.data.key,
    };
  }
}
