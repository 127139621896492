import React from 'react';
import TransferEndedModal
  from '@features/modal-windows/components/modals/transfer-ended-modals/base/TransferEndedModal';
import { $transferCanceledModalText } from '@data/text.constants';
import icon from '@assets/transfer-canceled.svg';

const TransferCanceledModal = () => (
  <TransferEndedModal modalIcon={icon} modalText={$transferCanceledModalText.transferCanceled} buttonText="OK" />
);

export default TransferCanceledModal;
