import { useCallback, useEffect, useState } from 'react';
import { IAuthService } from '@services/index';
import { useAppDispatch } from '@store/hooks';
import { userActions } from '@store/slices/user/userSlice';
import $serviceType from '@data/service.types.constant';
import { $loginErrorText as text } from '@data/text.constants';
import $responseTypes from '@data/response.types.constants';
import parseJwtToken from '@features/authorization/hooks/utils/parseJwtToken';
import services from '../../../inversify.config';

const useAsyncState = (initialState: any) => {
  const [state, setState] = useState(initialState);

  const asyncSetState = (value: any) => new Promise((resolve) => {
    setState(value);
    setState((current: any) => {
      resolve(current);
      return current;
    });
  });

  return [state, asyncSetState];
};

const parseError = (type?: string): string => {
  const types = $responseTypes.auth;
  switch (type) {
    case types.invalidEmail:
      return text.invalidEmail;
    case types.invalidPassword:
      return text.invalidPassword;
    case types.accNotActivated:
      return text.accNotActivated;
    default:
      return text.unknown;
  }
};

type Status = 'init' | 'loading' | 'error' | 'success';

export default function useLogin() {
  const [status, setStatus] = useState('init' as Status);
  const [error, setError] = useState('');
  const dispatch = useAppDispatch();
  async function login(email: string, password: string): Promise<void> {
    setStatus('loading');
    const auth = services.get<IAuthService>($serviceType.auth);
    const resp = await auth.login(email, password);
    if (resp.status === 'error') {
      setError(parseError(resp.type));
      setStatus('error');
      return;
    }

    dispatch(userActions.logIn({
      email,
      refreshToken: resp.refreshToken,
      accessToken: resp.accessToken,
      license: parseJwtToken(resp.accessToken).license,
      isAuth: true,
    }));

    setStatus('success');
  }

  return {
    status,
    error,
    login,
  };
}
