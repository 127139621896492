import React from 'react';
import icon from '@assets/double-arrow.svg';
import { useAppSelector } from '@store/hooks';
import { Tooltip } from 'react-tooltip';
import styles from './styles/TransferButton.module.scss';
import { useGlobalModalContext } from '../../../contexts';

type TransferButtonProps = {
  isAnySelected: boolean;
};

const TransferButton = ({ isAnySelected } : TransferButtonProps) => {
  const { showModal } = useGlobalModalContext();
  const showDestinationModal = () => showModal({ type: 'choose_destination' });
  const isTransferInProgress = useAppSelector((state) => state.beginTransfer.status === 'inProgress');
  const isTrialUser = useAppSelector((state) => state.user.license === 'Trial');
  const isTransferActiveForTrial = isTrialUser && !isTransferInProgress;
  const trialClassName = isAnySelected
  && isTransferActiveForTrial ? styles.container : styles.inactive;
  const defaultClassName = isAnySelected ? styles.container : styles.inactive;
  const trialOnClick = isAnySelected && isTransferActiveForTrial ? showDestinationModal : undefined;
  const defaultOnClick = isAnySelected ? showDestinationModal : undefined;
  return (
    <>
      <div
        className={styles.wrapper}
        data-tooltip-id="transfer-button"
        data-tooltip-content="Transfer"
      >
        <button
          className={isTrialUser ? trialClassName : defaultClassName}
          type="button"
          onClick={isTrialUser ? trialOnClick : defaultOnClick}
        >
          <div className={styles.inner_wrapper}>
            <div className={styles.text}>Transfer</div>
            <img className={styles.image} src={icon} alt="icon" />
          </div>
        </button>
      </div>
      <Tooltip id="transfer-button" className={styles.tooltip} />
    </>
  );
};

export default TransferButton;
