import React, { useEffect } from 'react';
import { $homePageText } from '@data/text.constants';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import loadRecentTransfers from '@store/slices/recent-transfers/recentTransfersThunks';
import { Tooltip } from 'react-tooltip';
import styles from './styles/RecentTransfersMinimal.module.scss';
import RecentTransferButton from '../buttons/RecentTransferButton';

const RecentTransfersMinimal = () => {
  const dispatch = useAppDispatch();
  const transferStatus = useAppSelector((state) => state.beginTransfer.status);
  useEffect(() => {
    dispatch(loadRecentTransfers());
  }, [transferStatus]);
  const recentTransfers = useAppSelector((state) => state.recentTransfers.recentTransfersList);
  return (
    recentTransfers.length > 0
      ? (
        <div className={styles.container}>
          <div className={styles.inner_container}>
            <div className={styles.header_text}>
              <div>
                {$homePageText.recentTransfers}
              </div>
              {/* <button className={styles.see_all} type="button">
            {$homePageText.seeAll}
          </button> */}
            </div>
            <div className={styles.scroller}>
              <div className={styles.recent_transfers_container}>
                {recentTransfers.map((recentTransfer) => (
                  <RecentTransferButton
                    key={recentTransfer.transferId}
                    recentTransfer={recentTransfer}
                  />
                ))}
              </div>
              <Tooltip id="transfer-result-icon" className={styles.tooltip} place="top" />
            </div>
          </div>
        </div>
      )
      : <div />
  );
};

export default RecentTransfersMinimal;
