import React from 'react';
import { useMobile } from '@hooks/responsive';
import logo from '@assets/musconv-header-logo.svg';
import mobLogo from '@assets/musconv-header-logo-mob.svg';
import styles from './styles/MusConvHeader.module.scss';

const MusConvHeader = () => {
  const { isMobile } = useMobile();
  return (
    <div className={styles.logo_container}>
      <img
        className={styles.logo}
        src={isMobile ? mobLogo : logo}
        alt="MusConv logo"
      />
    </div>
  );
};

export default MusConvHeader;
