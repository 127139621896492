import React from 'react';
import AdminPage from '@features/admin-page/AdminPage';
import { useAppSelector } from '@store/hooks';
import { NotFound } from '@pages/index';

const Admin = () => {
  const isDeveloper = useAppSelector((state) => state.user.license === 'Developer');
  return (
    isDeveloper ? <AdminPage /> : <NotFound />
  );
};

export default Admin;
