import { RootState } from '@store/index';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ActiveTransferState,
} from '@store/slices/active-transfers/activeTransfers.types';
import { getLoggedServiceData } from '@store/slices/music-services/musicService.utils';
import { getTransferStatus } from '@store/slices/transfer/transfer.utils';
import { getTransferService, getTransferStatusService } from '../../../inversify.config';

type ActiveTransfersConfig = {
  rejectValue: string;
  state: RootState;
};

type ReturnType = {
  transferItems: Array<ActiveTransferState>;
};

const loadActiveTransfersAsync = createAsyncThunk<ReturnType, void, ActiveTransfersConfig>(
  'activeTransfers/loadActiveTransfers',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState();
    const transferService = getTransferService();
    const activeTransferResponse = await transferService.getActiveTransfers();
    if (activeTransferResponse.type === 'error') {
      return thunkAPI.rejectWithValue(activeTransferResponse.message);
    }

    return {
      transferItems: activeTransferResponse.transferItems.map((transferItem, index) => {
        const sourceServiceInfo = state.musicServices.loggedServices
          .find(
            (loggedService) => loggedService.userId
                === transferItem.sourceCredentials.serviceUserId,
          );
        const destinationServiceInfo = state.musicServices.loggedServices
          .find(
            (loggedService) => loggedService.userId
                === transferItem.destinationCredentials.serviceUserId,
          );
        if (!sourceServiceInfo || !destinationServiceInfo) {
          throw Error('Source or destination info is invalid');
        }
        const sourceMusicData = getLoggedServiceData(sourceServiceInfo);
        const destinationMusicData = getLoggedServiceData(destinationServiceInfo);

        const transferProgressService = getTransferStatusService();
        transferProgressService.startMonitoring(transferItem.transferId);

        return ({
          activeTransferId: transferItem.transferId,
          title: `Transfer №${index + 1}`,
          type: transferItem.transferType,
          isSelected: false,
          isVisible: true,
          actionStatus: getTransferStatus(transferItem.status).text,
          sourceInfo: {
            serviceLogo: sourceMusicData.logo,
            serviceIcon: sourceMusicData.icon,
            userAvatar: sourceServiceInfo.avatar,
            userEmail: sourceServiceInfo.email,
          },
          destinationInfo: {
            serviceLogo: destinationMusicData.logo,
            serviceIcon: destinationMusicData.icon,
            userAvatar: destinationServiceInfo.avatar,
            userEmail: destinationServiceInfo.email,
          },
        }) as ActiveTransferState;
      }),
    };
  },
);

export default loadActiveTransfersAsync;
