import React from 'react';
import { Link } from 'react-router-dom';
import $route from '@data/routing.constants';
import { $notFountPageText } from '@data/text.constants';
import { StyledButton } from '@components/index';
import logo from '@assets/not-found.svg';
import styles from './styles/NotFound.module.scss';

const NotFound = () => (
  <div className={styles.wrapper}>
    <img className={styles.img} src={logo} alt="Not Found" />
    <h1 className={styles.error}>{$notFountPageText.error}</h1>
    <h1 className={styles.page_not_found}>{$notFountPageText.pageNotFound}</h1>
    <Link to={$route.home}>
      <div className={styles.btn}>
        <StyledButton onClick={() => {}} title={$notFountPageText.goMainPage} />
      </div>
    </Link>
  </div>
);

export default NotFound;
