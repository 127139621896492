import React, { useState } from 'react';
import { useMobile } from '@hooks/index';
import { useGlobalModalContext } from 'src/contexts';
import closeIcon from '@features/modal-windows/assets/close-icon.svg';
import backIcon from '@features/modal-windows/assets/arrow-back.svg';
import { $activeTransferModalText as text } from '@data/text.constants';
import spotifyLogo from '@assets/service-logos/spotify-logo.svg';
import youtubeLogo from '@assets/service-logos/youtube-logo.svg';
import arrow from '@assets/arrow.svg';
import { Column } from 'react-table';
import { MediaDataGrid } from '@features/library-page';
import MobileMediaDataGrid from '@features/library-page/components/media-content/MobileMediaDataGrid';
import $columnIds from '@data/columns.constants';
import styles from './styles/ActiveTransferModal.module.scss';
import TransferProgressBar from './components/TransferProgressBar';

type ActiveTransferModalProps = {
  playlistTitle: string;
  tracksTransferring: Array<any>;
};

/**
 * Modal with tracks of playlist that show active transfer tracks transfer status.
 *
 * Show playlist transfer details on click on playlist in history tab.
 *
 * @param playlistTitle - playlist title.
 * @param tracksTransferring - list of tracks in playlist that transfer.
 * @param sourceServiceLogo - logo of source service.
 * @param destinationServiceLogo - logo of destination service.
 */

const ActiveTransferModal = ({
  playlistTitle,
  tracksTransferring,
}
: ActiveTransferModalProps) => {
  const { hideModal } = useGlobalModalContext();
  const { isMobile } = useMobile();
  // TODO: remove hardcoded random values.
  const [completed, setCompleted] = useState(0);
  useState(() => {
    setInterval(() => setCompleted(Math.floor(Math.random() * 100) + 1), 2000);
  });

  const columns = Array<Column>(
    {
      Header: 'Track',
      accessor: $columnIds.title,
    },
    {
      Header: 'Artist',
      accessor: $columnIds.artist,
    },
    {
      Header: 'Album',
      accessor: $columnIds.album,
    },
  );
  const mobileColumns = Array<Column>(
    {
      Header: 'Track',
      accessor: $columnIds.title,
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ row }: any) => (
        <div className={styles.mob_grid_container}>
          <div className={styles.text_container}>
            <div className={styles.title}>
              {row.original.title}
            </div>
            <div className={styles.subtext_container}>
              <div>
                {row.original.artist}
              </div>
              <div>
                {playlistTitle}
              </div>
            </div>
          </div>
          <img src={row.original.status} alt="" />
        </div>
      ),
    },
  );

  return (
    <div className={styles.modal}>

      <div className={styles.top_header}>
        <div className={styles.left_header}>
          <img className={styles.back_icon} src={backIcon} onClick={hideModal} role="presentation" alt="back" />
          <div className={styles.active_transfer_text}>
            {text.activeTransfer}
            {/* TODO: check number of active transfers and set here. */}
            (4)
          </div>
          {isMobile && <img src={closeIcon} role="presentation" onClick={hideModal} className={styles.close_icon} alt="close" />}
        </div>
        <div className={styles.right_header}>
          {/* TODO: add source and destination logo to parameters later. */}
          {!isMobile && <div className={styles.playlist_title}>{playlistTitle}</div>}
          <img src={spotifyLogo} role="presentation" className={styles.source_logo} alt={spotifyLogo.toString()} />
          <img src={arrow} role="presentation" className={styles.arrow_img} alt="" />
          <img src={youtubeLogo} role="presentation" className={styles.destination_logo} alt={youtubeLogo.toString()} />
        </div>
        {!isMobile && <img src={closeIcon} role="presentation" onClick={hideModal} className={styles.close_icon} alt="close" />}
      </div>

      {/* TODO: send transferStatus dynamically due to transfer status (searching .., adding ..) */}
      <TransferProgressBar completedPercent={completed} transferStatus="Transfer status..." />
      <div className={styles.data_grid}>
        {!isMobile ? (
          <MediaDataGrid
            tableColumns={columns}
            data={tracksTransferring}
          />
        ) : (
          <MobileMediaDataGrid
            tableColumns={mobileColumns}
            data={tracksTransferring}
          />
        )}
      </div>
    </div>
  );
};

export default ActiveTransferModal;
