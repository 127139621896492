import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import MenuButton from '@features/main-page/components/buttons/MenuButton';
import ServiceSidebarModal from '@features/library-page/service-sidebar/ServiceSidebarModal';
import { useAppSelector } from '@store/hooks';
import { $libraryItemText as text } from '@data/text.constants';
import { getLoggedServiceData } from '@store/slices/music-services/musicService.utils';
import useModal from '@features/modal-windows/hooks/useModal';
import backIcon from '@features/main-page/assets/prev-button.svg';
import { SiteNavigationForm, UserDropdown } from '../../index';
import SidebarModal from '../sidebar/SidebarModal';
import styles from './styles/MobileHeader.module.scss';

const MobileHeader = () => {
  const {
    isOpen,
    isClosing,
    openModal,
    closeModal,
  } = useModal();
  const isLibraryPage = useLocation().pathname.includes('library');
  const { openedMediaItemId } = useAppSelector((state) => state.libraryItem);
  const { service } = useParams();
  const selectedService = useAppSelector((state) => state.musicServices.loggedServices
    .find((loggedService) => loggedService.type === service))!;
  const navigate = useNavigate();

  return (
    <>
      <SidebarModal isOpen={isOpen} isClosing={isClosing} closeModal={closeModal} />
      {/* TEMP, TODO: maybe to add gradient background
      from average color of playlist need to add new layout
      and relocate this logic to new layout */}
      {openedMediaItemId && (
        <div className={styles.info_header}>
          <img role="presentation" src={backIcon} alt="" onClick={() => navigate(-1)} />
          <div>{text.details}</div>
        </div>
      )}
      {isLibraryPage && (
        <ServiceSidebarModal
          isOpen={isOpen}
          isClosing={isClosing}
          closeModal={closeModal}
          serviceLogo={getLoggedServiceData(selectedService).logo}
          userAvatar={selectedService.avatar}
          email={selectedService.email}
        />
      )}

      {!openedMediaItemId && (
        <>
          <MenuButton onClick={openModal} />
          <SiteNavigationForm />
        </>
      )}
      <div className={styles.header_items}>
        <UserDropdown />
      </div>
    </>
  );
};

export default MobileHeader;
