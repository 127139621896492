import React from 'react';
import { MusConvHeaderAuth, RegistrationForm } from '@features/authorization';
import styles from './styles/Header.module.scss';

const Register = () => (
  <div>
    <div className={styles.header}>
      <MusConvHeaderAuth height="52" width="170" />
    </div>
    <RegistrationForm />
  </div>
);

export default Register;
