import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useMobile, useDidMountEffect } from '@hooks/index';
import $route from '@data/routing.constants';
import { $loginText } from '@data/text.constants';
import { ErrorMessage, StyledButton, StyledInput } from '@components/index';
import useGoogleLogin from '@features/authorization/hooks/useGoogleLogin';
import styles from './styles/LoginForm.module.scss';
import { GoogleButton, TransparentButton } from '../buttons';
import { useEmailValidation, usePasswordValidation, useLogin } from '../../hooks';
import { AuthContainer, HorizontalSpan } from '../index';

const LoginForm = () => {
  const {
    emailField,
    setEmailField,
    validEmailField,
    emailValidation,
  } = useEmailValidation();

  const {
    passwordField,
    setPasswordField,
    validPasswordField,
    passwordValidation,
  } = usePasswordValidation();

  const [googleEmail, setGoogleEmail] = useState('');

  const { isMobile } = useMobile();
  const {
    status,
    error,
    login,
  } = useLogin();

  const {
    googleStatus,
    googleError,
    googleLogin,
  } = useGoogleLogin();

  const loginOnClick = async () => {
    const isPasswordValid = validPasswordField();
    const isEmailValid = validEmailField();
    if (isEmailValid && isPasswordValid) {
      await login(emailField, passwordField);
    }
  };

  const loginGoogleOnClick = async (token: string) => {
    const email = await googleLogin(token);
    setGoogleEmail(email);
  };

  const navigate = useNavigate();
  useDidMountEffect(() => {
    if (status === 'success' || googleStatus === 'success') {
      navigate($route.home);
    }
  }, [status, googleEmail]);

  return (
    <AuthContainer title={$loginText.toContinueLogIn}>
      <div className={styles.oauth_buttons}>
        <GoogleButton onSuccess={loginGoogleOnClick} title={$loginText.logInWithGoogle} />
      </div>
      <HorizontalSpan />
      <StyledInput
        type="email"
        title={$loginText.emailAddress}
        value={emailField}
        onChange={(e) => setEmailField(e.target.value)}
        placeholder={$loginText.enterYourEmail}
        validation={emailValidation}
      />
      <div className={styles.password_container}>
        <StyledInput
          onChange={(e) => setPasswordField(e.target.value)}
          value={passwordField}
          type="password"
          placeholder={$loginText.password}
          title={$loginText.password}
          validation={passwordValidation}
        />
      </div>
      <div className={styles.login_part}>
        <Link
          to={$route.forgotPassword}
          role="button"
          className={styles.forgot_password}
        >
          Forgot password?
        </Link>
        <StyledButton
          onClick={loginOnClick}
          title={$loginText.logIn}
          isLoading={status === 'loading'}
          isDisabled={!passwordValidation.isValid || !emailValidation.isValid}
          keyBind="Enter"
        />
      </div>
      {
      status === 'error'
      && (
        <div className={styles.error}>
          <ErrorMessage message={error || googleError} />
        </div>
      )
    }
      {!isMobile && <hr />}
      <div className={styles.sing_title}>{$loginText.dontHaveAnAccount}</div>
      <Link to={$route.register} tabIndex={-1} style={{ textDecoration: 'none' }}>
        {isMobile
          ? <div className={styles.mob_sign}>{$loginText.signUpForMusConv}</div>
          : (
            <TransparentButton>
              {$loginText.signingUpForMusConv}
            </TransparentButton>
          )}
      </Link>
    </AuthContainer>
  );
};

export default LoginForm;
