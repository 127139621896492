import React from 'react';
import styles from './TrialTransferResultBox.module.scss';

type Props = {
  transferred: number,
  availableToTransfer: number,
  type: string,
};

/**
 * Button of destination service.
 *
 * @param transferred - number - how many transferred.
 * @param availableToTransfer - number - how many can transfer in trial.
 * @param type - transferred type.
 */

const TrialTransferResultBox = ({
  transferred, availableToTransfer, type,
}: Props) => (
  <div className={styles.box}>
    <div className={styles.count_text}>
      {transferred}
      /
      {availableToTransfer}
    </div>
    <div className={styles.type_text}>
      {type}
    </div>
  </div>
);
export default TrialTransferResultBox;
