import React from 'react';
import { MediaDataGrid } from '@features/library-page';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { Column } from 'react-table';
import $columnIds from '@data/columns.constants';
import { getMusicService } from '@store/slices/music-services/musicService.utils';
import { RecentTransfer } from '@store/slices/recent-transfers/recentTransfers.types';
import { recentTransfersActions } from '@store/slices/recent-transfers/recentTransfersSlice';
import SiteLoader from '@components/SiteLoader';
import styles from './styles/RecentTransfers.module.scss';

const columns = Array<Column>(
  {
    accessor: $columnIds.title,
    Cell: ({ row }: any) => (
      <div className={styles.title_container}>
        Transfer №
        {row.index + 1}
      </div>
    ),
  },
  {
    accessor: $columnIds.date,
    Cell: ({ row }: any) => (
      <div className={styles.date_container}>
        Transferred:
        {' '}
        {row.original.date}
      </div>
    ),
  },
  {
    accessor: $columnIds.type,
    Cell: ({ row }: any) => (
      <div className={styles.type}>
        {row.original.type}
      </div>
    ),
  },
  {
    accessor: $columnIds.source,
    Cell: ({ row }: any) => {
      const sourceLogo = getMusicService(row.original.sourceService)?.logo;
      return (
        <img className={styles.service_img} src={sourceLogo} alt="src-img" />
      );
    },
  },
  {
    accessor: $columnIds.destination,
    Cell: ({ row }: any) => {
      const destinationLogo = getMusicService(row.original.destinationService)?.logo;
      return (
        <img className={styles.service_img} src={destinationLogo} alt="dst-img" />
      );
    },
  },
);

const RecentTransfers = () => {
  const dispatch = useAppDispatch();
  const { recentTransfersList, loading } = useAppSelector((state) => state.recentTransfers);
  const rowCheckbox = {
    isVisible: true,
    onCheck: (item: RecentTransfer) => dispatch(recentTransfersActions.selectRecentTransfer(item)),
  };
  return (
    loading ? <SiteLoader headerText="Loading..." />
      : (
        <div className={styles.container}>
          <MediaDataGrid
            tableColumns={columns}
            data={recentTransfersList}
          />
        </div>
      )
  );
};

export default RecentTransfers;
