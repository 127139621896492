import { inject, injectable } from 'inversify';
import { type IHttpClient } from '@lib/index';
import $api from '@data/api.constants';
import $serviceType from '@data/service.types.constant';
import { CredentialsModel, ResponseError } from '@services/types/webapi.types';
import {
  AlbumExtended,
  Artist,
  Track,
} from '@services/types/music.items.types';
import { IApiResponse } from '@lib/http/HttpClient.types';
import { ISearchService } from './SearchService.interface';
import {
  ExtendedAlbumsModel,
  ArtistsModel,
  TracksModel,
  SearchRequest,
  ExtendedAlbumsResponse,
  SearchResponse,
  ItemsModel,
  ArtistsResponse,
  TracksResponse,
} from './SearchService.types';

@injectable()
export default class SearchService implements ISearchService {
  @inject($serviceType.http) private http: IHttpClient;

  private getItemsModel<ItemType, ResponseType>(
    response: IApiResponse<ResponseType>,
  ) : ItemsModel<ItemType> {
    if (response.status === 200) {
      const data = response.data as SearchResponse<ItemType>;
      return {
        type: 'ok',
        items: data.items,
      };
    }

    const data = response.data as ResponseError;
    return {
      type: 'error',
      message: data.title,
    };
  }

  private async searchItems<ItemType, ResponseType>(
    endpoint: string,
    credentials: CredentialsModel,
    searchCriteria: string,
  ): Promise<ItemsModel<ItemType>> {
    const requestData = {
      credentials,
      searchCriteria,
    } as SearchRequest;

    const response = await this.http.post<ResponseType>(
      endpoint,
      requestData,
    );

    return this.getItemsModel<ItemType, ResponseType>(response);
  }

  searchAlbums(
    credentials: CredentialsModel,
    searchCriteria: string,
  ): Promise<ExtendedAlbumsModel> {
    return this.searchItems<AlbumExtended, ExtendedAlbumsResponse>(
      $api.search.albums,
      credentials,
      searchCriteria,
    );
  }

  searchArtists(
    credentials: CredentialsModel,
    searchCriteria: string,
  ): Promise<ArtistsModel> {
    return this.searchItems<Artist, ArtistsResponse>(
      $api.search.artists,
      credentials,
      searchCriteria,
    );
  }

  searchTracks(
    credentials: CredentialsModel,
    searchCriteria: string,
  ): Promise<TracksModel> {
    return this.searchItems<Track, TracksResponse>(
      $api.search.tracks,
      credentials,
      searchCriteria,
    );
  }
}
