import React from 'react';
import { useParams } from 'react-router-dom';
import { MusConvHeaderAuth, ResetPasswordForm } from '@features/authorization';
import { NotFound } from '@pages/index';
import styles from './styles/Header.module.scss';

const ResetPassword = () => {
  const { email, secret } = useParams();

  return (
    !email || !secret
      ? <NotFound />
      : (
        <>
          <div className={styles.header}>
            <MusConvHeaderAuth height="52" width="170" />
          </div>
          <ResetPasswordForm email={email} secret={secret} />
        </>
      )
  );
};

export default ResetPassword;
