import React, { ChangeEvent, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useMobile } from '@hooks/index';
import $route from '@data/routing.constants';
import { $registerText } from '@data/text.constants';
import { ErrorMessage, StyledButton, StyledInput } from '@components/index';
import { useAppDispatch } from '@store/hooks';
import { registrationActions } from '@store/slices/registration/registrationSlice';
import useGoogleRegister from '@features/authorization/hooks/useGoogleRegister';
import styles from './styles/RegistrationForm.module.scss';
import { GoogleButton, TransparentButton, RadioButton } from '../buttons';
import { useEmailValidation, usePasswordValidation, useRegister } from '../../hooks';
import { AuthContainer, HorizontalSpan } from '../index';

const RegistrationForm = () => {
  const {
    emailField,
    setEmailField,
    validEmailField,
    emailValidation,
  } = useEmailValidation('');

  const {
    passwordField,
    setPasswordField,
    validPasswordField,
    passwordValidation,
  } = usePasswordValidation('');

  const [googleEmail, setGoogleEmail] = useState('');

  const [radiobutton, setRadiobutton] = useState('ordUser');
  const isRadioSelected = (value: string): boolean => radiobutton === value;
  const handleRadioClick = (e: ChangeEvent<HTMLInputElement>): void => {
    setRadiobutton(e.currentTarget.value);
  };

  const navigate = useNavigate();
  const { isMobile } = useMobile();
  const {
    status,
    error,
    register,
  } = useRegister();

  const {
    googleStatus,
    googleError,
    googleRegister,
  } = useGoogleRegister();

  const dispatch = useAppDispatch();
  const registerOnClick = async () => {
    const isEmailValid = validEmailField();
    const isPasswordValid = validPasswordField();

    if (isEmailValid && isPasswordValid) {
      await register(emailField, passwordField);
    }
  };
  const registerGoogleOnClick = async (token: string) => {
    const email = await googleRegister(token);
    setGoogleEmail(email);
  };

  useEffect(() => {
    if (status === 'success' || googleStatus === 'success') {
      dispatch(registrationActions.register({ email: emailField || googleEmail }));
      navigate($route.main);
    }
  }, [status, googleEmail]);

  return (
    <AuthContainer title={$registerText.signUpToTransfer}>
      <div className={styles.oauth_buttons}>
        <GoogleButton onSuccess={registerGoogleOnClick} title={$registerText.signUpWithGoogle} />
      </div>
      <HorizontalSpan />
      <StyledInput
        type="email"
        title={$registerText.whatsYourEmail}
        value={emailField}
        onChange={(e) => setEmailField(e.target.value)}
        placeholder={$registerText.enterYourEmail}
        validation={emailValidation}
      />
      <div className={styles.password_container}>
        <StyledInput
          onChange={(e) => setPasswordField(e.target.value)}
          value={passwordField}
          type="password"
          placeholder={$registerText.createPassword}
          title={$registerText.createPassword}
          validation={passwordValidation}
        />
      </div>
      {/* <div className={styles.role_part}>{$registerText.chooseWhoYouAre}</div>
      <div className={styles.radio_row}>
        <RadioButton
          id="dj"
          text={$registerText.dJ}
          onChange={handleRadioClick}
          isChecked={isRadioSelected('dj')}
        />
        <RadioButton
          id="musician"
          text={$registerText.musician}
          onChange={handleRadioClick}
          isChecked={isRadioSelected('musician')}
        />
        <RadioButton
          id="musLabel"
          text={$registerText.musicLabel}
          onChange={handleRadioClick}
          isChecked={isRadioSelected('musLabel')}
        />
        <RadioButton
          id="oth"
          text={$registerText.other}
          onChange={handleRadioClick}
          isChecked={isRadioSelected('oth')}
        />
      </div> */}
      <div className={styles.register_part}>
        <StyledButton
          keyBind="Enter"
          onClick={registerOnClick}
          title={$registerText.signUp}
          isLoading={status === 'loading'}
        />
      </div>
      {
        (status === 'error' || googleStatus === 'error')
        && (
        <div className={styles.error}>
          <ErrorMessage message={error || googleError} />
        </div>
        )
      }
      {!isMobile && <hr />}
      <div className={styles.sing_title}>{$registerText.haveAnAccount}</div>
      <Link to={$route.login} style={{ textDecoration: 'none' }}>
        {isMobile
          ? <div className={styles.mob_sign}>{$registerText.logIn}</div>
          : (
            <TransparentButton>
              {$registerText.logIn}
            </TransparentButton>
          )}
      </Link>
    </AuthContainer>
  );
};

export default RegistrationForm;
