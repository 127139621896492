import React, { useEffect, useRef, useState } from 'react';
import styles from './styles/TransferProgressBar.module.scss';

type Props = {
  completedPercent: number;
};

const getProgressColor = (progress: number): string => {
  const red = 255 * ((100 - progress) / 100);
  const green = 255 * (progress / 100);
  return `rgb(${red}, ${green}, 0)`;
};

const TransferProgressBar = ({ completedPercent }: Props) => {
  const [progressWidth, setProgressWidth] = useState(0);
  const statusBodyRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const statusBodyWidth = statusBodyRef.current?.clientWidth || 0;
    const width = (completedPercent / 100) * statusBodyWidth;
    setProgressWidth(width);
  }, [completedPercent]);
  return (
    <div className={styles.wrapper} ref={statusBodyRef}>
      <div
        className={styles.progress_color}
        style={{ width: completedPercent, backgroundColor: getProgressColor(completedPercent) }}
      />
    </div>
  );
};

export default TransferProgressBar;
