import { inject, injectable } from 'inversify';
import { type IHttpClient } from '@lib/index';
import $serviceType from '@data/service.types.constant';
import SettingsOptionType from '@data/enums/settingsOptionType';
import $api from '@data/api.constants';
import { ResponseError } from '@services/types/webapi.types';
import { IApiResponse } from '@lib/http/HttpClient.types';
import {
  ActionResultModel,
  SettingFlagRequest,
  SettingFlagValueRequest,
  SettingFlagsModel,
  SettingFlagsResponse,
  SettingFlagsSuccess,
  SettingResponse,
} from './SettingsService.types';
import { ISettingsService } from './SettingsService.interface';

@injectable()
export default class SettingsService implements ISettingsService {
  @inject($serviceType.http) private http: IHttpClient;

  async getAllSettingFlags(): Promise<SettingFlagsModel> {
    const response = await this.http.post<SettingFlagsResponse>(
      $api.settings.all,
    );

    if (response.status === 200) {
      const data = response.data as SettingFlagsSuccess;
      return {
        type: 'ok',
        settingFlags: data.settingFlags,
      };
    }

    const data = response.data as ResponseError;
    return {
      type: 'error',
      message: data.detail,
    };
  }

  private validateResponse(response: IApiResponse<SettingResponse>): ActionResultModel {
    if (response.status === 200) {
      return {
        type: 'ok',
      };
    }

    const data = response.data as ResponseError;
    return {
      type: 'error',
      message: data.detail,
    };
  }

  async setSettingFlag(
    settingsOptionType: SettingsOptionType,
    isSelected: boolean,
  ): Promise<ActionResultModel> {
    const requestData = {
      settingsOptionType,
      isSelected,
    } as SettingFlagValueRequest;

    const response = await this.http.post<SettingResponse>(
      $api.settings.update,
      requestData,
    );

    return this.validateResponse(response);
  }

  async resetSettingFlag(
    settingsOptionType: SettingsOptionType,
  ): Promise<ActionResultModel> {
    const requestData = {
      settingsOptionType,
    } as SettingFlagRequest;

    const response = await this.http.post<SettingResponse>(
      $api.settings.default,
      requestData,
    );

    return this.validateResponse(response);
  }
}
