import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER, PersistState, createMigrate,
} from 'redux-persist';
import storage from 'redux-persist/es/storage';
import musicServicesSlice from '@store/slices/music-services/musicServicesSlice';
import librarySlice from '@store/slices/library/librarySlice';
import transferSlice from '@store/slices/transfer/transferSlice';
import { createStateSyncMiddleware, initStateWithPrevTab } from 'redux-state-sync';
import recentTransfersSlice from '@store/slices/recent-transfers/recentTransfersSlice';
import activeTransfersSlice from '@store/slices/active-transfers/activeTransfersSlice';
import beginTransferSlice from '@store/slices/begin-transfer/beginTransferSlice';
import userReducer from './slices/user/userSlice';
import registerReducer from './slices/registration/registrationSlice';
import libraryItemSlice from './slices/library/library-item/libraryItemSlice';
import actionConfirmationSlice from './slices/action-confirmation/actionConfirmationSlice';

const rootReducer = combineReducers({
  user: userReducer,
  register: persistReducer({
    key: 'register',
    storage,
    blacklist: ['email'],
  }, registerReducer),
  musicServices: musicServicesSlice,
  library: librarySlice,
  libraryItem: libraryItemSlice,
  beginTransfer: beginTransferSlice,
  transfer: transferSlice,
  recentTransfers: recentTransfersSlice,
  activeTransfers: activeTransfersSlice,
  actionConfirmation: actionConfirmationSlice,
});

const persistedReducer = persistReducer({
  key: 'root',
  storage,
  // version: 1,
  blacklist: ['register', 'library', 'beginTransfer', 'transfer', 'recentTransfers', 'activeTransfers', 'actionConfirmation'],
  // migrate: createMigrate({
  //   1: (state) => ({
  //     ...state,
  //     musicServices: {
  //       services: $services,
  //     },
  //   }),
  // }, { debug: true }),
}, rootReducer);

const extraMiddlewares = [createStateSyncMiddleware({
  whitelist: [
    'music-services/logInService/pending',
    'music-services/logInService/fulfilled',
    'music-services/logInService/rejected',
    'music-services/logOutService/pending',
    'music-services/logOutService/fulfilled',
    'music-services/logOutService/rejected',
    'user/logIn',
    'user/refresh',
  ],
})];

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat(extraMiddlewares),
});
initStateWithPrevTab(store);
export const persistor = persistStore(store);
export default store;

export type RootState = ReturnType<typeof store.getState> & { _persist: PersistState };
export type AppDispatch = typeof store.dispatch;
