import React, { RefObject } from 'react';
import defaultAvatar from '@assets/default-avatar.svg';
import styles from './styles/DesktopDropdown.module.scss';

type Props = {
  handleDropdownClick: () => void;
  dropdownRef: RefObject<HTMLDivElement>;
  email: string;
  itemsList: JSX.Element;
  arrowImg: string;
};

const DesktopDropdown = ({
  handleDropdownClick, dropdownRef, itemsList, email, arrowImg,
}: Props) => (
  <div className={styles.container}>
    <div className={styles.dropdown} ref={dropdownRef}>
      <button
        className={styles.dropButton}
        type="button"
        onClick={handleDropdownClick}
      >
        <img
          className={styles.avatar_image}
          src={defaultAvatar}
          alt="avatar"
        />
        <div className={styles.text}>
          {email}
        </div>
        <img
          className={styles.arrow_image}
          src={arrowImg}
          alt="arrow"
        />
      </button>
      {itemsList}
    </div>
  </div>
);

export default DesktopDropdown;
