import React from 'react';
import styles from './styles/TabButton.module.scss';

type Props = {
  icon: string;
  tabName: string;
  onClick: () => void;
  isSelected: boolean;
};

const TabButton = ({
  icon, tabName, onClick, isSelected,
}: Props) => {
  const selected = isSelected ? ` ${styles.selected}` : '';
  return (
    <div className={styles.container + selected} tabIndex={-1} role="presentation" onClick={onClick}>
      <div className={styles.inner_container}>
        <img className={styles.tab_icon} src={icon} alt="tabIcon" />
        <div className={styles.tab_name}>
          {tabName}
        </div>
      </div>
    </div>
  );
};

export default TabButton;
