import { useState } from 'react';
import { IAdminService } from '@services/admin/AdminService.interface';
import $serviceType from '@data/service.types.constant';
import services from '../../../inversify.config';

type Status = 'init' | 'loading' | 'error' | 'success';

export default function useChangeLicense() {
  const [status, setStatus] = useState('init' as Status);
  const [error, setError] = useState('');

  async function changeLicense(userEmail: string, licenseName: string): Promise<void> {
    setStatus('loading');
    const adminService = services.get<IAdminService>($serviceType.admin);
    const response = await adminService.changeUserLicense(userEmail, licenseName);

    if (response.type === 'error') {
      setError(response.message);
      setStatus('error');
      return;
    }
    setStatus('success');
  }

  return {
    status,
    error,
    changeLicense,
  };
}
