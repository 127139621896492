import React, { useMemo, useState } from 'react';
import Modal, {
  useModal,
} from '@features/modal-windows';
import { ModalType } from './ModalContext.types';
import ModalContext from './ModalContext';
import renderModalByType from './modal.ulits';

type Props = {
  children: JSX.Element;
};
const GlobalModal = ({ children }: Props) => {
  const [modal, setModal] = useState<JSX.Element | null>(null);
  const {
    closeModal, openModal, isClosing, isOpen,
  } = useModal();

  const showModal = (modalType: ModalType) => {
    setModal(renderModalByType(modalType));
    openModal();
  };

  const modalValue = useMemo(
    () => ({ showModal, hideModal: closeModal }),
    [showModal, closeModal],
  );

  return (
    <ModalContext.Provider value={modalValue}>
      <Modal isOpen={isOpen} isClosing={isClosing} closeModal={closeModal}>
        {modal}
      </Modal>
      {children}
    </ModalContext.Provider>
  );
};

export default GlobalModal;
