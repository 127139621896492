import React, {
  useState, useEffect, useRef,
} from 'react';
import { $mainPageText } from '@data/text.constants';
import $route from '@data/routing.constants';
import { useMobile } from '@hooks/index';
import arrowUp from '@assets/dropdown-up.svg';
import arrowDown from '@assets/dropdown-down.svg';
import { useAppSelector } from '@store/hooks';
import $urls from '@data/url.constants';
import isTrialLicense from '@store/slices/user/user.utils';
import styles from './styles/UserDropdown.module.scss';
import DropdownItem from './DropdownItem';
import MobileDropdown from './MobileDropdown';
import DesktopDropdown from './DesktopDropdown';

const UserDropdown = () => {
  const { email } = useAppSelector((store) => store.user);
  const { isMobile } = useMobile();
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const itemsList = (
    <ul className={isOpen ? styles.option_active : styles.option_inactive}>
      {/* <DropdownItem text={$mainPageText.profile} link={$route.home} /> */}
      {isTrialLicense()
      && <DropdownItem text={$mainPageText.premium} link={$urls.premiumPage} openNewTab />}
      {/* <DropdownItem text={$mainPageText.video} link={$route.home} /> */}
      <DropdownItem text={$mainPageText.support} link={$urls.mailToSupport} />
      <DropdownItem text={$mainPageText.download} link={$urls.download} openNewTab />
      <DropdownItem
        text={$mainPageText.logOut}
        link={$route.login}
        onClick={() => localStorage.clear()}
      />
    </ul>
  );
  const handleDropDownFocus = (state: boolean) => {
    setIsOpen(!state);
  };

  useEffect(() => {
    const closeDropdown = (e: any) => {
      if (!dropdownRef.current?.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', closeDropdown);

    return () => document.removeEventListener('mousedown', closeDropdown);
  }, []);
  return (
    <div className={styles.dropdown_form}>
      {isMobile
        ? (
          <MobileDropdown
            dropdownRef={dropdownRef}
            handleDropdownClick={() => handleDropDownFocus(isOpen)}
            email={email}
            itemsList={itemsList}
          />
        )
        : (
          <DesktopDropdown
            handleDropdownClick={() => handleDropDownFocus(isOpen)}
            dropdownRef={dropdownRef}
            email={email}
            itemsList={itemsList}
            arrowImg={isOpen ? arrowUp : arrowDown}
          />
        )}
    </div>
  );
};

export default UserDropdown;
