import { useState } from 'react';
import MusicService from '@data/enums/musicService';
import { useAppSelector } from '@store/hooks';
import $urls from '@data/url.constants';
import { $featureUnavailableText } from '@data/text.constants';
import { getMusicAuthService } from '../../../inversify.config';
import { useGlobalModalContext } from '../../../contexts';

type Status = 'init' | 'loading' | 'error' | 'success';

export default function useYoutubeAuth() {
  const license = useAppSelector((state) => state.user.license);
  const [youtubeStatus, setYoutubeStatus] = useState('init' as Status);
  const [error, setError] = useState('');
  const { showModal } = useGlobalModalContext();

  async function authorizeYoutubeService(musicService: MusicService): Promise<void> {
    if (license === 'Trial') {
      showModal(
        {
          type: 'message_box',
          props: {
            title: $featureUnavailableText.subscriptionError,
            description: $featureUnavailableText.featureForPaid,
            additionalOnClick: () => window.open($urls.premiumPage),
          },
        },
      );

      return;
    }
    setYoutubeStatus('loading');

    const serviceAuth = getMusicAuthService();
    const result = await serviceAuth.getOauthRedirectUri(musicService);
    if (result.type === 'error') {
      setError(result.message);
      setYoutubeStatus('error');
      return;
    }
    setTimeout(() => window.open(result.uri));
    setYoutubeStatus('success');
  }

  // eslint-disable-next-line consistent-return
  return {
    youtubeStatus,
    error,
    authorizeYoutubeService,
  };
}
