import { useMobile } from '@hooks/responsive';
import React, { useEffect, useState } from 'react';
import { useGlobalModalContext } from 'src/contexts';
import { $thankYouModalText as text } from '@data/text.constants';
import StyledInput from '@components/StyledInput';
import StyledButton from '@components/StyledButton';
import { useEmailValidation } from '@features/authorization/hooks';
import styles from './styles/ThankYouModal.module.scss';
import thankYouIcon from '../../../assets/thank-you-icon.svg';
import closeIcon from '../../../assets/close-icon.svg';
import EmojiButton from './components/EmojiButton';
import emoji1 from '../../../assets/emoji-5.svg';
import emoji2 from '../../../assets/emoji-4.svg';
import emoji3 from '../../../assets/emoji-3.svg';
import emoji4 from '../../../assets/emoji-2.svg';
import emoji5 from '../../../assets/emoji-1.svg';

/**
 * Thank you modal.
 *
 * Used to subscribe to newsletter and rate quality.
 */
const ThankYouModal = () => {
  const { isMobile } = useMobile();
  const { hideModal } = useGlobalModalContext();
  const [selectedGrade, setSelectedGrade] = useState(0);
  const {
    emailField,
    setEmailField,
    validEmailField,
    emailValidation,
  } = useEmailValidation();

  const subscribeOnClick = async () => {
    const isEmailValid = validEmailField();
    if (isEmailValid) {
      // TODO: add subscribe and send user grade.
      hideModal();
    }
  };
  const handleEmojiClick = (grade: number) => {
    setSelectedGrade(grade);
  };
  const emojiGrades = [
    { icon: emoji1, grade: 1 },
    { icon: emoji2, grade: 2 },
    { icon: emoji3, grade: 3 },
    { icon: emoji4, grade: 4 },
    { icon: emoji5, grade: 5 },
  ];
  return (
    <div className={styles.modal_wrapper}>
      <img className={styles.main_icon} src={thankYouIcon} alt="icon" />
      <img className={styles.close_btn} alt="close" src={closeIcon} role="presentation" onClick={hideModal} />
      <div className={styles.thank_you_text}>
        {text.thankYou}
        <br />
        {' '}
        {text.musConv}
      </div>
      <div className={styles.subscribe_text}>
        {isMobile
          ? (
            <>
              {text.subscribeMobInfo1}
              <br />
              {' '}
              {text.subscribeMobInfo2}
            </>
          )
          : text.subscribeNewsletter}
      </div>

      <div className={styles.subscribe_container}>
        <div className={styles.input_container}>
          <StyledInput
            title=""
            value={emailField}
            onChange={(e) => setEmailField(e.target.value)}
            placeholder={text.enterEmail}
            validation={emailValidation}
          />
        </div>
        <div className={styles.btn_container}>
          <StyledButton
            title={text.subscribe}
            onClick={subscribeOnClick}
          />
        </div>
      </div>

      <div className={styles.rate_text}>
        {text.rateQuality}
      </div>

      {emojiGrades.map(({ icon, grade }) => (
        <EmojiButton
          key={grade}
          icon={icon}
          onClick={() => handleEmojiClick(grade)}
          grade={grade}
        />
      ))}
    </div>
  );
};

export default ThankYouModal;
