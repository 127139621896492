import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import styles from './styles/MarqueeText.module.scss';

type Props = {
  text?: string;
};

const MarqueeText = ({ text }: Props) => {
  const marqueeRef = useRef<HTMLDivElement>(null);
  const [shouldAnimate, setShouldAnimate] = useState(false);

  const handleResize = useCallback(() => {
    const marqueeEl = marqueeRef.current;
    if (marqueeEl && marqueeEl.scrollWidth > marqueeEl.clientWidth) {
      setShouldAnimate(true);
    }
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const marqueeWidth = shouldAnimate
    ? (marqueeRef.current && marqueeRef.current.scrollWidth - marqueeRef.current.clientWidth)
    : (marqueeRef.current && marqueeRef.current.clientWidth);

  return (
    <div
      className={shouldAnimate ? styles.marquee : ''}
      ref={marqueeRef}
      style={{ width: `${marqueeWidth}px` }}
    >
      {text}
    </div>
  );
};

export default MarqueeText;
