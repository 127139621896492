import React, { ChangeEventHandler } from 'react';
import styles from './styles/SearchFieldMobile.module.scss';

type Props = {
  placeholder: string;
  value: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

const SearchFieldMobile = ({ placeholder, onChange, value }: Props) => (
  <div className={styles.container}>
    <input
      type="text"
      className={styles.field}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  </div>
);

export default SearchFieldMobile;
