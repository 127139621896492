import React, { useState } from 'react';
import { $libraryItemText as text } from '@data/text.constants';
import { ItemWithTracksLibraryModel } from '@store/slices/library/library.types';
import noteIcon from '@features/library-page/assets/note-icon.svg';
import MarqueeText from '@components/MarqueeText';
import defaultPlaylistIcon from '@assets/icons/default-playlist-icon.svg';
import { MusicServiceData } from '@store/slices/music-services/musicService.types';
import styles from './styles/LibraryMediaItemHeader.module.scss';

type LibraryMediaItemHeaderProps = {
  mediaItem: ItemWithTracksLibraryModel;
  service: MusicServiceData;
};
const LibraryMediaItemHeader = ({ mediaItem, service } : LibraryMediaItemHeaderProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleCollapseToggle = () => {
    setIsCollapsed(!isCollapsed);
  };
  return (
    <>
      <div id="image_playlist" className={`${styles.item_header} ${isCollapsed ? styles.collapsed : styles.expanded}`}>
        <div className={styles.image_container}>
          <img
            className={styles.header_image}
            src={mediaItem.imageLink ?? defaultPlaylistIcon}
            alt="cover"
          />
        </div>
        <div>
          <div className={styles.item_type_text}>{mediaItem.type}</div>
          <div className={styles.item_title_text}>
            <MarqueeText text={mediaItem.title} />
          </div>
          <a href={mediaItem.url} target="_blank" rel="noreferrer">
            <img className={styles.service_logo} src={service.logo} alt={service.title} />
          </a>
          {mediaItem.description && (
          <div className={styles.item_description}>
            <MarqueeText text={mediaItem.description} />
          </div>
          )}
          <div className={styles.item_additional_info}>
            <div className={styles.info_count}>
              <img src={noteIcon} alt="" />
              <div>
                {mediaItem.tracksCount ?? mediaItem.tracks.length}
                &nbsp;
                {text.tracks}
              </div>
            </div>
            {/* TODO: implement properly
          <div className={styles.info_count}>
            <img src={usersIcon} alt="" />
            <div>
              0
              &nbsp;
              {text.followers}
            </div>
          </div>
          */}
          </div>
        </div>
      </div>
      <div className={styles.collapse_button} role="presentation" onClick={handleCollapseToggle}>
        {isCollapsed ? 'Expand' : 'Collapse'}
      </div>
    </>
  );
};
export default LibraryMediaItemHeader;
