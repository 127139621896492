import React from 'react';
import animation from '@assets/animations/confirm-email.json';
import { $emailConfText } from '@data/text.constants';
import { IconContainer } from '@features/authorization/components';
import { StyledButton } from '@components/index';
import { useAppDispatch } from '@store/hooks';
import { registrationActions } from '@store/slices/registration/registrationSlice';
import closeIcon from '@features/modal-windows/assets/close-icon.svg';
import styles from './styles/ConfirmEmailModal.module.scss';
import { useGlobalModalContext } from '../../../../../contexts';

type Props = {
  email: string;
};
const ConfirmEmailModal = ({ email }: Props) => {
  const dispatch = useAppDispatch();
  const { hideModal } = useGlobalModalContext();
  return (
    <div className={styles.container}>
      <img
        className={styles.close_btn}
        alt="close"
        src={closeIcon}
        role="presentation"
        onClick={() => {
          dispatch(registrationActions.closeBanner());
          hideModal();
        }}
      />
      <IconContainer icon={animation} title={$emailConfText.title}>
        <div className={styles.info}>
          {$emailConfText.info1}
          <span className={styles.email}>
            {' '}
            {email}
            {'. '}
          </span>
          {$emailConfText.info2}
        </div>
        <div className={styles.next_button}>
          <StyledButton
            title="Go to the app"
            onClick={() => {
              dispatch(registrationActions.closeBanner());
              hideModal();
            }}
          />
        </div>
      </IconContainer>
    </div>
  );
};

export default ConfirmEmailModal;
