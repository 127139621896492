import { useState } from 'react';
import { IAuthService } from '@services/index';
import $serviceType from '@data/service.types.constant';
import { useAppDispatch } from '@store/hooks';
import { userActions } from '@store/slices/user/userSlice';
import parseJwtToken from '@features/authorization/hooks/utils/parseJwtToken';
import services from '../../../inversify.config';

type Status = 'init' | 'loading' | 'error' | 'success';

export default function useRegister() {
  const [status, setStatus] = useState('init' as Status);
  const [error, setError] = useState('');
  const dispatch = useAppDispatch();

  async function register(email: string, password: string): Promise<void> {
    setStatus('loading');
    const auth = services.get<IAuthService>($serviceType.auth);
    const res = await auth.register(email, password);
    if (res.type === 'error') {
      setError(res.message);
      setStatus('error');
      return;
    }

    dispatch(userActions.logIn({
      email,
      refreshToken: res.refreshToken,
      accessToken: res.accessToken,
      license: parseJwtToken(res.accessToken).license,
      isAuth: true,
    }));

    setStatus('success');
  }

  return {
    status,
    error,
    register,
  };
}
