import store from '@store/index';
import { TransferMediaItem } from '@store/slices/transfer/transfer.types';
import { $activeTransferText } from '@data/text.constants';
import { TransferStatusInfo } from '@services/types/transfer.types';
import { assertUnreachable } from '../../../utils';

const getTransferMediaItems = () => {
  const { openedMediaItemId } = store.getState().libraryItem;

  const selectedItems = openedMediaItemId
    ? store.getState().libraryItem.items
    : store.getState().library.items.filter((item) => item.isSelected);

  return selectedItems.map((selectedItem) => ({
    id: selectedItem.id,
    type: selectedItem.type,
  } as TransferMediaItem));
};

const getTransferStatus = (transferStatus: TransferStatusInfo) => {
  switch (transferStatus.transferStatusCode) {
    case 'preparing':
      return {
        text: $activeTransferText.preparingItemsStatus,
      };
    case 'searching':
      return {
        text: transferStatus.itemArtist
          ? `${$activeTransferText.searchingItemsStatus + transferStatus.itemTitle} by ${transferStatus.itemArtist}`
          : `${$activeTransferText.searchingItemsStatus + transferStatus.itemTitle}`,
      };
    case 'transferring':
      return {
        text: $activeTransferText.transferringItemsStatus,
      };
    case 'completed':
      return {
        text: $activeTransferText.transferCompletedStatus,
      };
    case 'trialCompleted':
      return {
        text: $activeTransferText.trialCompletedStatus,
        trackTransferred: transferStatus.tracksTransferred,
      };
    case 'emptyTransfer':
      return {
        text: $activeTransferText.transferEmptyStatus,
      };
    case 'error':
      return {
        text: $activeTransferText.transferErrorStatus,
      };
    default:
      assertUnreachable(transferStatus);
  }
  return {
    text: '',
  };
};

export {
  getTransferMediaItems,
  getTransferStatus,
};
