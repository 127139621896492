import { useState } from 'react';
import MusicService from '@data/enums/musicService';
import { useAppDispatch } from '@store/hooks';
import { logInServiceAsync } from '@store/slices/music-services/musicServicesThunks';
import { getKeysService, getMusicAuthService } from '../../../inversify.config';

type Status = 'init' | 'loading' | 'error' | 'success';

export default function useMusicKitAuth() {
  const [appleStatus, setAppleStatus] = useState('init' as Status);
  const [error, setError] = useState('');
  const dispatch = useAppDispatch();

  async function authorizeMusicKit(service: string): Promise<void> {
    if (service !== MusicService.appleMusic) {
      setError(`Unable to use MusicKit to authorize ${service}.`);
      setAppleStatus('error');
      return;
    }

    setAppleStatus('loading');
    const keysService = getKeysService();
    const appleDevToken = await keysService.getAppleDeveloperToken();

    const musicKit = (window as any).MusicKit;

    try {
      await musicKit.configure({
        developerToken: appleDevToken.key,
        app: {
          name: 'MusConv',
          build: '0.1.0',
        },
      });
    } catch (err) {
      setError('Failed to configure MusicKit authorization.');
      setAppleStatus('error');
      return;
    }

    const instance = musicKit.getInstance();
    await instance.authorize();

    const token = instance.musicUserToken as string;
    const authService = getMusicAuthService();
    const response = await authService.tokenAuth(service, token);

    if (response.type === 'error') {
      setError(response.message);
      setAppleStatus('error');
      return;
    }

    dispatch(logInServiceAsync(response.credentials));
    setAppleStatus('success');
  }

  return {
    appleStatus,
    error,
    authorizeMusicKit,
  };
}
