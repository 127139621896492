import React from 'react';
import MarqueeText from '@components/MarqueeText';
import styles from './styles/LoggedServiceButton.module.scss';

type Props = {
  serviceIcon: string;
  email?: string;
  onClick: () => void;
};

const LoggedServiceButton = ({
  serviceIcon,
  email,
  onClick,
}: Props) => (
  <button className={styles.container} type="button" onClick={onClick}>
    <img className={styles.service_icon} src={serviceIcon} alt="serviceIcon" />
    <div className={styles.email_text}>
      <MarqueeText text={email} />
    </div>
  </button>
);

export default LoggedServiceButton;
