import React from 'react';
import $route from '@data/routing.constants';
import { $mainPageText } from '@data/text.constants';
import transfersLogo from '@assets/double-arrow.svg';
import recentLogo from '@assets/recent-icon.svg';
import homeLogo from '../assets/navigation/home-icon.svg';
import autosyncLogo from '../assets/navigation/autosync-icon.svg';
import settingsLogo from '../assets/navigation/settings-icon.svg';
import NavigationButton from './buttons/NavigationButton';
import styles from './styles/NavigationForm.module.scss';

const NavigationForm = () => (
  <div className={styles.container}>
    <NavigationButton
      text={$mainPageText.home}
      icon={homeLogo}
      destinationRoute={$route.home}
    />
    <NavigationButton
      text={$mainPageText.transfers}
      icon={transfersLogo}
      destinationRoute={$route.activeTransfers}
    />
    <NavigationButton
      text={$mainPageText.recent}
      icon={recentLogo}
      destinationRoute={$route.recentTransfers}
    />
    {/* <NavigationButton
      text={$mainPageText.autosync}
      icon={autosyncLogo}
      destinationRoute={$route.autosync}
    />
    <NavigationButton
      text={$mainPageText.settings}
      icon={settingsLogo}
      destinationRoute={$route.register}
    /> */}
  </div>
);

export default NavigationForm;
