import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserState } from '@store/slices/user/user.types';

const initialState: UserState = {
  isAuth: false,
  email: '',
  license: 'none',
  accessToken: null,
  refreshToken: null,
};

type RefreshModel = {
  accessToken: string;
  refreshToken: string;
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logIn(state, action: PayloadAction<UserState>) {
      state.email = action.payload.email;
      state.isAuth = true;
      state.license = action.payload.license;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    },
    refresh(state, action: PayloadAction<RefreshModel>) {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    },
  },
});

export const userActions = userSlice.actions;
export default userSlice.reducer;
