import React from 'react';
import { $trialVersionModalText } from '@data/text.constants';
import $trialTransfer from '@data/transfer.constants';
import $urls from '@data/url.constants';
import closeIcon from '../../../assets/close-icon.svg';
import styles from './TrialVersionModal.module.scss';
import TrialTransferResultBox from './components/TrialTransferResultBox';
import { PremiumButton } from '../../../../main-page';
import { useGlobalModalContext } from '../../../../../contexts';

type Props = {
  transferredTracks: number,
};

function closeModal(hideModal: () => void) {
  hideModal();
  setTimeout(() => window.open($urls.premiumPage));
}

const TrialVersionModal = ({
  transferredTracks,
}: Props) => {
  const { hideModal } = useGlobalModalContext();
  return (
    <div className={styles.trial_version_wrapper}>
      <div className={styles.trial_version_border}>
        <img className={styles.close_btn} alt="close" src={closeIcon} role="presentation" onClick={() => closeModal(hideModal)} />
        <div className={styles.main_info}>
          <div className={styles.thank_you_text}>
            {$trialVersionModalText.thankYouForUsing}
            <br />
            {' '}
            {$trialVersionModalText.versionOfMusConv}
          </div>
          <div className={styles.have_transferred_text}>
            {$trialVersionModalText.haveTransferredText}
          </div>
          <div className={styles.transfer_boxes}>
            <TrialTransferResultBox
              transferred={transferredTracks}
              availableToTransfer={$trialTransfer.availableTracksTransfer}
              type={$trialVersionModalText.songType}
            />
          </div>
          <div className={styles.trial_version_allow_text}>
            {$trialVersionModalText.trialVersionAllows}
          </div>
          <div className={styles.you_have_reached_text}>
            {$trialVersionModalText.youHaveReached}
            <br />
            {' '}
            {$trialVersionModalText.versionOfSoftware}
          </div>
          <div className={styles.premium_btn_container}>
            <PremiumButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrialVersionModal;
