import React from 'react';
import MobileMediaDataGrid
  from '@features/library-page/components/media-content/MobileMediaDataGrid';
import { Column } from 'react-table';
import $columnIds from '@data/columns.constants';
import styles
  from '@features/transfer-page/recent-transfers/responsive/styles/MobileRecentTransfersPage.module.scss';
import arrow from '@assets/arrow.svg';
import { RecentTransfer } from '@store/slices/recent-transfers/recentTransfers.types';
import { getMusicService } from '@store/slices/music-services/musicService.utils';

type Props = {
  recentTransfers: RecentTransfer[];
};

const mobileColumns = Array<Column>(
  {
    Header: 'Track',
    accessor: $columnIds.title,
    Cell: ({ row }: any) => {
      const sourceServiceLogo = getMusicService(row.original.sourceService)?.logo;
      const destinationServiceLogo = getMusicService(row.original.destinationService)?.logo;
      return (
        <div className={styles.mob_grid_container}>
          <div className={styles.row_container}>
            <div className={styles.main_content}>
              <div className={styles.main_content}>
                Transfer №
                {row.index + 1}
              </div>
              <div className={styles.logo_container}>
                <img
                  className={styles.service_logo}
                  src={sourceServiceLogo}
                  alt="srcLogo"
                />
                <img className={styles.arrow_icon} src={arrow} alt="arrow" />
                <img
                  className={styles.service_logo}
                  src={destinationServiceLogo}
                  alt="dstLogo"
                />
              </div>
            </div>
            <div className={styles.subtext_text}>
              {row.original.type}
              {' '}
              type
            </div>
          </div>
        </div>
      );
    },
  },
);

const MobileRecentTransfersPage = ({ recentTransfers }: Props) => (
  <div className={styles.container}>
    <MobileMediaDataGrid
      tableColumns={mobileColumns}
      data={recentTransfers}
    />
  </div>
);

export default MobileRecentTransfersPage;
