import React, { RefObject } from 'react';
import defaultAvatar from '@assets/default-avatar.svg';
import styles from './styles/MobileDropdown.module.scss';

type Props = {
  handleDropdownClick: () => void;
  dropdownRef: RefObject<HTMLDivElement>;
  email: string;
  itemsList: JSX.Element;
};

const MobileDropdown = ({
  handleDropdownClick, dropdownRef, email, itemsList,
}: Props) => (
  <div className={styles.mob_dropdown_container} ref={dropdownRef}>
    <button
      className={styles.mob_dropdown}
      type="button"
      onClick={handleDropdownClick}
    >
      <span className={styles.mob_email}>{email}</span>
      <div className={styles.avatar_circle}>
        <img className={styles.mob_avatar_img} src={defaultAvatar} alt="avatar" />
      </div>
    </button>
    {itemsList}
  </div>
);

export default MobileDropdown;
