import React, { CSSProperties } from 'react';
import styles from './styles/BlankComponent.module.scss';

type Props = {
  icon?: string;
  title: string;
  titleStyle?: CSSProperties;
  description: string;
  descriptionStyle?: CSSProperties;
};

const BlankComponent = ({
  icon, title, titleStyle, description, descriptionStyle,
}: Props) => (
  <div className={styles.container}>
    { icon && <img src={icon} className={styles.icon} alt="blank icon" />}
    <div className={styles.title} style={titleStyle}>{title}</div>
    <div className={styles.description} style={descriptionStyle}>{description}</div>
  </div>
);

export default BlankComponent;
