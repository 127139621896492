import { useState } from 'react';
import { IAuthService } from '@services/index';
import $serviceType from '@data/service.types.constant';
import { useAppDispatch } from '@store/hooks';
import { userActions } from '@store/slices/user/userSlice';
import parseJwtToken from '@features/authorization/hooks/utils/parseJwtToken';
import services from '../../../inversify.config';

type Status = 'init' | 'loading' | 'error' | 'success';

export default function useGoogleRegister() {
  const [googleStatus, setGoogleStatus] = useState('init' as Status);
  const [googleError, setGoogleError] = useState('');
  const dispatch = useAppDispatch();

  async function googleRegister(token: string): Promise<string> {
    setGoogleStatus('loading');
    const auth = services.get<IAuthService>($serviceType.auth);
    const res = await auth.googleRegister(token);
    if (res.type === 'error') {
      setGoogleError(res.message);
      setGoogleStatus('error');
      throw new Error(res.message ?? 'Google Registration Failed');
    }

    dispatch(userActions.logIn({
      email: res.email,
      refreshToken: res.refreshToken,
      accessToken: res.accessToken,
      license: parseJwtToken(res.accessToken).license,
      isAuth: true,
    }));
    setGoogleStatus('success');
    return res.email;
  }

  return {
    googleStatus,
    googleError,
    googleRegister,
  };
}
