import React, { useEffect, useState } from 'react';
import { Column } from 'react-table';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { libraryActions } from '@store/slices/library/librarySlice';
import { Command } from '@features/library-page/buttons/CommandButton';
import { $libraryPageText } from '@data/text.constants';
import { libraryItemActions } from '@store/slices/library/library-item/libraryItemSlice';
import MobileCommandsForm from '@features/library-page/components/media-content/MobileCommandsForm';
import styles from './MobileLibraryItems.module.scss';
import { SearchFieldMobile } from '../../home-page';
import MobileMediaDataGrid from '../components/media-content/MobileMediaDataGrid';
import useSelectedItemsCount from '../hooks/useSelectedItemsCount';
import { EmptyLibraryBlank } from '../../../components';

type Props<T> = {
  columns: Column[];
  hiddenColumns: string[];
  data: T[];
  commands: Command[];
  rowCheckbox: {
    isVisible: boolean;
    onCheck?: (rowItem: T) => void;
  };
  searchPlaceholder?: string;
  isLibraryItem?: boolean;
};

const MobileLibraryItems = <T extends object>({
  columns, hiddenColumns, data, commands, searchPlaceholder, isLibraryItem, rowCheckbox,
}: Props<T>) => {
  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useAppDispatch();
  const { selectedTab } = useAppSelector((state) => state.library);
  const toggleCheckbox = () => {
    if (isLibraryItem) {
      dispatch(libraryItemActions.selectAllItems());
    } else {
      dispatch(libraryActions.selectAllItems());
    }
  };

  useEffect(() => {
    if (isLibraryItem) {
      dispatch(libraryItemActions.searchTrack({ searchQuery }));
    } else {
      dispatch(libraryActions.searchItem({ searchQuery }));
    }
  }, [searchQuery]);

  const { isAnySelected } = useAppSelector((state) => (isLibraryItem
    ? state.libraryItem : state.library));

  const { isAllSelected } = useAppSelector((state) => (isLibraryItem
    ? state.libraryItem : state.library));

  const selectedItemsCount = useSelectedItemsCount(isLibraryItem!);

  return (
    <div className={styles.wrapper}>
      <SearchFieldMobile
        placeholder={searchPlaceholder ?? `${$libraryPageText.search} ${selectedTab}`}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <MobileCommandsForm
        isAnySelected={isAnySelected}
        isAllSelected={isAllSelected}
        commands={commands}
        selectedItemsCount={selectedItemsCount}
        toggleCheckbox={toggleCheckbox}
      />
      {
        data.length === 0
          ? (<EmptyLibraryBlank />)
          : (
            <MobileMediaDataGrid
              tableColumns={columns}
              data={data}
              hiddenColumns={hiddenColumns}
              rowCheckbox={rowCheckbox}
            />
          )
      }
    </div>
  );
};

export default MobileLibraryItems;
