import store from '@store/index';
import $allMusicServices from '@store/slices/music-services/musicService.data';
import {
  LoggedMusicService,
  MusicServiceData,
} from '@store/slices/music-services/musicService.types';

export const getLoggedServiceData = (
  loggedService: LoggedMusicService,
) => $allMusicServices.find((m) => m.type === loggedService.type)!;

type SortedService = {
  isConnected: false,
  data: MusicServiceData,
};

type LoggedSortedService = {
  isConnected: true,
  data: MusicServiceData,
  loggedData: LoggedMusicService,
};

export type SortedMusicService = LoggedSortedService | SortedService;

export const getSortedServices = (): SortedMusicService[] => {
  const loggedServices = store.getState()
    .musicServices.loggedServices.map((s) => ({
      data: getLoggedServiceData(s)!,
      isConnected: true,
      loggedData: s,
    } as LoggedSortedService));
  const services = $allMusicServices
    .filter((service) => !loggedServices
      .some((loggedService) => loggedService.data.type === service.type))
    .map((service) => ({ data: service, isConnected: false } as SortedService));
  return [...loggedServices, ...services];
};

export const getLoggedService = (
  service: MusicServiceData,
) => store.getState().musicServices.loggedServices.find((s) => s.type === service.type);

export const getLoggedServiceFromUrl = (url: string) => store.getState()
  .musicServices.loggedServices.find((loggedService) => loggedService.type === url)!;

export const getMusicService = (
  serviceName: string,
) => $allMusicServices.find((s) => s.type === serviceName);

export default getSortedServices;
