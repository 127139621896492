import React from 'react';
import styles from './styles/IconButton.module.scss';

type Props = {
  /** Defines a string path to image. */
  image: string;
  /** Defines function to click on button. */
  onClick: ()=>void;
};

/**
 * Button with icon only.
 *
 * @public
 */
const IconButton = ({ image, onClick }: Props) => (
  <div
    className={styles.iconButton}
    onClick={onClick}
    role="presentation"
  >
    <img className={styles.icon} src={image} alt="Close" />
  </div>
);

export default IconButton;
