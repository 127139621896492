import React from 'react';
import { useMobile } from '@hooks/responsive';
import styles from './styles/ServiceButton.module.scss';

type Props = {
  icon: string;
  isConnected: boolean;
  isSelected?: boolean;
  onClick: () => void;
};
const ServiceButton = ({
  icon, isConnected, isSelected = false, onClick,
}: Props) => {
  const { isMobile } = useMobile();
  return (
    <div className={styles.container}>
      {isSelected && !isMobile && <div className={styles.selected_service} />}
      <div
        className={styles.button}
        role="button"
        tabIndex={0}
        onClick={onClick}
        onKeyDown={undefined}
      >
        <div className={isConnected ? styles.connected_circle : styles.unconnected_circle} />
        <img className={styles.button_img} src={icon} alt={icon.toString()} />
      </div>
    </div>
  );
};

export default ServiceButton;
