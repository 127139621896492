import React from 'react';
import { useMobile } from '@hooks/index';
import HomePageMobile from '@features/home-page/pages/HomePageMobile';
import HomePageDesktop from '@features/home-page/pages/HomePageDesktop';
import styles from './styles/Home.module.scss';

const Home = () => {
  const { isMobile } = useMobile();
  return (
    <div className={styles.main_container}>
      {isMobile ? <HomePageMobile /> : <HomePageDesktop />}
    </div>
  );
};

export default Home;
