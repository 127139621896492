import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { injectable } from 'inversify';
import store from '@store/index';
import { ISignalRClient } from './SignalRClient.interface';

@injectable()
export default class SignalRClient implements ISignalRClient {
  private hubConnection: HubConnection;

  start(
    url: string,
  ): Promise<void> {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(url, {
        transport: HttpTransportType.WebSockets,
        skipNegotiation: true,
        accessTokenFactory: () => `${store.getState().user.accessToken}`,
      })
      .build();

    return this.hubConnection.start();
  }

  stop(): Promise<void> {
    return this.hubConnection.stop();
  }

  send(
    methodName: string,
    data: any,
  ): Promise<any> {
    return this.hubConnection.invoke(methodName, data);
  }

  receive(
    methodName: string,
    method: (...args: any[]) => any,
  ): void {
    this.hubConnection.on(methodName, method);
  }
}
