import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import MainPageLayout from '@pages/layouts/MainPageLayout';
import $route from '@data/routing.constants';
import {
  Home,
  Login,
  Register,
  NotFound,
  EmailConfirm,
  ForgotPassword,
  ResetPassword,
  PasswordResetSuccess,
  RegistrationSuccessfully,
  Callback,
  ServiceLoginPage,
  ActiveTransfers,
  History,
  LibraryPage,
  LibraryItemTracksPage,
  Admin,
  RecentTransfer,
} from '@pages/index';
import LoggedRoute from '@components/routing/LoggedRoute';
import UnloggedRoute from '@components/routing/UnloggedRoute';

const App = () => (
  <Routes>
    <Route
      path={$route.admin}
      element={<LoggedRoute><Admin /></LoggedRoute>}
    />
    <Route element={<MainPageLayout />}>
      <Route path={$route.home} element={<LoggedRoute><Home /></LoggedRoute>} />
      <Route path={$route.autosync} element={<LoggedRoute><Home /></LoggedRoute>} />
      <Route path={$route.home} element={<LoggedRoute><Home /></LoggedRoute>} />
      <Route path={$route.library} element={<LoggedRoute><LibraryPage /></LoggedRoute>} />
      <Route
        path={$route.libraryItem}
        element={<LoggedRoute><LibraryItemTracksPage /></LoggedRoute>}
      />
      <Route
        path={$route.activeTransfers}
        element={(
          <LoggedRoute>
            <ActiveTransfers />
          </LoggedRoute>
                )}
      />
      <Route
        path={$route.recentTransfers}
        element={(
          <LoggedRoute>
            <RecentTransfer />
          </LoggedRoute>
                )}
      />
      <Route path={$route.transferHistory} element={<LoggedRoute><History /></LoggedRoute>} />
    </Route>
    <Route
      path={$route.main}
      element={<LoggedRoute><Navigate to={$route.home} /></LoggedRoute>}
    />
    <Route path={$route.login} element={<Login />} />
    <Route path={$route.register} element={<Register />} />
    <Route
      path={$route.emailConfirm}
      element={<LoggedRoute><EmailConfirm /></LoggedRoute>}
    />
    <Route
      path={$route.forgotPassword}
      element={(
        <UnloggedRoute>
          <ForgotPassword />
        </UnloggedRoute>
            )}
    />
    <Route
      path={$route.resetPassword}
      element={<UnloggedRoute><ResetPassword /></UnloggedRoute>}
    />
    <Route
      path={$route.resetPasswordSuccess}
      element={(
        <UnloggedRoute>
          <PasswordResetSuccess />
        </UnloggedRoute>
            )}
    />
    <Route
      path={$route.registrationSuccessfully}
      element={<UnloggedRoute><RegistrationSuccessfully /></UnloggedRoute>}
    />
    <Route path={$route.callback} element={<Callback />} />
    <Route path={$route.serviceLogin} element={<ServiceLoginPage />} />
    <Route path={$route.notFound} element={(<NotFound />)} />
  </Routes>
);

export default App;
