import React from 'react';
import BlankComponent from '@components/blank/BlankComponent';
import icon from '@assets/icons/no-services-icon.svg';
import { $emptyServicesBlankText } from '@data/text.constants';

const EmptyServicesBlank = () => (
  <BlankComponent
    icon={icon}
    title={$emptyServicesBlankText.title}
    description={$emptyServicesBlankText.description}
  />
);

export default EmptyServicesBlank;
