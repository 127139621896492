import React, { useState } from 'react';
import { useMobile } from '@hooks/index';
import { $chooseDestModalText } from '@data/text.constants';
import { IconButton, SearchField } from '@components/index';
import { useGlobalModalContext } from 'src/contexts';
import store from '@store/index';
import { getLoggedServiceData } from '@store/slices/music-services/musicService.utils';
import closeButton from '@features/modal-windows/assets/close-icon.svg';
import backButton from '@features/modal-windows/assets/arrow-back.svg';
import styles from './ChooseDestinationModal.module.scss';
import DestinationServicesList from './components/DestinationServicesList';

/**
 * Modal window with list of destination services.
 */
const ChooseDestinationModal = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const { isMobile } = useMobile();
  const { hideModal } = useGlobalModalContext();
  const { selectedService } = store.getState().library;

  return (
    <div className={styles.modal}>
      <div className={styles.header}>
        <div className={styles.up_header}>
          <IconButton image={backButton} onClick={hideModal} />
          <div>
            <div className={styles.transfer_text}>{$chooseDestModalText.transfer}</div>
          </div>
          <div className={styles.close_container}>
            <IconButton image={closeButton} onClick={hideModal} />
          </div>
        </div>
        <div className={styles.separate_line} />
        <div className={styles.service_info}>
          <div className={styles.source_service_text}>
            {$chooseDestModalText.sourceService}
          </div>
          <img
            src={getLoggedServiceData(selectedService!).logo}
            alt="source"
            className={styles.source_service_logo}
          />
          <div className={styles.email_service_text}>
            {selectedService!.email}
          </div>
        </div>
      </div>

      <div className={styles.content_wrapper}>
        <div className={styles.content_row_header}>

          <div className={styles.select_destination_text}>
            {isMobile
              ? $chooseDestModalText.destinationService
              : $chooseDestModalText.selectDestinationService}
          </div>

          <div className={styles.search_field}>
            <SearchField
              placeholder={$chooseDestModalText.searchPlaceholder}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

        </div>
        <div className={styles.scrollable_list}>
          <DestinationServicesList query={searchQuery} />
        </div>

      </div>
    </div>
  );
};

export default ChooseDestinationModal;
