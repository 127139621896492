import axios, { AxiosError, AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import { userActions } from '@store/slices/user/userSlice';
import store from '@store/index';
import $api from '@data/api.constants';
import { IApiResponse, RefreshModel, RefreshSuccess } from './HttpClient.types';
import type { IHttpClient } from './HttpClient.interface';

@injectable()
export default class HttpClient implements IHttpClient {
  private axiosInstance = axios.create({
    withCredentials: true,
  });

  constructor() {
    this.initRequest();
    this.initResponse();
  }

  private async getResponse<ResponseType>(
    action: Promise<AxiosResponse<any, any>>,
  ) : Promise<IApiResponse<ResponseType>> {
    try {
      const resp = await action;
      return {
        status: resp.status,
        data: resp.data,
      } as IApiResponse<ResponseType>;
    } catch (e) {
      if (e instanceof AxiosError) {
        return {
          status: e.status ?? 400,
          data: e.response?.data ?? e.request,
        };
      }
      throw e;
    }
  }

  async post<ResponseType>(url: string, data?: any): Promise<IApiResponse<ResponseType>> {
    return this.getResponse(
      this.axiosInstance.post(url, data),
    );
  }

  async put<ResponseType>(url: string, data?: any): Promise<IApiResponse<ResponseType>> {
    return this.getResponse(
      this.axiosInstance.put(url, data),
    );
  }

  async delete<ResponseType>(url: string, data?: any): Promise<IApiResponse<ResponseType>> {
    return this.getResponse(
      this.axiosInstance.delete(url, data),
    );
  }

  private async refresh(accessToken: string, refreshToken: string): Promise<RefreshModel> {
    const requestData = {
      grantType: 'refresh',
      refreshToken,
      accessToken,
    };
    const response = await this.axiosInstance.post<RefreshSuccess>($api.auth.token, requestData);

    if (response.status === 200) {
      const responseData = response.data as RefreshSuccess;
      return {
        type: 'ok',
        refreshToken: responseData.refreshToken,
        accessToken: responseData.accessToken,
      };
    }
    return {
      type: 'error',
    };
  }

  private initRequest(): void {
    this.axiosInstance.interceptors.request.use((config) => {
      const { accessToken } = store.getState().user;
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    });
  }

  private initResponse() {
    this.axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const { config } = error;
        if (!(error.response.status === 401 && !config.sent)) {
          return Promise.reject(error);
        }
        config.sent = true;

        const { accessToken, refreshToken } = store.getState().user;
        if (accessToken === null || refreshToken === null) {
          return Promise.reject(error);
        }

        const refreshResponse = await this.refresh(accessToken, refreshToken);
        if (refreshResponse.type === 'error') {
          return Promise.reject(error);
        }

        store.dispatch(userActions.refresh({
          refreshToken: refreshResponse.refreshToken,
          accessToken: refreshResponse.accessToken,
        }));

        config.headers.Authorization = `Bearer ${refreshResponse.accessToken}`;
        return axios(config);
      },
    );
  }
}
