import React from 'react';
import { $availableServicesText } from '@data/text.constants';
import $urls from '@data/url.constants';
import styles from './styles/AvailableServicesDescriptionComponent.module.scss';

const AvailableServicesDescriptionComponent = () => (
  <div>
    {$availableServicesText.availableServices}
    <br />
    <br />
    {$availableServicesText.suggest}
    <br />
    <div className={styles.description_button} role="presentation" onClick={() => window.open($urls.download)}>
      {$availableServicesText.link}
    </div>
  </div>
);

export default AvailableServicesDescriptionComponent;
