import MusicService from '@data/enums/musicService';
import useMusicKitAuth from '@features/service-authorization/hooks/useMusicKitAuth';
import useServiceRedirectUri from '@features/service-authorization/hooks/useServiceRedirectUri';
import useYoutubeAuth from '@features/service-authorization/hooks/useYoutubeAuth';

export default function useServiceAuthorization() {
  const { authorizeMusicKit } = useMusicKitAuth();
  const { serviceRedirectUri } = useServiceRedirectUri();
  const { authorizeYoutubeService } = useYoutubeAuth();
  async function authorizeMusicService(musicService: MusicService) {
    switch (musicService) {
      case MusicService.appleMusic: {
        await authorizeMusicKit(musicService);
        break;
      }
      case MusicService.youTube: {
        await authorizeYoutubeService(musicService);
        break;
      }
      case MusicService.youTubeMusic: {
        await authorizeYoutubeService(musicService);
        break;
      }
      default: {
        await serviceRedirectUri(musicService);
        break;
      }
    }
  }

  return {
    authorizeMusicService,
  };
}
