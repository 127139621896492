import { inject, injectable } from 'inversify';
import $serviceType from '@data/service.types.constant';
import { type IHttpClient } from '@lib/index';
import MusicService from '@data/enums/musicService';
import { CredentialsModel, ResponseError } from '@services/types/webapi.types';
import $api from '@data/api.constants';
import $responseTypes from '@data/response.types.constants';
import { IMusicServiceUserService } from './MusicServiceUserService.interface';
import {
  UserProfileModel,
  AuthorizedServicesModel,
  ReceivedCredentialsModel,
  FullCredentialsModel,
  SentCredentialsModel,
  UserProfileRequest,
  UserProfileResponse,
  UserProfileSuccess,
  AuthorizedServicesResponse,
  AuthorizedServicesSuccess,
  ReceiveCredentialsRequest,
  ReceiveCredentialsResponse,
  ReceiveCredentialsSuccess,
  SendCredentialsRequest,
  SendCredentialsResponse,
  SendCredentialsSuccess,
  RemoveCredentialsModel,
  RemoveCredentialsRequest,
  RemoveCredentialsResponse,
} from './MusicServiceUserService.types';

@injectable()
export default class MusicServiceUserService implements IMusicServiceUserService {
  @inject($serviceType.http) private http: IHttpClient;

  async getUserProfile(
    credentials: CredentialsModel,
  ): Promise<UserProfileModel> {
    const requestData = {
      credentials,
    } as UserProfileRequest;

    const response = await this.http.post<UserProfileResponse>(
      $api.serviceUser.profile,
      requestData,
    );

    if (response.status === 200) {
      const data = response.data as UserProfileSuccess;
      return {
        type: 'ok',
        id: data.userProfile.id,
        displayName: data.userProfile.displayName,
        email: data.userProfile.email,
        imageLink: data.userProfile.imageLink,
        isActiveSubscription: data.userProfile.isActiveSubscription,
      };
    }

    if (response.status === 400) {
      const data = response.data as ResponseError;
      if (data.type === $responseTypes.user.profile.profileUnknown) {
        return {
          type: 'ok',
        };
      }
    }

    const data = response.data as ResponseError;
    return {
      type: 'error',
      message: data.detail,
    };
  }

  async getAuthorizedServices(): Promise<AuthorizedServicesModel> {
    const response = await this.http.post<AuthorizedServicesResponse>(
      $api.serviceUser.authorizedServices,
    );

    if (response.status === 200) {
      const data = response.data as AuthorizedServicesSuccess;
      return {
        type: 'ok',
        credentials: data.credentials,
      };
    }

    const data = response.data as ResponseError;
    return {
      type: 'error',
      message: data.title,
    };
  }

  async removeCredentials(
    credentials: CredentialsModel[],
  ): Promise<RemoveCredentialsModel> {
    const requestData = {
      credentials,
    } as RemoveCredentialsRequest;

    const response = await this.http.post<RemoveCredentialsResponse>(
      $api.serviceUser.removeCredentials,
      requestData,
    );

    if (response.status === 200) {
      return {
        type: 'ok',
      };
    }

    const data = response.data as ResponseError;
    return {
      type: 'error',
      message: data.title,
    };
  }

  async receiveFullCredentials(
    musicServices: MusicService[],
  ): Promise<ReceivedCredentialsModel> {
    const requestData = {
      musicServices,
    } as ReceiveCredentialsRequest;

    const response = await this.http.post<ReceiveCredentialsResponse>(
      $api.serviceUser.receiveCredentials,
      requestData,
    );

    if (response.status === 200) {
      const data = response.data as ReceiveCredentialsSuccess;
      return {
        type: 'ok',
        credentials: data.credentials,
      };
    }

    const data = response.data as ResponseError;
    return {
      type: 'error',
      message: data.title,
    };
  }

  async sendFullCredentials(
    credentials: FullCredentialsModel[],
  ): Promise<SentCredentialsModel> {
    const requestData = {
      credentials,
    } as SendCredentialsRequest;

    const response = await this.http.post<SendCredentialsResponse>(
      $api.serviceUser.receiveCredentials,
      requestData,
    );

    if (response.status === 200) {
      const data = response.data as SendCredentialsSuccess;
      return {
        type: 'ok',
        addedCredentials: data.addedCredentials,
        updatedCredentials: data.updatedCredentials,
      };
    }

    const data = response.data as ResponseError;
    return {
      type: 'error',
      message: data.title,
    };
  }
}
