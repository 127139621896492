import { useState } from 'react';
import MusicService from '@data/enums/musicService';
import { useAppDispatch } from '@store/hooks';
import { logInServiceAsync } from '@store/slices/music-services/musicServicesThunks';
import { getMusicAuthService } from '../../../inversify.config';

type Status = 'init' | 'loading' | 'error' | 'success';

export default function useLoginPasswordAuth() {
  const [status, setStatus] = useState('init' as Status);
  const [error, setError] = useState('');
  const dispatch = useAppDispatch();

  async function loginPasswordAuth(
    service: MusicService,
    login: string,
    password: string,
  ): Promise<void> {
    setStatus('loading');

    const serviceAuth = getMusicAuthService();
    const musicService = service as MusicService;
    const res = await serviceAuth.loginPasswordAuth(musicService, login, password);
    if (res.type === 'error') {
      setError(res.message);
      setStatus('error');
      return;
    }

    await dispatch(logInServiceAsync(res.credentials));
    setStatus('success');
  }

  return {
    status,
    error,
    loginPasswordAuth,
  };
}
