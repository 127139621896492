import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '@store/index';
import { RecentTransfer } from '@store/slices/recent-transfers/recentTransfers.types';
import getTransferDate from '@store/slices/recent-transfers/recentTransfers.utils';
import { getTransferService } from '../../../inversify.config';

type RecentTransfersConfig = {
  rejectValue: string;
  state: RootState;
};

type ReturnType = {
  recentTransferItems: RecentTransfer[];
};

const loadRecentTransfers = createAsyncThunk<ReturnType, void, RecentTransfersConfig>(
  'recentTransfers/loadRecentTransfers',
  async (arg, thunkAPI) => {
    const transferService = getTransferService();
    const transferHistoryResponse = await transferService.getTransferHistory();
    if (transferHistoryResponse.type === 'error') {
      return thunkAPI.rejectWithValue(`Some problems with server: ${transferHistoryResponse.message}.`);
    }
    const transferHistory = transferHistoryResponse.transferItems.sort((a, b) => {
      const date1 = new Date(a.transferEndTime).getTime();
      const date2 = new Date(b.transferEndTime).getTime();
      return date2 - date1;
    });
    return {
      recentTransferItems: transferHistory.map((history) => {
        const {
          transferType,
          transferId,
          transferUnits,
          sourceCredentials,
          destinationCredentials,
          transferEndTime,
          isSuccessful,
          isCanceled,
        } = history;

        const keys = Object.keys(transferUnits);
        let itemsCount = keys.length;

        if (transferType !== 'Playlist') {
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            itemsCount = transferUnits[key].itemsCount;
          }
        }

        return ({
          transferId,
          sourceService: sourceCredentials.musicService,
          destinationService:
            destinationCredentials.musicService,
          countOfTransferred: itemsCount,
          type: transferType,
          date: getTransferDate(transferEndTime),
          isSuccessful,
          isCanceled,
        } as RecentTransfer);
      }),
    };
  },
);

export default loadRecentTransfers;
