import { useNavigate } from 'react-router-dom';
import MusicService from '@data/enums/musicService';
import $route from '@data/routing.constants';

const useAppNavigation = () => {
  const navigate = useNavigate();

  const toLibrary = (service: MusicService) => {
    navigate($route.library.replace(':service', service));
  };

  return {
    toLibrary,
  };
};

export default useAppNavigation;
