import store from '@store/index';
import {
  AlbumLibraryModel, ArtistLibraryModel,
  PlaylistLibraryModel,
  TrackLibraryModel,
} from '@store/slices/library/library.types';
import { MediaItem } from '@components/MediaItemsList';
import { $libraryPageText } from '@data/text.constants';
import { useAppSelector } from '@store/hooks';
import { assertUnreachable } from '../../../utils';

const getOpenedItemSelectedTracks = (): MediaItem[] => {
  const { items } = useAppSelector((state) => state.libraryItem);
  return items[0].tracks
    .filter((mediaItem) => mediaItem.isSelected)
    .map((track) => ({
      id: track.id,
      title: track.title,
      imageLink: track.imageLink,
      artist: track.artist,
      albumName: track.albumName,
      type: $libraryPageText.track,
    } as MediaItem));
};

const useLibraryModelType = () => {
  const { openedMediaItemId } = useAppSelector((state) => state.libraryItem);
  if (openedMediaItemId) {
    return getOpenedItemSelectedTracks();
  }
  const libraryType = useAppSelector((state) => state.library.selectedTab);
  const selectedMediaItems = store.getState()
    .library.items.filter((mediaItem) => mediaItem.isSelected);

  switch (libraryType) {
    case 'playlists': {
      const playlists = selectedMediaItems as PlaylistLibraryModel[];
      return playlists.map((playlist) => ({
        id: playlist.id,
        title: playlist.title,
        imageLink: playlist.imageLink,
        tracksCount: playlist.tracksCount,
        type: $libraryPageText.playlist,
      } as MediaItem));
    }
    case 'albums': {
      const albums = selectedMediaItems as AlbumLibraryModel[];
      return albums.map((album) => ({
        id: album.id,
        title: album.title,
        imageLink: album.imageLink,
        tracksCount: album.tracksCount,
        artist: album.artist,
        type: $libraryPageText.album,
      } as MediaItem));
    }
    case 'artists': {
      const artists = selectedMediaItems as ArtistLibraryModel[];
      return artists.map((artist) => ({
        id: artist.id,
        title: artist.title,
        imageLink: artist.imageLink,
        type: $libraryPageText.artist,
      } as MediaItem));
    }
    case 'tracks': {
      const tracks = selectedMediaItems as TrackLibraryModel[];
      return tracks.map((track) => ({
        id: track.id,
        title: track.title,
        imageLink: track.imageLink,
        artist: track.artist,
        albumName: track.albumName,
        type: $libraryPageText.track,
      } as MediaItem));
    }
    case undefined:
      return undefined;
    default:
      assertUnreachable(libraryType);
  }
  return undefined;
};

export default useLibraryModelType;
