import 'reflect-metadata';
import { Container } from 'inversify';
import $serviceType from '@data/service.types.constant';
import {
  AuthService,
  IAuthService,
  MusicServiceAuthService,
  IMusicServiceAuthService,
  MusicServiceUserService,
  IMusicServiceUserService,
  IAccountService,
  AccountService,
  LibraryService,
  ILibraryService,
  TransferService,
  ITransferService,
  SearchService,
  ISearchService,
  ITransferProgressService,
  TransferProgressService,
  ISettingsService,
  SettingsService,
  IKeysService,
  KeysService, IAdminService, AdminService,
} from '@services/index';
import {
  HttpClient,
  IHttpClient,
  ISignalRClient,
  SignalRClient,
} from '@lib/index';

const services = new Container();
services.bind<IAdminService>($serviceType.admin).to(AdminService);
services.bind<IAuthService>($serviceType.auth).to(AuthService);
services.bind<IMusicServiceAuthService>($serviceType.serviceAuth).to(MusicServiceAuthService);
services.bind<IMusicServiceUserService>($serviceType.serviceUser).to(MusicServiceUserService);
services.bind<IAccountService>($serviceType.account).to(AccountService);
services.bind<ILibraryService>($serviceType.library).to(LibraryService);
services.bind<ISearchService>($serviceType.search).to(SearchService);
services.bind<ITransferService>($serviceType.transfer).to(TransferService);
services.bind<ITransferProgressService>($serviceType.transferProgress).to(TransferProgressService);
services.bind<ISettingsService>($serviceType.settings).to(SettingsService);
services.bind<IKeysService>($serviceType.keys).to(KeysService);
services.bind<IHttpClient>($serviceType.http).to(HttpClient);
services.bind<ISignalRClient>($serviceType.signalr).to(SignalRClient);

export const getAccountService = () => services.get<IAccountService>($serviceType.account);
export const getMusicAuthService = () => services.get<IMusicServiceAuthService>(
  $serviceType.serviceAuth,
);
export const getMusicServiceUserService = () => services.get<IMusicServiceUserService>(
  $serviceType.serviceUser,
);
export const getLibraryService = () => services.get<ILibraryService>($serviceType.library);
export const getTransferService = () => services.get<ITransferService>($serviceType.transfer);
export const getTransferStatusService = () => services.get<ITransferProgressService>(
  $serviceType.transferProgress,
);
export const getSettingsService = () => services.get<ISettingsService>($serviceType.settings);
export const getKeysService = () => services.get<IKeysService>($serviceType.keys);

export default services;
export { $serviceType };
