import { inject, injectable } from 'inversify';
import { type IHttpClient } from '@lib/index';
import MusicService from '@data/enums/musicService';
import $api from '@data/api.constants';
import $serviceType from '@data/service.types.constant';
import { ResponseError } from '@services/types/webapi.types';
import {
  OauthRedirectUriRequest,
  OauthRedirectUriResponse,
  OauthCallbackRequest,
  OauthCallbackResponse,
  LoginPasswordAuthRequest,
  LoginPasswordAuthResponse,
  RedirectUriModel,
  OauthRedirectUriSuccess,
  ServiceAuthModel,
  OauthCallbackSuccess,
  LoginPasswordAuthSuccess,
  TokenAuthRequest,
  TokenAuthResponse,
  TokenAuthSuccess,
} from './MusicServiceAuthService.types';
import { IMusicServiceAuthService } from './MusicServiceAuthService.interface';

@injectable()
export default class MusicServiceAuthService implements IMusicServiceAuthService {
  @inject($serviceType.http) private http: IHttpClient;

  async getOauthRedirectUri(
    musicService: MusicService,
  ): Promise<RedirectUriModel> {
    const requestData = {
      musicService,
    } as OauthRedirectUriRequest;

    const response = await this.http.post<OauthRedirectUriResponse>(
      $api.serviceAuth.oauthRedirectUri,
      requestData,
    );

    if (response.status === 200) {
      const data = response.data as OauthRedirectUriSuccess;
      return {
        type: 'ok',
        uri: data.uri,
      };
    }

    const data = response.data as ResponseError;
    return {
      type: 'error',
      message: data.title,
    };
  }

  async sendOauthCallback(
    musicService: MusicService,
    code: string,
    state: string,
  ): Promise<ServiceAuthModel> {
    const requestData = {
      musicService,
      code,
      state,
    } as OauthCallbackRequest;

    const response = await this.http.post<OauthCallbackResponse>(
      $api.serviceAuth.oauthCallback,
      requestData,
    );

    if (response.status === 200) {
      const data = response.data as OauthCallbackSuccess;
      return {
        type: 'ok',
        credentials: data.credentials,
      };
    }

    const data = response.data as ResponseError;
    return {
      type: 'error',
      message: data.title,
    };
  }

  async loginPasswordAuth(
    musicService: MusicService,
    login: string,
    password: string,
  ): Promise<ServiceAuthModel> {
    const requestData = {
      musicService,
      login,
      password,
    } as LoginPasswordAuthRequest;

    const response = await this.http.post<LoginPasswordAuthResponse>(
      $api.serviceAuth.loginPasswordAuth,
      requestData,
    );

    if (response.status === 200) {
      const data = response.data as LoginPasswordAuthSuccess;
      return {
        type: 'ok',
        credentials: data.credentials,
      };
    }

    const data = response.data as ResponseError;
    return {
      type: 'error',
      message: data.title,
    };
  }

  async tokenAuth(
    musicService: MusicService,
    userToken: string,
  ): Promise<ServiceAuthModel> {
    const requestData = {
      musicService,
      userToken,
    } as TokenAuthRequest;

    const response = await this.http.post<TokenAuthResponse>(
      $api.serviceAuth.tokenAuth,
      requestData,
    );

    if (response.status === 200) {
      const data = response.data as TokenAuthSuccess;
      return {
        type: 'ok',
        credentials: data.credentials,
      };
    }

    const data = response.data as ResponseError;
    return {
      type: 'error',
      message: data.title,
    };
  }
}
