import { MusConvHeaderAuth } from '@features/authorization';
import MusicService from '@data/enums/musicService';
import ServiceLoginForm from '@features/service-authorization/components/forms/ServiceLoginForm';
import React from 'react';
import { useParams } from 'react-router-dom';
import NotFound from './NotFound';
import styles from './styles/Header.module.scss';

const ServiceLoginPage = () => {
  const { service } = useParams();
  const serviceTitle = MusicService[service as keyof typeof MusicService];

  return (
    !serviceTitle
      ? <NotFound />
      : (
        <>
          <div className={styles.header}>
            <MusConvHeaderAuth height="52" width="170" />
          </div>
          <ServiceLoginForm serviceTitle={serviceTitle} />
        </>
      )
  );
};

export default ServiceLoginPage;
