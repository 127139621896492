import React from 'react';
import logo from '@assets/musconv-header-logo.svg';
import styles from './styles/MusConvHeaderAuth.module.scss';

type Props = {
  width: string;
  height: string;
};

const MusConvHeaderAuth = ({ width, height } : Props) => (
  <div className={styles.header}>
    <img className={styles.logo} src={logo} alt="MusConv logo" height={height} width={width} />
  </div>
);

export default MusConvHeaderAuth;
