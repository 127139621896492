import React from 'react';
import HistoryHeader from '@features/history-page/components/HistoryHeader';
import { MediaDataGrid } from '@features/library-page';
import { Column } from 'react-table';
import imageAsset from '@assets/temp/user-avatar.svg';
import spotifyAsset from '@assets/service-logos/spotify-logo.svg';
import warningIcon from '@assets/status-icons/warning.svg';
import successfullyIcon from '@assets/status-icons/successfully.svg';
import inProgressIcon from '@assets/status-icons/in-progress.svg';
import $columnIds from '@data/columns.constants';
import styles from './styles/History.module.scss';

const data = [
  {
    imageLink: imageAsset,
    title: 'Shut Down',
    tracks: 773,
    type: 'Playlist',
    source: {
      sourceService: spotifyAsset,
      sourceUserIcon: imageAsset,
      sourceEmail: 'musconv@gmail.com',
    },
    destination: {
      destinationService: spotifyAsset,
      destinationUserIcon: imageAsset,
      destinationEmail: 'musconv@gmail.com',
    },
    status: warningIcon,
  }, {
    imageLink: imageAsset,
    title: 'Shut Down',
    tracks: 773,
    type: 'Playlist',
    source: {
      sourceService: spotifyAsset,
      sourceUserIcon: imageAsset,
      sourceEmail: 'musconv@gmail.com',
    },
    destination: {
      destinationService: spotifyAsset,
      destinationUserIcon: imageAsset,
      destinationEmail: 'musconv@gmail.com',
    },
    status: successfullyIcon,
  }, {
    imageLink: imageAsset,
    title: 'Shut Down',
    tracks: 773,
    type: 'Playlist',
    source: {
      sourceService: spotifyAsset,
      sourceUserIcon: imageAsset,
      sourceEmail: 'musconv@gmail.com',
    },
    destination: {
      destinationService: spotifyAsset,
      destinationUserIcon: imageAsset,
      destinationEmail: 'musconv@gmail.com',
    },
    status: successfullyIcon,
  }, {
    imageLink: imageAsset,
    title: 'Shut Down',
    tracks: 773,
    type: 'Playlist',
    source: {
      sourceService: spotifyAsset,
      sourceUserIcon: imageAsset,
      sourceEmail: 'musconv@gmail.com',
    },
    destination: {
      destinationService: spotifyAsset,
      destinationUserIcon: imageAsset,
      destinationEmail: 'musconv@gmail.com',
    },
    status: successfullyIcon,
  }, {
    imageLink: imageAsset,
    title: 'Shut Down',
    tracks: 773,
    type: 'Playlist',
    source: {
      sourceService: spotifyAsset,
      sourceUserIcon: imageAsset,
      sourceEmail: 'musconv@gmail.com',
    },
    destination: {
      destinationService: spotifyAsset,
      destinationUserIcon: imageAsset,
      destinationEmail: 'musconv@gmail.com',
    },
    status: inProgressIcon,
  }, {
    imageLink: imageAsset,
    title: 'Shut Down',
    tracks: 773,
    type: 'Playlist',
    source: {
      sourceService: spotifyAsset,
      sourceUserIcon: imageAsset,
      sourceEmail: 'musconv@gmail.com',
    },
    destination: {
      destinationService: spotifyAsset,
      destinationUserIcon: imageAsset,
      destinationEmail: 'musconv@gmail.com',
    },
    status: inProgressIcon,
  }, {
    imageLink: imageAsset,
    title: 'Shut Down',
    tracks: 773,
    type: 'Playlist',
    source: {
      sourceService: spotifyAsset,
      sourceUserIcon: imageAsset,
      sourceEmail: 'musconv@gmail.com',
    },
    destination: {
      destinationService: spotifyAsset,
      destinationUserIcon: imageAsset,
      destinationEmail: 'musconv@gmail.com',
    },
    status: inProgressIcon,
  }, {
    imageLink: imageAsset,
    title: 'Shut Down',
    tracks: 773,
    type: 'Playlist',
    source: {
      sourceService: spotifyAsset,
      sourceUserIcon: imageAsset,
      sourceEmail: 'musconv@gmail.com',
    },
    destination: {
      destinationService: spotifyAsset,
      destinationUserIcon: imageAsset,
      destinationEmail: 'musconv@gmail.com',
    },
    status: inProgressIcon,
  }, {
    imageLink: imageAsset,
    title: 'Shut Down',
    tracks: 773,
    type: 'Playlist',
    source: {
      sourceService: spotifyAsset,
      sourceUserIcon: imageAsset,
      sourceEmail: 'musconv@gmail.com',
    },
    destination: {
      destinationService: spotifyAsset,
      destinationUserIcon: imageAsset,
      destinationEmail: 'musconv@gmail.com',
    },
    status: warningIcon,
  }, {
    imageLink: imageAsset,
    title: 'Shut Down',
    tracks: 773,
    type: 'Playlist',
    source: {
      sourceService: spotifyAsset,
      sourceUserIcon: imageAsset,
      sourceEmail: 'musconv@gmail.com',
    },
    destination: {
      destinationService: spotifyAsset,
      destinationUserIcon: imageAsset,
      destinationEmail: 'musconv@gmail.com',
    },
    status: warningIcon,
  }, {
    imageLink: imageAsset,
    title: 'Shut Down',
    tracks: 773,
    type: 'Playlist',
    source: {
      sourceService: spotifyAsset,
      sourceUserIcon: imageAsset,
      sourceEmail: 'musconv@gmail.com',
    },
    destination: {
      destinationService: spotifyAsset,
      destinationUserIcon: imageAsset,
      destinationEmail: 'musconv@gmail.com',
    },
    status: successfullyIcon,
  }, {
    imageLink: imageAsset,
    title: 'Shut Down',
    tracks: 773,
    type: 'Playlist',
    source: {
      sourceService: spotifyAsset,
      sourceUserIcon: imageAsset,
      sourceEmail: 'musconv@gmail.com',
    },
    destination: {
      destinationService: spotifyAsset,
      destinationUserIcon: imageAsset,
      destinationEmail: 'musconv@gmail.com',
    },
    status: successfullyIcon,
  }, {
    imageLink: imageAsset,
    title: 'Shut Down',
    tracks: 773,
    type: 'Playlist',
    source: {
      sourceService: spotifyAsset,
      sourceUserIcon: imageAsset,
      sourceEmail: 'musconv@gmail.com',
    },
    destination: {
      destinationService: spotifyAsset,
      destinationUserIcon: imageAsset,
      destinationEmail: 'musconv@gmail.com',
    },
    status: successfullyIcon,
  }, {
    imageLink: imageAsset,
    title: 'Shut Down',
    tracks: 773,
    type: 'Playlist',
    source: {
      sourceService: spotifyAsset,
      sourceUserIcon: imageAsset,
      sourceEmail: 'musconv@gmail.com',
    },
    destination: {
      destinationService: spotifyAsset,
      destinationUserIcon: imageAsset,
      destinationEmail: 'musconv@gmail.com',
    },
    status: successfullyIcon,
  },
];

const columns = Array<Column>(
  {
    Header: 'Title',
    accessor: $columnIds.title,
  },
  {
    Header: 'Tracks',
    accessor: $columnIds.tracks,
    width: 70,
    Cell: ({ row }: any) => row.original.tracks,
  },
  {
    Header: 'Type',
    accessor: $columnIds.type,
    width: 70,
    Cell: ({ row }: any) => row.original.type,
  },
  {
    Header: 'Source',
    accessor: $columnIds.source,
    Cell: ({ row }: any) => (
      <div className={styles.service_container}>
        <div className={styles.service_image_container}>
          <img
            className={styles.service_image}
            src={row.original.source.sourceService}
            alt="serviceImg"
          />
        </div>
        <div className={styles.user_data_container}>
          <img
            className={styles.user_avatar}
            src={row.original.source.sourceUserIcon}
            alt="userIcon"
          />
          <div className={styles.user_email}>
            {row.original.source.sourceEmail}
          </div>
        </div>
      </div>
    ),
  },
  {
    Header: 'Destination',
    accessor: $columnIds.destination,
    Cell: ({ row }: any) => (
      <div className={styles.service_container}>
        <div className={styles.service_image_container}>
          <img
            className={styles.service_image}
            src={row.original.destination.destinationService}
            alt="serviceImg"
          />
        </div>
        <div className={styles.user_data_container}>
          <img
            className={styles.user_avatar}
            src={row.original.destination.destinationUserIcon}
            alt="userIcon"
          />
          <div className={styles.user_email}>
            {row.original.destination.destinationEmail}
          </div>
        </div>
      </div>
    ),
  },
  {
    Header: 'Status',
    width: 40,
    accessor: $columnIds.status,
    Cell: ({ row }: any) => (
      <img className={styles.status_icon} src={row.original.status} alt="statusIcon" />
    ),
  },
);

const History = () => (
  <div className={styles.container}>
    <HistoryHeader />
    <div className={styles.data_grid}>
      <MediaDataGrid
        tableColumns={columns}
        data={data}
      />
    </div>
  </div>
);

export default History;
