import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import loadRecentTransfers from '@store/slices/recent-transfers/recentTransfersThunks';
import { RecentTransfer } from '@store/slices/recent-transfers/recentTransfers.types';

type RecentTransferState = {
  recentTransfersList: Array<RecentTransfer>;
  isAllSelected: boolean;
  isAnySelected: boolean;
  loading: boolean;
};

const initialState: RecentTransferState = {
  recentTransfersList: [],
  isAllSelected: false,
  isAnySelected: false,
  loading: false,
};

const recentTransfersSlice = createSlice(
  {
    name: 'recent-transfers',
    initialState,
    reducers: {
      selectRecentTransfer(state, action: PayloadAction<RecentTransfer>) {
        if (!state.recentTransfersList) {
          return;
        }

        const selectedItem = state.recentTransfersList
          .find((e) => e.transferId === action.payload.transferId);
        if (!selectedItem) {
          return;
        }

        selectedItem.isSelected = !selectedItem.isSelected;
        state.isAllSelected = state.recentTransfersList.every(((item) => item.isSelected));
        state.isAnySelected = state.recentTransfersList.some((item) => item.isSelected);
      },
      selectAllRecentTransfers(state) {
        if (!state.recentTransfersList) {
          return;
        }

        state.isAllSelected = !state.isAllSelected;
        state.recentTransfersList.forEach((item) => { item.isSelected = state.isAllSelected; });
        state.isAnySelected = state.recentTransfersList.some((item) => item.isSelected);
      },
    },
    extraReducers: (builder) => {
      builder.addCase(loadRecentTransfers.pending, (state) => {
        state.recentTransfersList = [];
        state.loading = true;
      });
      builder.addCase(loadRecentTransfers.fulfilled, (state, action) => {
        state.recentTransfersList = action.payload.recentTransferItems;
        state.loading = false;
      });
      builder.addCase(loadRecentTransfers.rejected, (state, action) => {
        state.recentTransfersList = [];
        state.loading = false;
      });
    },
  },
);

export const recentTransfersActions = recentTransfersSlice.actions;
export default recentTransfersSlice.reducer;
