import { LibraryTabType } from '@store/slices/types';
import {
  AlbumLibraryModel, ArtistLibraryModel,
  LibraryModel,
  PlaylistLibraryModel,
  TrackLibraryModel,
} from '@store/slices/library/library.types';
import playlistsIcon from '@features/library-page/assets/playlists-icon.svg';
import albumsIcon from '@features/library-page/assets/albums-icon.svg';
import artistsIcon from '@features/library-page/assets/artists-icon.svg';
import tracksIcon from '@features/library-page/assets/tracks-icon.svg';
import { LibraryTabModel } from '@features/library-page/components/LibraryTabsForm';
import { ImageLinkType } from '@services/types/music.items.types';
import { assertUnreachable } from '../../../utils';
import { MusicServiceData } from '../music-services/musicService.types';

const searchItems = (tab: LibraryTabType, items: LibraryModel[], searchQuery: string) => {
  switch (tab) {
    case undefined: {
      return;
    }
    case 'playlists': {
      (items as PlaylistLibraryModel[]).forEach((item) => {
        if (item.title.toLowerCase().includes(searchQuery)) {
          item.isVisible = true;
          return;
        }
        item.isVisible = false;
      });
      break;
    }
    case 'albums': {
      (items as AlbumLibraryModel[]).forEach((item) => {
        if (item.title.toLowerCase().includes(searchQuery)
          || item.artist.toLowerCase().includes(searchQuery)) {
          item.isVisible = true;
          return;
        }
        item.isVisible = false;
      });
      break;
    }
    case 'tracks': {
      (items as TrackLibraryModel[]).forEach((item) => {
        if (item.title.toLowerCase().includes(searchQuery)
          || item.artist.toLowerCase().includes(searchQuery)
          || item.albumName?.toLowerCase().includes(searchQuery)) {
          item.isVisible = true;
          return;
        }
        item.isVisible = false;
      });
      break;
    }
    case 'artists': {
      (items as ArtistLibraryModel[]).forEach((item) => {
        if (item.title.toLowerCase().includes(searchQuery)) {
          item.isVisible = true;
          return;
        }
        item.isVisible = false;
      });
      break;
    }
    default:
      assertUnreachable(tab);
  }
};

export const getServiceTabs = (
  service: MusicServiceData,
  onTabClick: (tab?: LibraryTabType) => void,
):
LibraryTabModel[] => service.sourceData.map<LibraryTabModel>((tab) => {
  switch (tab) {
    case 'playlists':
      return {
        type: tab,
        icon: playlistsIcon,
        tabName: 'Playlists',
        onClick: () => onTabClick(tab),
      };
    case 'albums':
      return {
        type: tab,
        icon: albumsIcon,
        tabName: 'Albums',
        onClick: () => onTabClick(tab),
      };
    case 'tracks':
      return {
        type: tab,
        icon: tracksIcon,
        tabName: 'Tracks',
        onClick: () => onTabClick(tab),
      };
    case 'artists':
      return {
        type: tab,
        icon: artistsIcon,
        tabName: 'Artists',
        onClick: () => onTabClick(tab),
      };
    default:
      assertUnreachable(tab);
      return {
        type: tab,
        icon: playlistsIcon,
        tabName: 'none',
        onClick: () => { },
      };
  }
});

export const tryGetSmallImage = (imageLinks: ImageLinkType)
: string | undefined => imageLinks?.Small ?? imageLinks?.Unknown;

export const tryGetLargeImage = (imageLinks: ImageLinkType)
: string | undefined => imageLinks?.Large
  ?? imageLinks?.Medium
  ?? imageLinks?.Small
  ?? imageLinks?.Unknown;

export default searchItems;
