/* eslint-disable no-nested-ternary */

import React, { useEffect } from 'react';
import { useMobile } from '@hooks/responsive';
import MobileRecentTransfersPage
  from '@features/transfer-page/recent-transfers/responsive/MobileRecentTransfersPage';
import DesktopRecentTransfersPage
  from '@features/transfer-page/recent-transfers/responsive/DesktopRecentTransfersPage';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import loadRecentTransfers from '@store/slices/recent-transfers/recentTransfersThunks';
import EmptyAnimatedBlank from '@components/blank/EmptyAnimatedBlank';
import { $recentTransfersPageText } from '@data/text.constants';
import { useNavigate } from 'react-router-dom';
import $route from '@data/routing.constants';
import SiteLoader from '@components/SiteLoader';

const RecentTransfersPage = () => {
  const { isMobile } = useMobile();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loadRecentTransfers());
  }, []);
  const recentTransfers = useAppSelector((state) => state.recentTransfers.recentTransfersList);
  const isRecentLoading = useAppSelector((state) => state.recentTransfers.loading);
  const areAnyRecentTransfers = recentTransfers.length > 0;
  return (
    isRecentLoading ? (
      <SiteLoader headerText="Loading..." />
    ) : (
      (areAnyRecentTransfers)
        ? isMobile
          ? <MobileRecentTransfersPage recentTransfers={recentTransfers} />
          : <DesktopRecentTransfersPage />
        : (
          <EmptyAnimatedBlank
            title={$recentTransfersPageText.noRecentTransfers}
            description={$recentTransfersPageText.createTransfer}
            buttonText={$recentTransfersPageText.getStarted}
            onClick={() => navigate($route.main)}
          />
        )
    )
  );
};

export default RecentTransfersPage;
