import React from 'react';
import { Link } from 'react-router-dom';
import { $registerSuccessText } from '@data/text.constants';
import { useMobile } from '@hooks/index';
import $route from '@data/routing.constants';
import { StyledButton } from '@components/index';
import animation from '@assets/animations/registration-successfully.json';
import GoToAppButton from '@features/authorization/components/buttons/GoToAppButton';
import { IconContainer } from '../index';
import styles from './styles/RegistrationSuccessfullyForm.module.scss';

const RegistrationSuccessfullyForm = () => {
  const { isMobile } = useMobile();
  return (
    <div className={styles.icon_container}>
      <IconContainer icon={animation} title={$registerSuccessText.title}>
        <div className={styles.info_text}>
          {$registerSuccessText.info1}
        </div>
        <div className={styles.button_container}>
          <Link to={$route.home}>
            <GoToAppButton />
          </Link>
        </div>
        {!isMobile && (<hr className={styles.line} />)}
      </IconContainer>
    </div>
  );
};

export default RegistrationSuccessfullyForm;
