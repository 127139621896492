import React from 'react';
import { MusConvHeaderAuth, PasswordResetSuccessForm } from '@features/authorization';
import styles from './styles/Header.module.scss';

const PasswordResetSuccess = () => (
  <>
    <div className={styles.header}>
      <MusConvHeaderAuth height="52" width="170" />
    </div>
    <PasswordResetSuccessForm />
  </>
);
export default PasswordResetSuccess;
