import React, { useState } from 'react';
import HistoryTabButton from '@features/history-page/components/buttons/HistoryTabButton';
import { SearchField } from '@components/index';
import { $activeTransferText } from '@data/text.constants';
import styles from './styles/HistoryHeader.module.scss';

const HistoryHeader = () => {
  const [searchQuery, setSearchQuery] = useState('');
  return (
    <div className={styles.container}>
      <div className={styles.tabs_container}>
        <HistoryTabButton
          title="All"
          onClick={() => {
          }}
        />
        <HistoryTabButton
          title="Playlists"
          onClick={() => {
          }}
        />
        <HistoryTabButton
          title="Albums"
          onClick={() => {
          }}
        />
        <HistoryTabButton
          title="Tracks"
          onClick={() => {
          }}
        />
      </div>
      <div className={styles.search_field}>
        <SearchField
          placeholder={$activeTransferText.searchTransfers}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
    </div>
  );
};

export default HistoryHeader;
