import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useNavigate } from 'react-router-dom';
import { useGlobalModalContext } from 'src/contexts';
import { logOutServiceAsync } from '@store/slices/music-services/musicServicesThunks';
import { getLoggedServiceData } from '@store/slices/music-services/musicService.utils';
import { actionConfirmationActions as actions } from '@store/slices/action-confirmation/actionConfirmationSlice';
import $route from '@data/routing.constants';
import logOutIcon from '@assets/icons/log-out-icon.svg';
import logOutAnimation from '@assets/animations/logout.json';
import SidebarSpan from '../components/SidebarSpan';
import UserAccountForm from '../components/UserAccountForm';
import LibraryTabsForm, { LibraryTabModel } from '../components/LibraryTabsForm';
import styles from './styles/ServiceSidebar.module.scss';

type Props = {
  serviceLogo: string;
  userAvatar?: string;
  username?: string;
  email?: string;
  tabs: Array<LibraryTabModel>;
};

const ServiceSidebar = ({
  serviceLogo,
  userAvatar,
  username,
  email,
  tabs,
}: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showModal } = useGlobalModalContext();
  const selectedService = useAppSelector((state) => state.library.selectedService);
  const actionConfirmed = useAppSelector((state) => state.actionConfirmation.isConfirmed);
  const showLogout = () => {
    const service = selectedService && getLoggedServiceData(selectedService);
    return showModal({
      type: 'confirm_action',
      props: {
        title: `Logging out from ${service?.title ?? 'service'}`,
        description: 'Do you really want to log out?',
        icon: logOutAnimation,
      },
    });
  };
  useEffect(() => {
    if (actionConfirmed === undefined) {
      return;
    }
    if (actionConfirmed) {
      dispatch(logOutServiceAsync());
      navigate($route.home);
    }
    dispatch(actions.resetConfirmation());
  }, [actionConfirmed]);
  return (
    <div className={styles.container}>
      <div className={styles.service_logo_container}>
        <img
          className={styles.service_logo}
          src={serviceLogo}
          alt="serviceLogo"
        />
        <button
          className={styles.logout_button}
          type="button"
          onClick={showLogout}
        >
          <img className={styles.logout_icon} src={logOutIcon} alt="logOut" />
        </button>
      </div>
      <div className={styles.inner_container}>
        {(username || email) && (
        <UserAccountForm
          userAvatar={userAvatar}
          email={email}
          username={username}
        />
        )}
        <SidebarSpan />
        <LibraryTabsForm serviceTabs={tabs} />
      </div>
    </div>
  );
};

export default ServiceSidebar;
