import React, { ChangeEvent, useState } from 'react';
import { StyledInput } from '@components/index';
import useGetUserData from '@features/admin-page/hooks/useGetUserData';
import useResetTrialTracks from '@features/admin-page/hooks/useResetTrialTracks';
import ChangeLicenseWidget from '@features/admin-page/components/ChangeLicenseWidget';
import styles from './AdminPage.module.scss';

const AdminPage = () => {
  const { getUserData } = useGetUserData();
  const { resetTrialTracks } = useResetTrialTracks();
  const [email, setEmail] = useState('');
  const [userData, setUserData] = useState('');
  const inputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const getUserOnClick = async () => {
    const userDataResponse = await getUserData(email);
    if (userDataResponse.status === 'ok') {
      setUserData(userDataResponse.licenseType);
    }
  };

  const resetOnClick = async () => {
    const userDataResponse = await getUserData(email);
    if (userDataResponse.status === 'ok') {
      const { userId } = userDataResponse;
      await resetTrialTracks(userId);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.column_container}>
        <div className={styles.email_field}>
          <StyledInput
            type="email"
            title=""
            placeholder="Enter user email"
            value={email}
            onChange={inputChange}
          />
        </div>
        <div className={styles.row_container}>
          <div
            className={styles.reset_button}
            role="presentation"
            onClick={async () => {
              await resetOnClick();
            }}
          >
            Reset trial tracks
          </div>
          <div
            className={styles.get_user_button}
            role="presentation"
            onClick={async () => {
              await getUserOnClick();
            }}
          >
            Get user license
          </div>
          <div>
            User license:
            {' '}
            {userData}
          </div>
        </div>
      </div>
      <ChangeLicenseWidget userEmail={email} />
    </div>
  );
};

export default AdminPage;
