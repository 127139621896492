import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LibraryItemState, ItemWithTracksLibraryModel, TrackLibraryModel } from '@store/slices/library/library.types';
import getLibraryItemById from './libraryItemThunks';
import searchItems from '../library.helpers';

const initialState: LibraryItemState = {
  items: [],
  isAllSelected: false,
  isAnySelected: false,
  status: 'init',
};

const libraryItemSlice = createSlice({
  name: 'libraryItem',
  initialState,
  reducers: {
    selectItem(state, action: PayloadAction<TrackLibraryModel>) {
      if (!state.items[0].tracks) {
        return;
      }
      const selectedItem = state.items[0].tracks.find((e) => e.id === action.payload.id);
      if (!selectedItem) {
        return;
      }
      selectedItem.isSelected = !selectedItem.isSelected;
      state.isAllSelected = state.items[0].tracks.every(((item) => item.isSelected));
      state.isAnySelected = state.items[0].tracks.some((item) => item.isSelected);
    },
    selectAllItems(state) {
      if (!state.items[0].tracks) {
        return;
      }
      state.isAllSelected = !state.isAllSelected;
      state.items[0].tracks.forEach((item) => { item.isSelected = state.isAllSelected; });
      state.isAnySelected = state.items[0].tracks.some((item) => item.isSelected);
    },
    searchTrack(state, action: PayloadAction<{ searchQuery: string }>) {
      const searchQuery = action.payload.searchQuery.toLowerCase();
      if (!searchQuery) {
        state.items[0]?.tracks.forEach((item) => { item.isVisible = true; });
        return;
      }

      searchItems('tracks', state.items[0].tracks, searchQuery);
    },
    clearState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLibraryItemById.pending, (state, action) => {
      state.lastRequestId = action.meta.requestId;
      state.status = 'loading';
      state.items = [];
      state.openedMediaItemId = action.meta.arg.id;
    });
    builder.addCase(getLibraryItemById.fulfilled, (state, action) => {
      state.status = 'success';
      state.items = Array<ItemWithTracksLibraryModel>(action.payload.itemDetails);
      state.openedMediaItemId = action.meta.arg.id;
    });
    builder.addCase(getLibraryItemById.rejected, (state, action) => {
      if (action.error.name === 'AbortError') {
        return;
      }
      state.status = 'error';
      state.items = [];
      state.errorMessage = action.payload;
    });
  },
});
export const libraryItemActions = libraryItemSlice.actions;
export default libraryItemSlice.reducer;
