import React from 'react';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { $libraryPageText as text } from '@data/text.constants';
import { Command } from '@features/library-page/buttons/CommandButton';
import commandDelete from '@assets/command-icons/delete-icon.svg';
import commandAddTracks from '@assets/command-icons/add-tracks-icon.svg';
import {
  ServiceSidebar, ActionsHeader, MediaDataGrid, MobileLibraryItems,
} from '@features/library-page';
import { useMobile, useTablet } from '@hooks/responsive';
import useLibraryPage from '@pages/hooks/useLibraryPage';
import useLibraryColumns from '@pages/hooks/useLibraryColumns';
import { getLoggedServiceData, getLoggedServiceFromUrl } from '@store/slices/music-services/musicService.utils';
import { useParams } from 'react-router-dom';
import SiteLoader from '@components/SiteLoader';
import { EmptyLibraryBlank } from '@components/index';
import { libraryActions } from '@store/slices/library/librarySlice';
import { LibraryModel } from '@store/slices/library/library.types';
import BlankComponent from '@components/blank/BlankComponent';
import icon from '@assets/icons/empty-library-icon.svg';
import styles from './styles/LibraryPage.module.scss';
import getHiddenColumns from './utils/Library.utils';

const commands: Array<Command> = [
  // { icon: commandDelete, name: 'Delete', onClick: () => {} },
  // { icon: commandAddTracks, name: 'Add tracks', onClick: () => {} },
];

const LibraryPage = () => {
  const { isMobile } = useMobile();
  const { isVerticalTablet } = useTablet();
  const {
    items, status, selectedTab, errorMessage,
  } = useAppSelector((state) => state.library);
  const { mobileColumns, desktopColumns } = useLibraryColumns();
  const { service } = useParams();
  const selectedService = getLoggedServiceFromUrl(service!);
  const { tabs } = useLibraryPage();

  const serviceLogo = getLoggedServiceData(selectedService).logo;
  const data = items.filter((i) => i.isVisible);

  const dispatch = useAppDispatch();
  const rowCheckbox = {
    isVisible: true,
    onCheck: (item: LibraryModel) => dispatch(libraryActions.selectItem(item)),
  };
  const { isAllSelected, isAnySelected } = useAppSelector((state) => state.library);

  return (
    <div className={styles.wrapper}>
      {!isMobile ? (
        <div className={styles.container}>
          {!isVerticalTablet && (
            <ServiceSidebar
              serviceLogo={serviceLogo}
              userAvatar={selectedService.avatar}
              email={selectedService.email}
              username={selectedService.nickName}
              tabs={tabs}
            />
          )}
          <div className={styles.content_box}>
            <div className={styles.playlists_header}>
              <ActionsHeader
                searchHint={`${text.search} ${selectedTab}`}
                commands={commands}
                toggleCheckbox={() => dispatch(libraryActions.selectAllItems())}
                isChecked={isAllSelected}
                isAnySelected={isAnySelected}
              />
            </div>
            {status === 'loading' && (
            <div className={styles.loader}>
              <SiteLoader
                headerText={`${text.loading} ${selectedTab}...`}
              />
            </div>
            )}
            {status === 'init' && (
            <div style={items.length === 0 ? { flex: 1 } : { flex: 'auto' }}>
              <MediaDataGrid
                tableColumns={desktopColumns}
                hiddenColumns={getHiddenColumns(data)}
                data={items.filter((i) => i.isVisible)}
                rowCheckbox={rowCheckbox}
              />
            </div>
            )}
            {status === 'init' && items.length === 0 && <EmptyLibraryBlank />}
            {status === 'error' && (
            <BlankComponent
              icon={icon}
              title="Library error"
              description={errorMessage || 'Something went wrong'}
            />
            )}
          </div>
        </div>
      ) : (
        <>
          {status === 'loading' && (
          <div className={styles.loader}>
            <SiteLoader
              headerText={`${text.loading} ${selectedTab}...`}
            />
          </div>
          )}
          {status === 'init'
            && (
              <MobileLibraryItems
                columns={mobileColumns}
                hiddenColumns={getHiddenColumns(data)}
                data={items.filter((i) => i.isVisible)}
                commands={commands}
                rowCheckbox={rowCheckbox}
              />
            )}
        </>
      )}
    </div>
  );
};

export default LibraryPage;
