import React from 'react';
import styles from './styles/ErrorMessage.module.scss';

type Props = {
  message: string;
};

const ErrorMessage = ({ message }: Props) => (
  <div className={styles.text}>{message}</div>
);

export default ErrorMessage;
