import { inject, injectable } from 'inversify';
import { IAdminService } from '@services/admin/AdminService.interface';
import {
  ChangeLicenseModel, ChangeLicenseResponse,
  ResetTrialModel, ResetTrialResponse,
  UserDataError,
  UserDataModel,
  UserDataResponse,
  UserDataSuccess,
} from '@services/admin/AdminService.types';
import $serviceType from '@data/service.types.constant';
import { type IHttpClient } from '@lib/index';
import $api from '@data/api.constants';
import { ResponseError } from '@services/types/webapi.types';

@injectable()
export default class AdminService implements IAdminService {
  @inject($serviceType.http) private http: IHttpClient;

  async getUserDataByEmail(userEmail: string): Promise<UserDataModel> {
    const response = await this.http.post<UserDataResponse>(
      $api.admin.getAllUserInfo,
      { userEmail },
    );

    if (response.status === 200) {
      const data = response.data as UserDataSuccess;
      return {
        status: 'ok',
        userId: data.userId,
        email: data.email,
        licenseType: data.licenseType,
        isActivated: data.isActivated,
      };
    }

    const data = response.data as UserDataError;
    return {
      status: 'error',
      type: data.type,
    };
  }

  async resetTrialTracks(userId: string): Promise<ResetTrialModel> {
    const response = await this.http.post<ResetTrialResponse>(
      $api.webAdmin.resetTrialTracks,
      { userId },
    );

    if (response.status === 200) {
      return {
        type: 'ok',
      };
    }

    const data = response.data as ResponseError;
    return {
      type: 'error',
      message: data.detail,
    };
  }

  async changeUserLicense(userEmail: string, licenseName: string): Promise<ChangeLicenseModel> {
    const response = await this.http.post<ChangeLicenseResponse>(
      $api.admin.changeUserLicense,
      { userEmail, licenseName },
    );

    if (response.status === 200) {
      return {
        type: 'ok',
      };
    }

    const data = response.data as ResponseError;
    return {
      type: 'error',
      message: data.detail,
    };
  }
}
