import { createSlice } from '@reduxjs/toolkit';

type ActionConfirmationState = {
  isConfirmed?: boolean;
};

const initialState: ActionConfirmationState = {
  isConfirmed: undefined,
};

const actionConfirmationSlice = createSlice({
  name: 'action-confirmation',
  initialState,
  reducers: {
    confirmAction(state) {
      state.isConfirmed = true;
    },
    cancelAction(state) {
      state.isConfirmed = false;
    },
    resetConfirmation(state) {
      state.isConfirmed = undefined;
    },
  },
});

export const actionConfirmationActions = actionConfirmationSlice.actions;
export default actionConfirmationSlice.reducer;
