import React from 'react';
import { $loginText } from '@data/text.constants';
import styles from './styles/HorizontalSpan.module.scss';

const HorizontalSpan = () => (
  <div className={styles.horizontal_span}>
    <span>{$loginText.or}</span>
  </div>
);

export default HorizontalSpan;
