import React from 'react';
import animation from '@assets/animations/service-login.json';
import { IconContainer } from '@features/authorization/components';
import { $loginText, $serviceLoginFormText } from '@data/text.constants';
import { StyledButton, StyledInput } from '@components/index';
import { useEmailValidation, usePasswordValidation } from '@features/authorization/hooks/validations';
import { useNavigate } from 'react-router-dom';
import $route from '@data/routing.constants';
import { useLoginPasswordAuth } from '@features/service-authorization/hooks';
import MusicService from '@data/enums/musicService';
import styles from './styles/ServiceLoginForm.module.scss';

interface ServiceLoginFormProps {
  serviceTitle: string
}

const ServiceLoginForm = ({ serviceTitle } : ServiceLoginFormProps) => {
  const {
    emailField,
    setEmailField,
    validEmailField,
    emailValidation,
  } = useEmailValidation();

  const {
    passwordField,
    setPasswordField,
    validPasswordField,
    passwordValidation,
  } = usePasswordValidation();

  // TODO: error handling
  const {
    loginPasswordAuth,
  } = useLoginPasswordAuth();

  const navigate = useNavigate();

  const serviceSignIn = async (email : string, password : string) => {
    // TODO: use proper MusicService parameter
    await loginPasswordAuth(MusicService.spotify, email, password);

    navigate($route.home);
  };

  const signIn = async () => {
    const isPasswordValid = validPasswordField();
    const isEmailValid = validEmailField();
    if (isEmailValid && isPasswordValid) {
      await serviceSignIn(emailField, passwordField);
    }
  };

  return (
    <IconContainer
      icon={animation}
      title={$serviceLoginFormText.title(serviceTitle)}
    >
      <div className={styles.container}>
        <StyledInput
          type="email"
          title={$loginText.emailAddress}
          value={emailField}
          onChange={(e) => setEmailField(e.target.value)}
          placeholder={$loginText.enterYourEmail}
          validation={emailValidation}
        />
        <div className={styles.password}>
          <StyledInput
            onChange={(e) => setPasswordField(e.target.value)}
            value={passwordField}
            type="password"
            placeholder={$loginText.password}
            title={$loginText.password}
            validation={passwordValidation}
          />
        </div>
        <div className={styles.signin}>
          <StyledButton title={$serviceLoginFormText.signIn} onClick={signIn} />
        </div>
      </div>
    </IconContainer>
  );
};

export default ServiceLoginForm;
