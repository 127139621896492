import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useMobile, useTablet } from '@hooks/index';
import DesktopHeader from '@features/main-page/components/headers/DesktopHeader';
import { SideBarForm } from '@features/main-page/index';
import MobileHeader from '@features/main-page/components/headers/MobileHeader';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { refreshLoggedServiceAsync } from '@store/slices/music-services/musicServicesThunks';
import { $activeTransferText } from '@data/text.constants';
import styles from './MainPageLayout.module.scss';
import { useGlobalModalContext } from '../../contexts';

const MainPageLayout = () => {
  const dispatch = useAppDispatch();
  const { isVerticalTablet } = useTablet();
  const { isMobile } = useMobile();
  useEffect(() => {
    dispatch(refreshLoggedServiceAsync());
  }, []);

  const isTrialTransferEnded = useAppSelector((state) => state.activeTransfers
    .items[0]?.actionStatus
    .includes($activeTransferText.trialCompletedStatus));
  const trialTransferStatus = useAppSelector((state) => state.beginTransfer.status);
  const tracksTransferred = useAppSelector((state) => state.activeTransfers
    .items[0]?.trialTransferred);
  const { showModal } = useGlobalModalContext();
  useEffect(() => {
    switch (trialTransferStatus) {
      case 'finished': {
        if (isTrialTransferEnded) {
          showModal({
            type: 'trial_version',
            props: { transferredTracks: tracksTransferred ?? 0 },
          });
        }
        break;
      }
      default:
    }
  }, [trialTransferStatus]);

  return (
    <div className={styles.main}>
      {/* transfer view for mobile/tablet {(isMobile || isVerticalTablet) && <TransferCircle />} */}
      {!isMobile && !isVerticalTablet && <SideBarForm />}
      <div className={styles.column}>
        <div className={styles.header}>
          {
              isMobile
                ? <MobileHeader />
                : <DesktopHeader />
          }
        </div>
        <div className={styles.outlet}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MainPageLayout;
