import React from 'react';
import styles from './styles/BlankButton.module.scss';

type Props = {
  icon: string;
  onClick: () => void;
};

const BlankButton = ({ icon, onClick }: Props) => (
  <div className={styles.container}>
    <div
      className={styles.button}
      role="presentation"
      onClick={onClick}
    >
      <img className={styles.button_img} src={icon} alt="blank" />
    </div>
  </div>
);

export default BlankButton;
