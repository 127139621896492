import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { $libraryItemText as text } from '@data/text.constants';
import { useMobile, useTablet } from '@hooks/responsive';
import { Command } from '@features/library-page/buttons/CommandButton';
import LibraryMediaItemHeader from '@features/library-page/components/LibraryMediaItemHeader';
import {
  ActionsHeader, MediaDataGrid, MobileLibraryItems, ServiceSidebar,
} from '@features/library-page';
import { getLoggedServiceData, getLoggedServiceFromUrl } from '@store/slices/music-services/musicService.utils';
import { useParams } from 'react-router-dom';
import { libraryItemActions } from '@store/slices/library/library-item/libraryItemSlice';
import useLibraryColumns from '@pages/hooks/useLibraryColumns';
import useLibraryPage from '@pages/hooks/useLibraryPage';
import SiteLoader from '@components/SiteLoader';
import { TrackLibraryModel } from '@store/slices/library/library.types';
import { EmptyLibraryBlank } from '@components/index';
import getLibraryItemByIdAsync from '@store/slices/library/library-item/libraryItemThunks';
import { LibraryTabType } from '@store/slices/types';
import commandDelete from '@assets/command-icons/delete-icon.svg';
import commandAddTracks from '@assets/command-icons/add-tracks-icon.svg';
import styles from './styles/LibraryItemTracksPage.module.scss';
import getHiddenColumns, { getVisibleItemTracks } from './utils/Library.utils';

const commands: Array<Command> = [
  // { icon: commandDelete, name: 'Delete', onClick: () => {} },
  // { icon: commandAddTracks, name: 'Add tracks', onClick: () => {} },
];

const LibraryItemTracksPage = () => {
  const { items, status } = useAppSelector((state) => state.libraryItem);
  const { tabs } = useLibraryPage();
  const { isMobile } = useMobile();
  const { isTablet } = useTablet();
  const { service, type, id } = useParams();
  const selectedService = getLoggedServiceFromUrl(service!);
  const selectedServiceData = getLoggedServiceData(selectedService);
  const dispatch = useAppDispatch();
  const { desktopColumns, mobileColumns } = useLibraryColumns();

  const rowCheckbox = {
    isVisible: true,
    onCheck: (item: TrackLibraryModel) => dispatch(libraryItemActions.selectItem(item)),
  };

  useEffect(() => {
    dispatch(getLibraryItemByIdAsync({ id: id!, selectedTab: type as LibraryTabType }));
  }, []);

  useEffect(() => () => {
    dispatch(libraryItemActions.clearState());
  }, []);
  const { isAllSelected, isAnySelected } = useAppSelector((state) => state.libraryItem);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {!isMobile && !isTablet && (
        <ServiceSidebar
          serviceLogo={selectedServiceData?.logo!}
          userAvatar={selectedService?.avatar}
          email={selectedService?.email}
          username={selectedService?.nickName}
          tabs={tabs}
        />
        )}
        {status === 'loading' && (<div className={styles.loader}><SiteLoader headerText={text.loading} /></div>)}
        {status === 'success' && (
          <div className={styles.content_box}>
            <LibraryMediaItemHeader mediaItem={items[0]} service={selectedServiceData!} />
            {!isMobile && (
            <div className={styles.actions_header}>
              <ActionsHeader
                searchHint={text.searchTracks}
                commands={commands}
                toggleCheckbox={() => dispatch(libraryItemActions.selectAllItems())}
                isChecked={isAllSelected}
                isAnySelected={isAnySelected}
                isLibraryItem
              />
            </div>
            )}
            {isMobile ? (
              <div className={styles.mob_items}>
                <MobileLibraryItems
                  columns={mobileColumns}
                  hiddenColumns={getHiddenColumns(getVisibleItemTracks(items))}
                  data={getVisibleItemTracks(items)}
                  commands={commands}
                  searchPlaceholder={text.searchTracks}
                  rowCheckbox={rowCheckbox}
                  isLibraryItem
                />
              </div>

            ) : (
              <div style={items.length === 0 ? { flex: 1 } : { flex: 'auto' }}>
                {items[0].tracks.length === 0 ? <EmptyLibraryBlank /> : (
                  <MediaDataGrid
                    tableColumns={desktopColumns}
                    hiddenColumns={getHiddenColumns(getVisibleItemTracks(items))}
                    data={getVisibleItemTracks(items)}
                    rowCheckbox={rowCheckbox}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default LibraryItemTracksPage;
