import $serviceType from '@data/service.types.constant';
import { HttpCodes, type IHttpClient } from '@lib/index';
import { inject, injectable } from 'inversify';
import $api from '@data/api.constants';
import { IApiResponse } from '@lib/http/HttpClient.types';
import { IAccountService } from './AccountService.interface';
import {
  ActivateError,
  ActivateModel,
  ActivateResponse,
  ForgotPasswordModel,
  ForgotPasswordResponse,
  ForgotPasswordError,
  ChangePasswordResponse,
  ChangePasswordModel,
  ChangePasswordError,
} from './AccountService.types';

@injectable()
export default class AccountService implements IAccountService {
  @inject($serviceType.http) private http: IHttpClient;

  async activate(secret: string): Promise<ActivateModel> {
    const request = { registrationSecret: secret };
    let response: IApiResponse<ActivateResponse>;
    try {
      response = await this.http.post<ActivateResponse>($api.account.activate, request);
    } catch (e) {
      return {
        status: 'error',
        type: undefined,
      };
    }

    if (response.status === HttpCodes.Ok) {
      return {
        status: 'ok',
      };
    }

    const error = response.data as ActivateError;
    return {
      status: 'error',
      type: error.type,
    };
  }

  async forgotPassword(email: string): Promise<ForgotPasswordModel> {
    const request = { email };
    const response = await this.http.post<ForgotPasswordResponse>($api.account.forgot, request);
    if (response.status === HttpCodes.Ok) {
      return {
        status: 'ok',
      };
    }

    const error = response.data as ForgotPasswordError;
    return {
      status: 'error',
      type: error.type,
    };
  }

  async changePassword(
    email: string,
    password: string,
    secret: string,
  ): Promise<ChangePasswordModel> {
    const request = {
      email,
      password,
      secret,
    };
    const response = await this.http.post<ChangePasswordResponse>(
      $api.account.changePassword,
      request,
    );
    if (response.status === HttpCodes.Ok) {
      return {
        status: 'ok',
      };
    }

    const error = response.data as ChangePasswordError;
    return {
      status: 'error',
      type: error.type,
    };
  }
}
