import React from 'react';
import styles from '@components/blank/styles/EmptyAnimatedBlank.module.scss';
import { StyledButton } from '@components/index';

type Props = {
  title: string;
  description: string;
  buttonText: string;
  onClick: () => void;
};

const EmptyAnimatedBlank = ({
  title, description, buttonText, onClick,
}: Props) => (
  <div className={styles.container}>
    <div className={styles.ball} />
    <div className={styles.text_wrapper}>
      <div className={styles.title}>
        {title}
      </div>
      <div className={styles.details}>
        {description}
      </div>
    </div>
    <div className={styles.button}>
      <StyledButton
        title={buttonText}
        onClick={onClick}
      />
    </div>
  </div>
);

export default EmptyAnimatedBlank;
