import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from '@store/index';
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from './App';
import { GlobalModal } from './contexts';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <PersistGate persistor={persistor}>
        <GoogleOAuthProvider clientId="720667085869-6250pbc36d46rk4p4avb1n2cgn5sqbu2.apps.googleusercontent.com">
          <GlobalModal>
            <App />
          </GlobalModal>
        </GoogleOAuthProvider>
      </PersistGate>
    </BrowserRouter>
  </Provider>,
);
