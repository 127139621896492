import React from 'react';
import { $emailConfText } from '@data/text.constants';
import animation from '@assets/animations/confirm-email.json';
import GoToAppButton from '@features/authorization/components/buttons/GoToAppButton';
import { IconContainer } from '../index';
import styles from './styles/EmailConfirmForm.module.scss';

type Props = {
  email: string;
};

const EmailConfirmForm = ({ email }: Props) => (
  <IconContainer icon={animation} title={$emailConfText.title}>
    <div className={styles.info}>
      {$emailConfText.info1}
      <span className={styles.email}>
        {' '}
        {email}
        {' '}
      </span>
      {$emailConfText.info2}
    </div>
    <div className={styles.next_button}>
      <GoToAppButton />
    </div>
    <hr className={styles.line} />
    <div className={styles.resend_text}>
      {$emailConfText.resendText}
      {' '}
      <span
        className={styles.resend}
        role="presentation"
        onClick={() => console.log('push')}
      >
        {$emailConfText.resend}
      </span>
    </div>
  </IconContainer>
);

export default EmailConfirmForm;
