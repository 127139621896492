import React from 'react';
import styles from '@features/library-page/components/media-content/styles/MobileCommandsForm.module.scss';
import { StyledCheckBox } from '@components/index';
import { $libraryPageText } from '@data/text.constants';
import CommandsForm from '@features/library-page/components/media-content/CommandsForm';
import { Command } from '@features/library-page/buttons/CommandButton';

type Props = {
  isAnySelected: boolean;
  toggleCheckbox: () => void;
  isAllSelected: boolean;
  commands: Command[];
  isTransferButtonVisible?: boolean;
  selectedItemsCount?: number;
};

const MobileCommandsForm = ({
  isAnySelected, toggleCheckbox,
  isAllSelected, commands, selectedItemsCount, isTransferButtonVisible,
}: Props) => (
  <div className={styles.actions_container}>
    <StyledCheckBox onChange={toggleCheckbox} isChecked={isAllSelected} />
    {!isAnySelected
      ? (
        <div className={styles.text}>
          {$libraryPageText.selectItems}
        </div>
      )
      : (
        <>
          <div className={styles.selected_count} style={{ display: selectedItemsCount ? 'block' : 'none' }}>
            {selectedItemsCount}
          </div>
          <CommandsForm
            commands={commands}
            isAnySelected={isAnySelected}
            isTransferButtonVisible={isTransferButtonVisible}
          />
        </>
      )}
  </div>
);

export default MobileCommandsForm;
