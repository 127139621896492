import React from 'react';
import { $transferSuccessfulModalText } from '@data/text.constants';
import icon from '@assets/transferred-successfully.svg';
import TransferEndedModal
  from '@features/modal-windows/components/modals/transfer-ended-modals/base/TransferEndedModal';

const TransferSuccessfulModal = () => (
  <TransferEndedModal modalIcon={icon} modalText={$transferSuccessfulModalText.transferredSuccessfully} buttonText="OK" />
);

export default TransferSuccessfulModal;
