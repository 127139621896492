import React from 'react';
import styles
  from '@features/transfer-page/active-transfers/responsive/styles/DesktopActiveTransfersPage.module.scss';
import { ActionsHeader, MediaDataGrid } from '@features/library-page';
import { $activeTransferText } from '@data/text.constants';
import { activeTransfersActions } from '@store/slices/active-transfers/activeTransfersSlice';
import { Column } from 'react-table';
import $columnIds from '@data/columns.constants';
import TransferProgressBar
  from '@features/transfer-page/active-transfers/components/TransferProgressBar';
import { Command } from '@features/library-page/buttons/CommandButton';
import { useAppDispatch } from '@store/hooks';
import { ActiveTransferState } from '@store/slices/active-transfers/activeTransfers.types';
import { useGlobalModalContext } from '../../../../contexts';

const TitleCell = ({ row }: any) => {
  const { showModal } = useGlobalModalContext();
  const showTransferProcessModal = () => {
    showModal({ type: 'transfer_process', props: { activeTransferId: row.original.activeTransferId } });
  };

  return (
    <div
      className={styles.title_container}
      role="presentation"
      onClick={showTransferProcessModal}
    >
      {row.original.title}
    </div>
  );
};

const columns = Array<Column>(
  {
    Header: 'Title',
    accessor: $columnIds.title,
    Cell: ({ row }: any) => <TitleCell row={row} />,
  },
  {
    Header: 'Status',
    accessor: $columnIds.status,
    Cell: ({ row }: any) => (
      <div className={styles.status_wrapper}>
        <div className={styles.status_container}>
          {(!row.original.completedProgress) ? row.original.status : `${row.original.completedProgress.toFixed()}%`}
        </div>
        <div style={{ display: !row.original.completedProgress || row.original.completedProgress === 100 || row.original.completedProgress === 0 ? 'none' : 'block' }}>
          <TransferProgressBar
            completedPercent={row.original.completedProgress}
          />
        </div>
      </div>
    ),
  },
  {
    Header: 'Type',
    accessor: $columnIds.type,
    width: 70,
    Cell: ({ row }: any) => (
      <div className={styles.media_item_type}>
        {row.original.type}
      </div>
    ),
  },
  {
    Header: 'Source',
    accessor: $columnIds.source,
    Cell: ({ row }: any) => (
      <div className={styles.container}>
        <div className={styles.service_image_container}>
          <img
            className={styles.service_image}
            src={row.original.sourceInfo.serviceLogo}
            alt="serviceImg"
          />
        </div>
      </div>
    ),
  },
  {
    Header: 'Destination',
    accessor: $columnIds.destination,
    Cell: ({ row }: any) => (
      <div className={styles.container}>
        <div className={styles.service_image_container}>
          <img
            className={styles.service_image}
            src={row.original.destinationInfo.serviceLogo}
            alt="serviceImg"
          />
        </div>
      </div>
    ),
  },
);

type Props = {
  commands: Command[];
  isAnySelected: boolean;
  isAllSelected: boolean;
  activeTransfers: ActiveTransferState[];
};

const DesktopActiveTransfersPage = ({
  commands, isAnySelected, isAllSelected, activeTransfers,
}: Props) => {
  const dispatch = useAppDispatch();
  const rowCheckbox = {
    isVisible: true,
    onCheck: (item: ActiveTransferState) => dispatch(activeTransfersActions.selectTransfer(item)),
  };
  const areAnyActiveTransfers = activeTransfers.length !== 0;
  return (
    <div className={styles.wrapper}>
      {areAnyActiveTransfers && (
      <div className={styles.header}>
        <ActionsHeader
          searchHint={$activeTransferText.searchTransfers}
          commands={commands}
          isTransferButtonVisible={false}
          toggleCheckbox={() => dispatch(activeTransfersActions.selectAllTransfers())}
          isChecked={isAllSelected}
          isAnySelected={isAnySelected}
          isSearchVisible={false}
        />
      </div>
      )}
      <div className={styles.active_wrapper}>
        <MediaDataGrid
          data={activeTransfers}
          tableColumns={columns}
          rowCheckbox={rowCheckbox}
        />
      </div>
    </div>
  );
};

export default DesktopActiveTransfersPage;
