import React, { useEffect, useState } from 'react';
import { Command } from '@features/library-page/buttons/CommandButton';
import { useAppDispatch } from '@store/hooks';
import { libraryActions } from '@store/slices/library/librarySlice';
import { libraryItemActions } from '@store/slices/library/library-item/libraryItemSlice';
import styles from './styles/ActionsHeader.module.scss';
import SearchField from '../../../components/SearchField';
import CommandsForm from './media-content/CommandsForm';
import { StyledCheckBox } from '../../../components';
import useSelectedItemsCount from '../hooks/useSelectedItemsCount';

type Props = {
  commands: Command[];
  isTransferButtonVisible?: boolean;
  searchHint: string;
  isLibraryItem?: boolean;
  toggleCheckbox: () => void;
  isChecked: boolean;
  isAnySelected: boolean;
  isSearchVisible?: boolean;
};

const ActionsHeader = ({
  commands, isTransferButtonVisible, searchHint,
  isLibraryItem, toggleCheckbox, isChecked, isAnySelected,
  isSearchVisible,
}: Props) => {
  const dispatch = useAppDispatch();
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if (isLibraryItem) {
      dispatch(libraryItemActions.searchTrack({ searchQuery }));
    } else {
      dispatch(libraryActions.searchItem({ searchQuery }));
    }
  }, [searchQuery]);

  const selectedItemsCount = useSelectedItemsCount(isLibraryItem!);

  return (
    <div className={styles.container}>
      <StyledCheckBox onChange={toggleCheckbox} isChecked={isChecked} />
      <div className={styles.selected_count} hidden={selectedItemsCount === 0}>
        {selectedItemsCount}
      </div>
      <div className={styles.actions_container}>
        <CommandsForm
          commands={commands}
          isTransferButtonVisible={isTransferButtonVisible}
          isAnySelected={isAnySelected}
        />
        <div className={styles.search_field}>
          <SearchField
            placeholder={searchHint}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            isVisible={isSearchVisible}
          />
        </div>
      </div>
    </div>
  );
};

export default ActionsHeader;
