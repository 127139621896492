import React from 'react';
import ReactModal from 'react-modal';
import styles from './styles/SidebarModal.module.scss';
import { SideBarForm } from '../../index';

type Props = {
  isOpen: boolean;
  isClosing: boolean;
  closeModal: () => void;
};

const SidebarModal = ({
  isOpen, isClosing, closeModal,
}: Props) => (
  <ReactModal
    isOpen={isOpen}
    onRequestClose={closeModal}
    overlayClassName={`${styles.overlay} ${isClosing ? styles.overlay_fade_out : ''}`}
    className={`${styles.modal} ${isClosing ? styles.modal_fade_out : ''}`}
  >
    <div className={styles.sidebar_wrapper}>
      <SideBarForm />
    </div>
  </ReactModal>
);

export default SidebarModal;
