import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type RegistrationState = {
  email: string | undefined;
  showBanner: boolean;
};

const initialState: RegistrationState = {
  email: undefined,
  showBanner: false,
};

const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    register(state, action: PayloadAction<{ email: string }>) {
      state.email = action.payload.email;
      state.showBanner = true;
    },
    closeBanner(state) {
      state.showBanner = false;
    },
  },
});

export const registrationActions = registrationSlice.actions;
export default registrationSlice.reducer;
