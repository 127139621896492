import React, {
  ChangeEventHandler, HTMLInputTypeAttribute, useState,
} from 'react';
import eyeHide from '@features/authorization/assets/eye-hide.svg';
import eyeShow from '@features/authorization/assets/eye-show.svg';
import styles from './styles/StyledInput.module.scss';
import { Validation } from '../hooks';

type Props = {
  title: string;
  value?: string;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  validation?: Validation;
};

const StyledInput = ({
  title, type, value, onChange, placeholder, validation,
}: Props) => {
  const validStyle = validation && !validation.isValid ? styles.inp_invalid : styles.inp_valid;
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const isTypePassword = type === 'password';
  const changePasswordVisibility = () => (isPasswordVisible ? 'text' : 'password');
  return (
    <>
      <div className={styles.title}>{title}</div>
      <div className={styles.inp_container}>
        <input
          type={isTypePassword ? changePasswordVisibility() : type}
          onChange={onChange}
          value={value}
          className={`${styles.inp} ${validStyle}`}
          placeholder={placeholder}
        />
        {isTypePassword && (
        <button
          className={styles.eye_button}
          id="password_btn"
          type="button"
          onClick={() => setIsPasswordVisible(!isPasswordVisible)}
        >
          <img
            className={styles.eye_icon}
            src={isPasswordVisible ? eyeHide : eyeShow}
            alt="hide"
            id="eye_icon"
          />
        </button>
        )}
      </div>
      {
        validation
        && <div className={styles.invalid_text}>{validation?.validationText}</div>
      }
    </>
  );
};
export default StyledInput;
