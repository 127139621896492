import { ForgotPasswordForm, MusConvHeaderAuth } from '@features/authorization';
import React from 'react';
import styles from './styles/Header.module.scss';

const ForgotPassword = () => (
  <>
    <div className={styles.header}>
      <MusConvHeaderAuth height="52" width="170" />
    </div>
    <ForgotPasswordForm />
  </>
);

export default ForgotPassword;
