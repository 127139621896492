import React from 'react';
import { Tooltip } from 'react-tooltip';
import styles from './styles/LibraryItemText.module.scss';

type LibraryItemTextProps = {
  id: string,
  text: string,
};

const LibraryItemText = ({ id, text }: LibraryItemTextProps) => (
  <>
    <span
      data-tooltip-id={id}
      data-tooltip-content={text}
    >
      {text}
    </span>
    <Tooltip id={id} className={styles.tooltip} noArrow />
  </>
);

export default LibraryItemText;
