const $registerText = {
  signUpWithGoogle: 'Sign up with Google',
  signUpToTransfer: 'Sign up to transfer your playlists and more for free.',
  whatsYourEmail: 'What\'s your email?',
  createPassword: 'Create a password',
  chooseWhoYouAre: 'Choose who you are',
  enterYourEmail: 'Enter your email',
  dJ: 'DJ',
  musician: 'Musician',
  musicLabel: 'Music Label',
  other: 'Other',
  signUp: 'SIGN UP',
  haveAnAccount: 'Have an account?',
  logIn: 'Log in',
};

const $loginText = {
  or: 'or',
  logInWithGoogle: 'Login with Google',
  toContinueLogIn: 'To continue, log in to MusConv.',
  emailAddress: 'Email address',
  password: 'Password',
  enterYourEmail: 'Enter your email',
  forgotPassword: 'Forgot password?',
  logIn: 'LOG IN',
  dontHaveAnAccount: 'Don\'t have an account?',
  signUpForMusConv: 'Sign up for MusConv',
  signingUpForMusConv: 'Signing up for MusConv',
};

const $mainPageText = {
  connectService: 'Connect service',
  home: 'Home',
  transfers: 'Transfers',
  recent: 'Recent',
  autosync: 'Autosync',
  settings: 'Settings',
  profile: 'Profile',
  premium: 'Upgrade to Premium',
  video: 'Video',
  support: 'Technical Support',
  logOut: 'Log out',
  main: 'MusConv',
  download: 'Download Desktop / Mobile App',
};

const $homePageText = {
  loggedServices: 'Select your source music platform:',
  allServices: 'All services',
  explore: 'Explore',
  recentTransfers: 'Recent transfers',
  seeAll: 'See all',
  searchService: 'Search service',
};

const $libraryPageText = {
  search: 'Search',
  playlist: 'Playlist',
  album: 'Album',
  artist: 'Artist',
  track: 'Track',
  selectItems: 'Select items and open tools',
  loading: 'Loading',
};

const $notFountPageText = {
  error: 'ERROR',
  pageNotFound: 'Page not found...',
  goMainPage: 'Go to main page',
};

const $transferSuccessfulModalText = {
  transferredSuccessfully: 'TRANSFERRED SUCCESSFULLY',
  showReport: 'Show Report',
};

const $chooseDestModalText = {
  transfer: 'Select destination music service',
  selectServiceYouWantTo: 'Select destination music service',
  sourceService: 'Source service:',
  destinationService: 'Destination service:',
  selectDestinationService: 'Select destination service:',
  searchPlaceholder: 'Search services',
};

const $confirmTransferModalText = {
  back: 'PREVIOUS',
  selectDestService: 'Select the service you want to transfer to',
  almostDone: 'ALMOST DONE',
  checkServices: 'Check if you have chosen the right services and',
  playlistsForTransfer: 'playlists for the transfer',
  transfer: 'Transfer',
};

const $emailConfText = {
  title: 'Email confirmation',
  info1: 'An email has been sent to',
  info2:
    'It contains an activation link you must click to activate your account.',
  resendText: 'If you not got any mail',
  resend: 'resend confirmation email',
};

const $trialVersionModalText = {
  thankYouForUsing: 'Thank you for using trial',
  versionOfMusConv: 'version of MusConv!',
  haveTransferredText: 'Have successfully transferred:',
  playlistType: 'Playlists',
  songType: 'Songs',
  trialVersionAllows: 'Trial version allows to copy only 25 songs.',
  youHaveReached: 'You have reached the limit for the trial',
  versionOfSoftware: 'version of our software!',
};

const $transferProcessModalText = {
  transfer: 'TRANSFER',
  cancel: 'Cancel',
  transferSpeed: 'Transfer speed depends on the number of selected',
  internedSpeed: 'files, your internet speed',
  search: 'Search...',
};

const $activeTransferText = {
  searchTransfers: 'Search transfers',
  preparingItemsStatus: 'Preparing transfer items...',
  searchingItemsStatus: 'Searching: ',
  transferringItemsStatus: 'Transferring...',
  transferCompletedStatus: 'Transfer successfully completed',
  trialCompletedStatus: 'Trial period expired',
  transferErrorStatus: 'Transfer not completed because of error',
  transferEmptyStatus: 'Nothing to transfer',
};

const $activeTransfersPageText = {
  noTransfersToShow: 'There are no transfers to show',
  connectAndTransfer: 'Connect music services and create transfer!',
  getStarted: 'Get started',
};

const $recentTransfersPageText = {
  recentTransfers: 'Recent transfers',
  noRecentTransfers: 'There are no recent transfers to show',
  createTransfer: 'Connect music services and create transfer',
  getStarted: 'Get started',
};

const $forgottenPasswordText = {
  title: 'Forgotten  your password?',
  enterEmail: 'Enter your email',
  resetPassword: 'RESET MY PASSWORD',
  lowerMessage: 'We will send a confirmation message to mail to reset the password. If you really want to reset your password, click on the button in the message.',
};

const $resetPasswordText = {
  title: 'Password reset',
  password: 'Password',
  confirmPassword: 'Confirm Password',
  resetPassword: 'RESET MY PASSWORD',
};

const $passwordResetSuccessText = {
  title: 'Password reset',
  info: 'An email has been sent. It contains a link you must click to reset your password.',
  note: 'Note: You can only request a new password once within 2 hours. If you don\'t get an email check your spam folder or try again',
  backToHome: 'BACK TO HOME',

};

const $confirmPasswordValidation = {
  validationText: 'The entered passwords don\'t match',
};

const $registerSuccessText = {
  title: 'Registration successfully',
  info1: 'Your email has been confirmed. Enjoy using the MusConv web app! Create your first transfer!',
  goToApp: 'Go to app!',
};

const $serviceLoginFormText = {
  title: (serviceTitle: string | undefined) => `MusConv needs access to your ${serviceTitle} account.`,
  signIn: 'SIGN IN',
};

const $loginErrorText = {
  invalidEmail: 'Invalid email.' as const,
  invalidPassword: 'Invalid password.' as const,
  accNotActivated: 'Account not activated. Please, check your email.' as const,
  unknown: 'Unknown error.' as const,
};

const $activationAccountErrorText = {
  notFound: 'Account not found in the database.' as const,
  notMatch: 'Secret doesn\'t match.' as const,
  unknownType: 'Unknown type error.' as const,
  unknown: 'Unknown error.' as const,
};

const $forgotPasswordErrorText = {
  notFound: 'Account not found in the database.' as const,
  unknownType: 'Unknown type error.' as const,
  unknown: 'Unknown error.' as const,
};

const $resetPasswordErrorText = {
  accountNotFound: 'Account not found in the database.' as const,
  tokenNotFound: 'Reset password token not found.' as const,
  tokenNotMatch: 'Reset password token doesn\'t match.' as const,
  unknownType: 'Unknown type error.' as const,
  unknown: 'Unknown error.' as const,
};

const $callbackLoaderText = {
  authProgressing: 'Authorization in process' as const,
  plsWait: 'Please wait...',
};

const $thankYouModalText = {
  thankYou: 'Thank you for using of' as const,
  musConv: 'MusConv!' as const,
  subscribeMobInfo1: 'Subscribe our newsletter and get' as const,
  subscribeMobInfo2: 'notifications to stay updated' as const,
  subscribeNewsletter: 'Subscribe our newsletter and get notifications to stay updated' as const,
  enterEmail: 'Enter your email' as const,
  subscribe: 'Subscribe' as const,
  rateQuality: 'Rate the quality of MusConv!' as const,
};

const $allServicesModalText = {
  connectService: 'Select source music service' as const,
  selectService: 'Select the service you want to authorize' as const,
  availableServices: 'Available services:' as const,
  searchPlaceholder: 'Search service' as const,
  connected: 'Connected' as const,
};

const $showReportModal = {
  found: 'Found' as const,
  notFound: 'Not found' as const,
};

const $transferCanceledModalText = {
  transferCanceled: 'Transfer canceled',
};

const $activeTransferModalText = {
  activeTransfer: 'Active transfer' as const,
};

const $confirmActionModalText = {
  cancel: 'CANCEL' as const,
  confirm: 'CONFIRM' as const,
};

const $emptyServicesBlankText = {
  title: 'No services',
  description: 'Get started by connecting your source music service on left side bar.',
};

const $emptyLibraryBlankText = {
  title: 'Library is empty',
  description: 'Add music to the library for display data.',
};

const $libraryItemText = {
  playlist: 'playlist' as const,
  album: 'album' as const,
  artist: 'artist' as const,
  searchTracks: 'Search tracks' as const,
  tracks: 'Tracks' as const,
  followers: 'Followers' as const,
  loading: 'Loading tracks ...' as const,
  details: 'Details' as const,
};

const $transferStartedModalText = {
  transferStarted: 'Transfer started',
  transferStartedDescription: 'You can view the transfer by clicking on the show activity button, or open it in the status bar.',
  showActives: 'Show actives',
};

const $emptyPageText = {
  title: 'No playlists to display',
  description: 'Link your first music service to load your playlists here',
  buttonText: 'Let\'s go',
};

const $featureUnavailableText = {
  subscriptionError: 'Thank you for using MusConv!',
  featureForPaid: 'Please upgrade to the paid version to access YouTube or YouTube Music',
};

const $availableServicesText = {
  thanksForUsingMusConv: 'Thank you for using MusConv!',
  availableServices: 'Web Version of MusConv allows to transfer playlists between Spotify, Apple Music, Deezer, YouTube, YouTube Music and Napster.',
  suggest: 'If your music service is missed, please try our desktop or mobile version:',
  link: 'https://musconv.com/download/',
};

export {
  $registerText, $loginText, $mainPageText,
  $notFountPageText, $transferSuccessfulModalText, $chooseDestModalText,
  $confirmTransferModalText, $emailConfText, $libraryPageText,
  $transferProcessModalText, $forgottenPasswordText, $resetPasswordText, $confirmPasswordValidation,
  $passwordResetSuccessText, $registerSuccessText, $homePageText, $trialVersionModalText,
  $serviceLoginFormText, $activeTransferText,
  $activationAccountErrorText, $forgotPasswordErrorText, $resetPasswordErrorText,
  $callbackLoaderText, $thankYouModalText, $allServicesModalText, $showReportModal,
  $activeTransferModalText, $loginErrorText, $libraryItemText,
  $emptyServicesBlankText, $emptyLibraryBlankText, $transferStartedModalText,
  $activeTransfersPageText, $transferCanceledModalText,
  $confirmActionModalText, $emptyPageText, $recentTransfersPageText,
  $featureUnavailableText, $availableServicesText,
};
