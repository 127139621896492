import { useState } from 'react';
import { getAccountService } from 'src/inversify.config';
import { $forgotPasswordErrorText as text } from '@data/text.constants';
import $responseTypes from '@data/response.types.constants';

const parseError = (type: string | undefined): string => {
  if (type === undefined) {
    return text.unknown;
  }

  const { accountNotFound } = $responseTypes.account.forgot;
  switch (type) {
    case accountNotFound:
      return text.notFound;
    default:
      return text.unknownType;
  }
};

type Status = 'init' | 'loading' | 'success' | 'error';
const useForgotPassword = () => {
  const [status, setStatus] = useState<Status>('init');
  const [error, setError] = useState<string>('');
  const send = async (email: string) => {
    setStatus('loading');
    const accountService = getAccountService();
    const resp = await accountService.forgotPassword(email);

    if (resp.status === 'ok') {
      setStatus('success');
      return;
    }

    setStatus('error');
    setError(parseError(resp.type));
  };

  return {
    status,
    error,
    send,
  };
};

export default useForgotPassword;
