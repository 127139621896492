const $route = {
  main: '/',
  home: '/home',
  login: '/login',
  register: '/signup',
  autosync: '/autosync',
  emailConfirm: '/email',
  library: '/library/:service',
  libraryItem: '/library/:service/:type/:id',
  transferHistory: '/history',
  activeTransfers: '/activeTransfers',
  recentTransfers: '/recentTransfers',
  forgotPassword: '/forgotPassword',
  resetPassword: '/resetPassword/:email/:secret',
  resetPasswordSuccess: '/resetPasswordSuccess',
  registrationSuccessfully: '/confirmed/:secret',
  callback: '/callback/:service',
  serviceLogin: '/service/auth/:service',
  admin: '/admin',
  notFound: '*',
};

export default $route;
