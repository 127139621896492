import { inject, injectable } from 'inversify';
import { type IHttpClient } from '@lib/index';
import { CredentialsModel, ResponseError } from '@services/types/webapi.types';
import $api from '@data/api.constants';
import $serviceType from '@data/service.types.constant';
import {
  AlbumExtended,
  AlbumSimplified,
  Artist,
  MusicItemMinimal,
  PlaylistExtended,
  PlaylistMinimal,
  PlaylistSimplified,
  Track,
} from '@services/types/music.items.types';
import { IApiResponse } from '@lib/http/HttpClient.types';
import { ILibraryService } from './LibraryService.interface';
import {
  ArtistsResponse,
  TracksResponse,
  ArtistsModel,
  LibraryRequest,
  TracksModel,
  ItemsModel,
  ItemsRequest,
  SimplifiedAlbumsModel,
  SimplifiedAlbumsResponse,
  SimplifiedPlaylistsModel,
  SimplifiedPlaylistsResponse,
  ExtendedAlbumsModel,
  ExtendedAlbumsResponse,
  ExtendedPlaylistsResponse,
  ExtendedPlaylistsModel,
  LibraryResponse,
  ActionResultModel,
  ActionResponse,
} from './LibraryService.types';

@injectable()
export default class LibraryService implements ILibraryService {
  @inject($serviceType.http) private http: IHttpClient;

  private getItemsModel<ItemType, ResponseType>(
    response: IApiResponse<ResponseType>,
  ) : ItemsModel<ItemType> {
    if (response.status === 200) {
      const data = response.data as LibraryResponse<ItemType>;
      return {
        type: 'ok',
        items: data.items,
      };
    }

    const data = response.data as ResponseError;
    return {
      type: 'error',
      title: data.title,
      message: data.detail,
    };
  }

  private getActionResultModel(
    response: IApiResponse<ActionResponse>,
  ) : ActionResultModel {
    if (response.status === 200) {
      return {
        type: 'ok',
      };
    }

    const data = response.data as ResponseError;
    return {
      type: 'error',
      title: data.title,
      message: data.detail,
    };
  }

  /* GetAll */

  private async getAllItems<ItemType, ResponseType>(
    endpoint: string,
    credentials: CredentialsModel,
  ): Promise<ItemsModel<ItemType>> {
    const requestData = { credentials } as LibraryRequest;

    const response = await this.http.post<ResponseType>(
      endpoint,
      requestData,
    );

    return this.getItemsModel<ItemType, ResponseType>(response);
  }

  getAllAlbums(
    credentials: CredentialsModel,
  ): Promise<SimplifiedAlbumsModel> {
    return this.getAllItems<AlbumSimplified, SimplifiedAlbumsResponse>(
      $api.library.allAlbums,
      credentials,
    );
  }

  getAllArtists(
    credentials: CredentialsModel,
  ): Promise<ArtistsModel> {
    return this.getAllItems<Artist, ArtistsResponse>(
      $api.library.allArtists,
      credentials,
    );
  }

  getAllPlaylists(
    credentials: CredentialsModel,
  ): Promise<SimplifiedPlaylistsModel> {
    return this.getAllItems<PlaylistSimplified, SimplifiedPlaylistsResponse>(
      $api.library.allPlaylists,
      credentials,
    );
  }

  getAllTracks(
    credentials: CredentialsModel,
  ): Promise<TracksModel> {
    return this.getAllItems<Track, TracksResponse>(
      $api.library.allTracks,
      credentials,
    );
  }

  /* GetById */

  private async getItemsById<ItemType, ResponseType>(
    endpoint: string,
    credentials: CredentialsModel,
    items: Array<MusicItemMinimal>,
  ): Promise<ItemsModel<ItemType>> {
    const requestData = {
      credentials,
      items,
    } as ItemsRequest<MusicItemMinimal>;

    const response = await this.http.post<ResponseType>(
      endpoint,
      requestData,
    );

    return this.getItemsModel<ItemType, ResponseType>(response);
  }

  getAlbums(
    credentials: CredentialsModel,
    items: Array<MusicItemMinimal>,
  ): Promise<ExtendedAlbumsModel> {
    return this.getItemsById<AlbumExtended, ExtendedAlbumsResponse>(
      $api.library.albums,
      credentials,
      items,
    );
  }

  getArtists(
    credentials: CredentialsModel,
    items: Array<MusicItemMinimal>,
  ): Promise<ArtistsModel> {
    return this.getItemsById<Artist, ArtistsResponse>(
      $api.library.artists,
      credentials,
      items,
    );
  }

  getPlaylists(
    credentials: CredentialsModel,
    items: Array<MusicItemMinimal>,
  ): Promise<ExtendedPlaylistsModel> {
    return this.getItemsById<PlaylistExtended, ExtendedPlaylistsResponse>(
      $api.library.playlists,
      credentials,
      items,
    );
  }

  getTracks(
    credentials: CredentialsModel,
    items: Array<MusicItemMinimal>,
  ): Promise<TracksModel> {
    return this.getItemsById<Track, TracksResponse>(
      $api.library.tracks,
      credentials,
      items,
    );
  }

  /* Save */

  private async saveItems<Type>(
    endpoint: string,
    credentials: CredentialsModel,
    items: Array<MusicItemMinimal>,
  ): Promise<ActionResultModel> {
    const requestData = {
      credentials,
      items,
    } as ItemsRequest<Type>;

    const response = await this.http.put<ActionResponse>(
      endpoint,
      requestData,
    );

    return this.getActionResultModel(response);
  }

  saveAlbums(
    credentials: CredentialsModel,
    items: Array<MusicItemMinimal>,
  ): Promise<ActionResultModel> {
    return this.saveItems<MusicItemMinimal>(
      $api.library.albums,
      credentials,
      items,
    );
  }

  saveArtists(
    credentials: CredentialsModel,
    items: Array<MusicItemMinimal>,
  ): Promise<ActionResultModel> {
    return this.saveItems<MusicItemMinimal>(
      $api.library.artists,
      credentials,
      items,
    );
  }

  savePlaylists(
    credentials: CredentialsModel,
    items: Array<PlaylistMinimal>,
  ): Promise<ActionResultModel> {
    return this.saveItems<PlaylistMinimal>(
      $api.library.playlists,
      credentials,
      items,
    );
  }

  saveTracks(
    credentials: CredentialsModel,
    items: Array<MusicItemMinimal>,
  ): Promise<ActionResultModel> {
    return this.saveItems<MusicItemMinimal>(
      $api.library.tracks,
      credentials,
      items,
    );
  }

  /* Remove */

  private async removeItems(
    endpoint: string,
    credentials: CredentialsModel,
    items: Array<MusicItemMinimal>,
  ): Promise<ActionResultModel> {
    const requestData = {
      credentials,
      items,
    } as ItemsRequest<MusicItemMinimal>;

    const response = await this.http.delete<ActionResponse>(
      endpoint,
      requestData,
    );

    return this.getActionResultModel(response);
  }

  removeAlbums(
    credentials: CredentialsModel,
    items: Array<MusicItemMinimal>,
  ): Promise<ActionResultModel> {
    return this.removeItems(
      $api.library.albums,
      credentials,
      items,
    );
  }

  removeArtists(
    credentials: CredentialsModel,
    items: Array<MusicItemMinimal>,
  ): Promise<ActionResultModel> {
    return this.removeItems(
      $api.library.artists,
      credentials,
      items,
    );
  }

  removePlaylists(
    credentials: CredentialsModel,
    items: Array<MusicItemMinimal>,
  ): Promise<ActionResultModel> {
    return this.removeItems(
      $api.library.playlists,
      credentials,
      items,
    );
  }

  removeTracks(
    credentials: CredentialsModel,
    items: Array<MusicItemMinimal>,
  ): Promise<ActionResultModel> {
    return this.removeItems(
      $api.library.tracks,
      credentials,
      items,
    );
  }
}
