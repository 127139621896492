import React from 'react';
import TransferButton from '@features/library-page/buttons/TransferButton';
import styles from './styles/CommandsForm.module.scss';
import CommandButton, { Command } from '../../buttons/CommandButton';

type Props = {
  commands: Command[];
  isTransferButtonVisible?: boolean;
  isAnySelected: boolean;
};

const CommandsForm = ({ commands, isTransferButtonVisible = true, isAnySelected }: Props) => (
  <div className={styles.container}>
    { isTransferButtonVisible && <TransferButton isAnySelected={isAnySelected} />}
    { commands.map((command) => (
      <CommandButton
        key={command.icon}
        name={command.name}
        icon={command.icon}
        onClick={command.onClick}
      />
    ))}
  </div>
);

export default CommandsForm;
