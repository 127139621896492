const $serviceType = {
  admin: Symbol.for('AdminService'),
  auth: Symbol.for('AuthService'),
  serviceAuth: Symbol.for('MusicServiceAuthService'),
  serviceUser: Symbol.for('MusicServiceUserService'),
  account: Symbol.for('AccountService'),
  library: Symbol.for('LibraryService'),
  search: Symbol.for('SearchService'),
  transfer: Symbol.for('TransferService'),
  transferProgress: Symbol.for('TransferProgressService'),
  settings: Symbol.for('SettingsService'),
  keys: Symbol.for('KeysService'),
  http: Symbol.for('HttpClient'),
  signalr: Symbol.for('SignalRClient'),
};

export default $serviceType;
