import useModal from '@features/modal-windows/hooks/useModal';
import Modal from '@features/modal-windows/components/Modal';
import ChooseDestinationModal from '@features/modal-windows/components/modals/choose-destination-modal/ChooseDestinationModal';
import MessageBoxModal from '@features/modal-windows/components/modals/message-box-modal/MessageBoxModal';
import TransferSuccessfulModal from '@features/modal-windows/components/modals/transfer-ended-modals/TransferSuccessfulModal';
import TransferCanceledModal from '@features/modal-windows/components/modals/transfer-ended-modals/TransferCanceledModal';
import TrialVersionModal from '@features/modal-windows/components/modals/trial-version-modal/TrialVersionModal';
import ConfirmTransferModal from '@features/modal-windows/components/modals/ConfirmTransferModal';
import TransferProcessModal from '@features/modal-windows/components/modals/transfer-process-modal/TransferProcessModal';
import ThankYouModal from '@features/modal-windows/components/modals/thank-you-modal/ThankYouModal';
import AllServicesModal from '@features/modal-windows/components/modals/all-services-modal/AllServicesModal';
import ShowReportModal from '@features/modal-windows/components/modals/show-report-modal/ShowReportModal';
import ActiveTransferModal from '@features/modal-windows/components/modals/active-transfer-modal/ActiveTransferModal';
import ConfirmActionModal from '@features/modal-windows/components/modals/confirm-action-modal/ConfirmActionModal';
import ConfirmEmailModal from '@features/modal-windows/components/modals/confirm-email-modal/ConfirmEmailModal';

export default Modal;
export {
  useModal,
  MessageBoxModal,
  ChooseDestinationModal,
  TransferSuccessfulModal,
  TrialVersionModal,
  ConfirmTransferModal,
  TransferProcessModal,
  ThankYouModal,
  AllServicesModal,
  ShowReportModal,
  ActiveTransferModal,
  TransferCanceledModal,
  ConfirmActionModal,
  ConfirmEmailModal,
};
