import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AlbumLibraryModel, ArtistLibraryModel,
  LibraryModel,
  PlaylistLibraryModel, TrackLibraryModel,
} from '@store/slices/library/library.types';
import { RootState } from '@store/index';
import { LibraryTabType } from '@store/slices/types';
import { CredentialsModel } from '@services/types/webapi.types';
import { AxiosError } from 'axios';
import { getLibraryService } from '../../../inversify.config';
import { assertUnreachable } from '../../../utils';
import { tryGetSmallImage } from './library.helpers';

const mapTab = async (
  type: LibraryTabType,
  creds: CredentialsModel,
): Promise<LibraryModel[]> => {
  const libraryService = getLibraryService();
  switch (type) {
    case 'playlists': {
      const playlistsResponse = await libraryService.getAllPlaylists(creds);
      if (playlistsResponse.type === 'error') {
        throw new AxiosError(`Error from server: ${playlistsResponse.message}`);
      }
      return playlistsResponse.items.map((playlist) => ({
        id: playlist.id,
        title: playlist.title,
        imageLink: tryGetSmallImage(playlist.imageLinks),
        tracksCount: playlist.tracksCount,
        isSelected: false,
        type: 'playlist',
      } as PlaylistLibraryModel));
    }
    case 'albums': {
      const albumsResponse = await libraryService.getAllAlbums(creds);
      if (albumsResponse.type === 'error') {
        throw Error(`Error from server: ${albumsResponse.message}.`);
      }
      return albumsResponse.items.map((album) => ({
        id: album.id,
        title: album.title,
        imageLink: tryGetSmallImage(album.imageLinks),
        tracksCount: album.tracksCount,
        artist: album.artist,
        isSelected: false,
        type: 'album',
      } as AlbumLibraryModel));
    }
    case 'tracks': {
      const tracksResponse = await libraryService.getAllTracks(creds);
      if (tracksResponse.type === 'error') {
        throw Error(`Error from server: ${tracksResponse.message}.`);
      }
      return tracksResponse.items.map((track) => ({
        id: track.id,
        title: track.title,
        imageLink: tryGetSmallImage(track.imageLinks),
        artist: track.artist,
        albumName: track.album,
        duration: track.duration,
        isSelected: false,
        type: 'track',
      } as TrackLibraryModel));
    }
    case 'artists': {
      const artistsResponse = await libraryService.getAllArtists(creds);
      if (artistsResponse.type === 'error') {
        throw Error(`Error from server: ${artistsResponse.message}.`);
      }
      return artistsResponse.items.map((artist) => ({
        id: artist.id,
        title: artist.title,
        imageLink: tryGetSmallImage(artist.imageLinks),
        isSelected: false,
        type: 'artist',
      } as ArtistLibraryModel));
    }
    default:
      assertUnreachable(type);
      return [];
  }
};

type FetchLibraryConfig = {
  rejectValue: string;
  state: RootState;
};

type ReturnType = {
  libraryItems: LibraryModel[],
  selectedTab: LibraryTabType,
};

const selectTabAsync = createAsyncThunk<
ReturnType,
LibraryTabType,
FetchLibraryConfig
>(
  'library/selectTabAsync',
  async (arg, thunkAPI) => {
    const state: RootState = thunkAPI.getState();
    const { selectedService } = state.library;
    if (!selectedService) {
      throw Error('Source service not selected');
    }
    const checkRequestId = () => {
      if (state.library.lastRequestId !== thunkAPI.requestId) {
        thunkAPI.abort();
      }
    };

    if (!selectedService || !selectedService.userId) {
      return thunkAPI.rejectWithValue('error from logged service');
    }

    const creds = {
      musicService: selectedService.type,
      serviceUserId: selectedService.userId,
    };

    checkRequestId();
    let result: LibraryModel[];
    try {
      result = await mapTab(arg, creds);
    } catch (e) {
      if (e instanceof AxiosError) {
        const errorMessage = e.response?.data?.detail || e.message;
        return thunkAPI.rejectWithValue(errorMessage);
      }
      return thunkAPI.rejectWithValue('Unknown error from server');
    }

    checkRequestId();
    return {
      selectedTab: arg,
      libraryItems: result,
    };
  },
);

export default selectTabAsync;
