import React, { useEffect } from 'react';
import { useAppSelector } from '@store/hooks';
import EmptyAnimatedBlank from '@components/blank/EmptyAnimatedBlank';
import { $emptyPageText } from '@data/text.constants';
import styles from './styles/HomePageDesktop.module.scss';
import { LoggedServices, RecentTransfersMinimal } from '../index';
import { useGlobalModalContext } from '../../../contexts';

const HomePageDesktop = () => {
  const { showModal } = useGlobalModalContext();
  const email = useAppSelector((state) => state.user.email);
  const showBanner = useAppSelector((state) => state.register.showBanner);
  const areLoggedServicesNotEmpty = useAppSelector(
    (state) => state.musicServices.loggedServices.length > 0,
  );
  useEffect(() => {
    if (showBanner) {
      showModal({
        type: 'confirm_email',
        props: { email },
      });
    }
  }, [email, showBanner]);
  return (
    areLoggedServicesNotEmpty
      ? (
        <>
          {areLoggedServicesNotEmpty && <LoggedServices />}
          <div className={styles.footer_container}>
            <RecentTransfersMinimal />
            {/* <Explore /> */}
          </div>
        </>
      )
      : (
        <EmptyAnimatedBlank
          title={$emptyPageText.title}
          description={$emptyPageText.description}
          buttonText={$emptyPageText.buttonText}
          onClick={() => showModal({ type: 'all_services' })}
        />
      )
  );
};

export default HomePageDesktop;
