import React from 'react';
import { $mainPageText } from '@data/text.constants';
import $urls from '@data/url.constants';
import styles from './styles/PremiumButton.module.scss';

const PremiumButton = () => (
  <div className={styles.container}>
    <div className={styles.container_border}>
      <a
        className={styles.button}
        href={$urls.premiumPage}
        type="button"
        target="_blank"
        rel="noreferrer"
      >
        <span className={styles.text}>
          {$mainPageText.premium}
        </span>
      </a>
    </div>
  </div>
);

export default PremiumButton;
