import React, { useState } from 'react';
import { $confirmTransferModalText } from '@data/text.constants';
import { StyledButton } from '@components/index';
import arrow from '@assets/arrow.svg';
import { useGlobalModalContext } from 'src/contexts';
import store from '@store/index';
import { useAppDispatch } from '@store/hooks';
import { startTransferAsync } from '@store/slices/begin-transfer/beginTransferThunks';
import { getLoggedServiceData } from '@store/slices/music-services/musicService.utils';
import useLibraryModelType from '@features/modal-windows/hooks/useLibraryModelType';
import MediaItemsList from '@components/MediaItemsList';
import arrowBack from '../../assets/arrow-back.svg';
import closeIcon from '../../assets/close-icon.svg';
import styles from './ConfirmTransferModal.module.scss';

const ConfirmTransferModal = () => {
  const { hideModal } = useGlobalModalContext();
  const { sourceService, destinationService } = store.getState().beginTransfer;
  if (!sourceService || !destinationService) {
    throw Error('Source or destination service not selected');
  }
  const mediaItems = useLibraryModelType();
  if (!mediaItems) {
    throw Error('No media items selected.');
  }
  const dispatch = useAppDispatch();
  const { showModal } = useGlobalModalContext();
  const showDestinationModal = () => showModal({ type: 'choose_destination' });
  const showTransferStartedModal = () => showModal({ type: 'transfer_started' });

  const showTrialLimitExhausted = () => showModal({
    type: 'trial_version',
    props: { transferredTracks: 25 },
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleTransferClick = async () => {
    setIsLoading(true);

    await dispatch(startTransferAsync()).then((action) => {
      if (action.payload === 'Trial limit exhausted') {
        showTrialLimitExhausted();
      } else {
        showTransferStartedModal();
      }
    });

    setIsLoading(false);
  };

  const sourceServiceData = getLoggedServiceData(sourceService);
  const destinationServiceData = getLoggedServiceData(destinationService);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <img
          className={styles.back_btn}
          alt="back"
          src={arrowBack}
          role="presentation"
          onClick={() => showDestinationModal()}
        />
        <div>
          <h1 className={styles.header_prev_txt}>{$confirmTransferModalText.back}</h1>
          <h1 className={styles.header_prev_name}>{$confirmTransferModalText.selectDestService}</h1>
        </div>
        <img
          className={styles.close_btn}
          alt="close"
          src={closeIcon}
          role="presentation"
          onClick={hideModal}
        />
      </div>
      <div className={styles.transfer_info}>
        <div className={styles.almost_done}>{$confirmTransferModalText.almostDone}</div>
        <div className={styles.check_txt}>
          {$confirmTransferModalText.checkServices}
          <br />
          {' '}
          {$confirmTransferModalText.playlistsForTransfer}
        </div>
        <div className={styles.transfer_services}>
          <img
            alt="source"
            src={sourceServiceData.icon}
          />
          <img className={styles.arrow} alt="arrow" src={arrow} />
          <img
            alt="dest"
            src={destinationServiceData.icon}
          />
        </div>
        <div className={styles.items}>
          <MediaItemsList items={mediaItems} />
        </div>
        <div className={styles.transfer_btn}>
          <StyledButton
            title={$confirmTransferModalText.transfer}
            onClick={handleTransferClick}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmTransferModal;
