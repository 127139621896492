import React from 'react';
import connectedIcon from '@features/modal-windows/assets/success-icon.svg';
import { $allServicesModalText as text } from '@data/text.constants';
import { MusicServiceData } from '@store/slices/music-services/musicService.types';
import { useAppDispatch } from '@store/hooks';
import { useServiceAuthorization } from '@features/service-authorization/hooks';
import { getLoggedService } from '@store/slices/music-services/musicService.utils';
import MarqueeText from '@components/MarqueeText';
import { beginTransferActions } from '@store/slices/begin-transfer/beginTransferSlice';
import styles from './SelectDestinationServiceButton.module.scss';
import { useGlobalModalContext } from '../../../../../../contexts';

type Props = {
  service: MusicServiceData;
  email?: string,
  isConnected: boolean;
};

/**
 * Button of destination service.
 *
 * @param service - destination service model.
 * @param onClick - to open next confirmation modal.
 */

const SelectDestinationServiceButton = ({
  service, isConnected, email,
}: Props) => {
  const dispatch = useAppDispatch();
  const { authorizeMusicService } = useServiceAuthorization();
  const { showModal } = useGlobalModalContext();

  const showConfirmModal = () => showModal({ type: 'confirm_transfer' });
  const moveToNextStep = () => {
    const destinationService = getLoggedService(service);
    if (!destinationService) {
      throw Error('Destination service not found.');
    }
    dispatch(beginTransferActions.selectDestinationService(destinationService));
    showConfirmModal();
  };

  const onClick = () => {
    if (isConnected) {
      moveToNextStep();
      return;
    }
    authorizeMusicService(service.type);
  };

  return (
    <div
      className={styles.button}
      role="presentation"
      onClick={onClick}
    >
      <img className={styles.button_img} src={service.logo} alt={service.logo.toString()} />
      {email && (
        <div className={styles.email_text}>
          <MarqueeText text={email} />
        </div>
      )}
      {isConnected && (
        <div className={styles.connected_container}>
          <img className={styles.connected_icon} src={connectedIcon} alt="icon" />
          <div className={styles.connected_text}>{text.connected}</div>
        </div>
      )}
    </div>
  );
};
export default SelectDestinationServiceButton;
