const $columnIds = {
  checkbox: 'checkbox' as const,
  image: 'image' as const,
  title: 'title' as const,
  duration: 'duration' as const,
  tracks: 'tracks' as const,
  album: 'album' as const,
  albums: 'albums' as const,
  artist: 'artist' as const,
  artists: 'artists' as const,
  playlists: 'playlists' as const,
  status: 'status' as const,
  type: 'type' as const,
  source: 'source' as const,
  destination: 'destination' as const,
  date: 'date' as const,
};

export default $columnIds;
