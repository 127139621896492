/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
import React, { useCallback, useMemo } from 'react';
import {
  Column, useFlexLayout, useResizeColumns, useRowSelect, useTable,
} from 'react-table';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import defaultPlaylistIcon from '@assets/icons/default-playlist-icon.svg';
import $columnIds from '@data/columns.constants';
import styles from './styles/MobileMediaDataGrid.module.scss';
import { StyledCheckBox } from '../../../../components';

type Props<T> = {
  tableColumns: Column[];
  hiddenColumns?: string[];
  data: T[];
  rowCheckbox?: {
    isVisible: boolean;
    onCheck?: (rowItem: T) => void;
  }
};

const getCellStyle = (id: string) : string => {
  switch (id) {
    case $columnIds.checkbox:
      return styles.row_cell_checkbox;
    case $columnIds.image:
      return styles.row_cell_image;
    default:
      return styles.row_cell;
  }
};

const MobileMediaDataGrid = <T extends object>({
  tableColumns,
  hiddenColumns = [],
  data,
  rowCheckbox = {
    isVisible: false,
  },
}: Props<T>) => {
  const columns = useMemo(() => Array<Column>(
    {
      id: $columnIds.checkbox,
      Header: '',
      accessor: $columnIds.checkbox,
      width: 27,
      maxWidth: 27,
      Cell: ({ row }: any) => (
        <div className={styles.checkbox_container}>
          <StyledCheckBox
            onChange={() => {
              if (rowCheckbox.onCheck) {
                rowCheckbox.onCheck(row.original);
              }
            }}
            isChecked={row.original.isSelected}
            {...row.getToggleRowSelectedProps}
          />
        </div>
      ),
    },
    {
      Header: '',
      accessor: $columnIds.image,
      width: 64,
      maxWidth: 64,
      Cell: ({ row }: any) => (
        <div className={styles.image_container}>
          <img className={styles.image} src={row.original.imageLink ?? defaultPlaylistIcon} alt="img" />
        </div>
      ),
    },
    ...tableColumns,
  ), [data]);

  const tableInstance = useTable(
    {
      columns,
      data,
      autoResetHiddenColumns: false,
      initialState: {
        hiddenColumns: rowCheckbox.isVisible
          ? hiddenColumns : hiddenColumns?.concat([$columnIds.checkbox]),
      },
    },
    useRowSelect,
    useFlexLayout,
    useResizeColumns,
  );
  const {
    getTableProps,
    getTableBodyProps,
    rows,
    prepareRow,
  } = tableInstance;
  const CustomMobileRow = useCallback(({ index, style }: any) => {
    const row = rows[index];
    prepareRow(row);
    return (
      <tr className={styles.content_tr} {...row.getRowProps({ style })}>
        {row.cells.map((mobileCell) => (
          <td
            className={getCellStyle(mobileCell.column.id)}
            {...mobileCell.getCellProps()}
          >
            {mobileCell.render('Cell')}
          </td>
        ))}
      </tr>
    );
  }, [prepareRow, rows]);
  return (
    <div className={styles.container}>
      <table className={styles.table} {...getTableProps()}>
        <tbody className={styles.tbody} {...getTableBodyProps()}>
          <AutoSizer>
            {({ height, width }: Size) => (
              <FixedSizeList
                className={styles.content_container}
                width={width as number}
                height={height as number}
                itemCount={rows.length}
                layout="vertical"
                itemSize={60}
              >
                {CustomMobileRow}
              </FixedSizeList>
            )}
          </AutoSizer>
        </tbody>
      </table>
    </div>
  );
};

export default MobileMediaDataGrid;
