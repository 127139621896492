/*
  Response types of '/auth' endpoint.
*/
const auth = {
  /*
  The type if email (account) already exists in the database.
  * */
  accAlreadyExist: 'auth-0001' as const,

  /*
  Invalid email while login in. The email doesn't exist in the database.
  * */
  invalidEmail: 'auth-0002' as const,

  /*
  Invalid password while login in. The password doesn't match with the email.
  * */
  invalidPassword: 'auth-0003' as const,

  /*
  Refresh token not found while refreshing tokens. The refresh token doesn't exist in the database.
  * */
  refreshNotFound: 'auth-0004' as const,

  /*
  Refresh token expired for refreshing.
  * */
  refreshExpired: 'auth-0005' as const,

  /*
  Account not activated.
  * */
  accNotActivated: 'auth-0006' as const,
};

/*
 Response types of '/account' endpoint.
*/
const account = {
  activate: {
    /*
    Account for activation not found.
    * */
    accountNotFound: 'acc-0001' as const,
    /*
    Activation secret not found.
    * */
    secretNotMatch: 'acc-0002' as const,
  } as const,
  forgot: {
    /*
    Account for forgot password function not found.
    * */
    accountNotFound: 'acc-0003' as const,
  } as const,
  changePassword: {
    /*
    Account for changing password not found.
    * */
    accountNotFound: 'acc-0004' as const,
    /*
    Change password token not found..
    * */
    tokenNotFound: 'acc-0005' as const,
    /*
    Change password token doesn't match
    * */
    tokenNotMatch: 'acc-0006' as const,
  } as const,
  license: {
    /*
    User trial limit exhausted
    * */
    trialLimitExhausted: 'acc-0007' as const,
  } as const,
};

const user = {
  profile: {
    /*
    Requested music service does not disclose user profile.
    * */
    profileUnknown: 'mususr-0001' as const,
  } as const,
};

const $responseTypes = {
  auth,
  account,
  user,
};

export default $responseTypes;
