import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  LibraryModel,
  LibraryState,
} from '@store/slices/library/library.types';
import selectTabAsync from '@store/slices/library/libraryThunks';
import {
  LoggedMusicService,
} from '@store/slices/music-services/musicService.types';
import searchItems from '@store/slices/library/library.helpers';
import { LibraryTabType } from '../types';

const initialState: LibraryState = {
  items: [],
  isAllSelected: false,
  isAnySelected: false,
  status: 'init',
};

const librarySlice = createSlice({
  name: 'library',
  initialState,
  reducers: {
    selectTab(state, action: PayloadAction<LibraryTabType>) {
      state.selectedTab = action.payload;
    },
    selectService(state, action: PayloadAction<LoggedMusicService>) {
      state.selectedService = action.payload;
    },
    selectAllItems(state) {
      if (!state.items) {
        return;
      }

      state.isAllSelected = !state.isAllSelected;
      state.items.forEach((item) => { item.isSelected = state.isAllSelected; });
      state.isAnySelected = state.items.some((item) => item.isSelected);
    },
    selectItem(state, action: PayloadAction<LibraryModel>) {
      if (!state.items) {
        return;
      }

      const selectedItem = state.items.find((e) => e.id === action.payload.id);
      if (!selectedItem) {
        return;
      }

      selectedItem.isSelected = !selectedItem.isSelected;
      state.isAllSelected = state.items.every(((item) => item.isSelected));
      state.isAnySelected = state.items.some((item) => item.isSelected);
    },
    searchItem(state, action: PayloadAction<{ searchQuery: string }>) {
      const searchQuery = action.payload.searchQuery.toLowerCase();
      if (!searchQuery) {
        state.items.forEach((item) => { item.isVisible = true; });
        return;
      }

      if (!state.selectedTab) {
        return;
      }
      searchItems(state.selectedTab, state.items, searchQuery);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(selectTabAsync.pending, (state, action) => {
      state.lastRequestId = action.meta.requestId;
      state.status = 'loading';
      state.selectedTab = action.meta.arg;
      state.items = [];
      state.errorMessage = undefined;
    });
    builder.addCase(selectTabAsync.fulfilled, (state, action) => {
      if (state.lastRequestId !== action.meta.requestId) {
        return;
      }
      state.status = 'init';
      const libItems = action.payload.libraryItems;
      libItems.forEach((item) => { item.isVisible = true; });
      state.items = libItems;
      state.selectedTab = action.payload.selectedTab;
    });
    builder.addCase(selectTabAsync.rejected, (state, action) => {
      if (action.error.name === 'AbortError') {
        return;
      }
      state.status = 'error';
      state.items = [];
      state.errorMessage = action.payload;
    });
  },
});

export const libraryActions = librarySlice.actions;
export default librarySlice.reducer;
