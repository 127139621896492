import React from 'react';
import loader from '@assets/animations/loader.json';
import { Player } from '@lottiefiles/react-lottie-player';
import styles from './styles/SiteLoader.module.scss';

type Props = {
  headerText?: string;
  subText?: string;
};

const SiteLoader = ({ headerText, subText }: Props) => (
  <div className={styles.container}>
    <Player src={loader} className={styles.loader} loop autoplay />
    <div className={styles.header_text}>
      {headerText}
    </div>
    <div className={styles.sub_text}>
      {subText}
    </div>
  </div>
);

export default SiteLoader;
