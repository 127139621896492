import { useEffect, useState } from 'react';

const useMediaQuery = (
  query: string,
  defaultMatches = window.matchMedia(query).matches,
) => {
  const [matches, setMatches] = useState(defaultMatches);

  useEffect(() => {
    const media = window.matchMedia(query);

    if (media.matches !== matches) setMatches(media.matches);

    const listener = () => setMatches(media.matches);

    media.addEventListener('change', listener);

    return () => media.removeEventListener('change', listener);
  }, [query, matches]);

  return matches;
};

const useMobile = (): { isMobile: boolean } => {
  const isMobile = useMediaQuery('(max-width: 766px)');
  return { isMobile };
};

const useTablet = (): {
  isTablet: boolean,
  isVerticalTablet: boolean,
  isHorizontalTablet: boolean
} => {
  const isTablet = useMediaQuery('(min-width: 767px) and (max-width: 1099px)')/*  && useMediaQuery('(max-width: 1099px)') */;
  const isVerticalTablet = useMediaQuery('(min-width: 767px) and (max-width: 1000px)')/*  && useMediaQuery('(max-width: 1000px)') */;
  const isHorizontalTablet = useMediaQuery('(min-width: 1001px) and (max-width: 1099px)')/*  && useMediaQuery('(max-width: 1099px)') */;
  return { isTablet, isVerticalTablet, isHorizontalTablet };
};

const useDesktop = (): { isDesktop: boolean } => {
  const isDesktop = useMediaQuery('(min-width: 1100px)');
  return { isDesktop };
};

export { useMobile, useTablet, useDesktop };
