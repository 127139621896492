import React from 'react';
import { Tooltip } from 'react-tooltip';
import tempAvatar from '@assets/temp/user-avatar.svg';
import styles from './styles/UserAccountForm.module.scss';

type Props = {
  userAvatar?: string;
  username?: string;
  email?: string;
};

const UserAccountForm = ({ userAvatar, username, email }: Props) => (
  <div className={styles.container}>
    <img
      className={styles.user_avatar}
      src={userAvatar || tempAvatar}
      alt="avatar"
      onError={(e) => {
        e.currentTarget.src = tempAvatar;
      }}
    />
    <div className={styles.text_container}>
      <div className={styles.username_text}>
        <span
          data-tooltip-id="user_name"
          data-tooltip-content={username}
        >
          {username}
        </span>
      </div>
      <Tooltip id="user_name" className={styles.tooltip} />
      <div className={styles.email_text}>
        <span
          data-tooltip-id="user_email"
          data-tooltip-content={email}
        >
          {email}
        </span>
      </div>
      <Tooltip id="user_email" className={styles.tooltip} place="bottom" />
    </div>
  </div>
);

export default UserAccountForm;
