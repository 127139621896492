/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
import React, { useCallback, useMemo } from 'react';
import {
  Column, useFlexLayout, useResizeColumns, useRowSelect, useTable,
} from 'react-table';
import { FixedSizeList } from 'react-window';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import defaultPlaylistIcon from '@assets/icons/default-playlist-icon.svg';
import $columnIds from '@data/columns.constants';
import styles from './styles/MediaDataGrid.module.scss';
import { StyledCheckBox } from '../../../../components';

type Props<T> = {
  tableColumns: Column[];
  hiddenColumns?: string[];
  data: T[];
  rowCheckbox?: {
    isVisible: boolean;
    onCheck?: (rowItem: T) => void;
  }
};

const getCellStyle = (id: string) : string => {
  switch (id) {
    case $columnIds.checkbox:
      return styles.row_cell_checkbox;
    case $columnIds.image:
      return styles.row_cell_image;
    case $columnIds.title:
      return styles.row_cell_title;
    case $columnIds.tracks:
      return styles.row_cell_tracks;
    default:
      return styles.row_cell;
  }
};

const getHeaderCellStyle = (id: string) : string => {
  switch (id) {
    case $columnIds.title:
      return styles.header_cell_title;
    case $columnIds.tracks:
      return styles.row_cell_tracks;
    default:
      return styles.col_header;
  }
};

const MediaDataGrid = <T extends object>({
  tableColumns,
  hiddenColumns = [],
  data,
  rowCheckbox = {
    isVisible: false,
  },
}: Props<T>) => {
  const columns = useMemo(() => Array<Column>(
    {
      id: $columnIds.checkbox,
      Header: '',
      accessor: $columnIds.checkbox,
      width: 56,
      maxWidth: 56,
      Cell: ({ row }: any) => (
        <div className={styles.checkbox_container}>
          <StyledCheckBox
            onChange={() => {
              if (rowCheckbox?.onCheck) {
                rowCheckbox.onCheck(row.original);
              }
            }}
            isChecked={row.original.isSelected}
            {...row.getToggleRowSelectedProps}
          />
        </div>
      ),
    },
    {
      Header: '',
      accessor: $columnIds.image,
      width: 78,
      maxWidth: 78,
      Cell: ({ row }: any) => (
        <div className={styles.image_container}>
          <img
            className={styles.image}
            src={row.original.imageLink ?? defaultPlaylistIcon}
            alt="img"
            onError={(event) => {
              event.currentTarget.src = defaultPlaylistIcon;
              return event.currentTarget.src;
            }}
          />
        </div>
      ),
    },
    ...tableColumns,
  ), [data]);

  const tableInstance = useTable(
    {
      columns,
      data,
      autoResetHiddenColumns: false,
      initialState: {
        hiddenColumns: rowCheckbox.isVisible
          ? hiddenColumns : hiddenColumns?.concat([$columnIds.checkbox]),
      },
      defaultColumn: { width: 100 },
    },
    useRowSelect,
    useFlexLayout,
    useResizeColumns,
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  const CustomRow = useCallback(({ index, style }: any) => {
    const row = rows[index];
    prepareRow(row);
    return (
      <div className={styles.content_tr} {...row.getRowProps({ style })}>
        {row.cells.map((cell) => (
          <div
            className={getCellStyle(cell.column.id)}
            {...cell.getCellProps()}
          >
            {cell.render('Cell')}
          </div>
        ))}
      </div>
    );
  }, [prepareRow, rows]);

  const areAnyHeaders = columns.some((col) => col.Header);
  return (
    <div className={styles.container}>
      <div className={styles.table} {...getTableProps()}>
        {areAnyHeaders && (
        <div className={styles.thead}>
          {headerGroups.map((headerGroup) => (
            <div className={styles.headers_row} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <div
                  className={getHeaderCellStyle(column.id)}
                  {...column.getHeaderProps({
                    style:
                            {
                              width: column.width,
                              minWidth: column.minWidth,
                              maxWidth: column.maxWidth,
                            },
                  })}
                >
                  {column.render('Header')}
                </div>
              ))}
            </div>
          ))}
        </div>
        ) }
        <div className={styles.tbody} {...getTableBodyProps()}>
          <AutoSizer>
            {({ height, width }: Size) => (
              <FixedSizeList
                className={styles.scroller}
                width={width as number}
                height={height as number}
                itemCount={rows.length}
                layout="vertical"
                itemSize={60}
              >
                {CustomRow}
              </FixedSizeList>
            )}
          </AutoSizer>
        </div>
      </div>
    </div>
  );
};

export default MediaDataGrid;
