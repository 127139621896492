import { useState } from 'react';
import MusicService from '@data/enums/musicService';
import { useAppDispatch } from '@store/hooks';
import { logInServiceAsync } from '@store/slices/music-services/musicServicesThunks';
import { getMusicAuthService } from '../../../inversify.config';

type Status = 'init' | 'loading' | 'error' | 'success';

export default function useServiceCallback() {
  const [status, setStatus] = useState('init' as Status);
  const [error, setError] = useState('');
  const dispatch = useAppDispatch();

  async function serviceCallback(
    service: string,
    searchParams: URLSearchParams,
  ): Promise<void> {
    setStatus('loading');

    const callbackError = searchParams.get('error');
    if (callbackError !== null) {
      setError(callbackError);
      setStatus('error');
      return;
    }

    const code = searchParams.get('code');
    const state = searchParams.get('state');
    if (code !== null && state !== null) {
      const serviceAuth = getMusicAuthService();
      const musicService = service as MusicService;
      const res = await serviceAuth.sendOauthCallback(musicService, code, state);

      if (res.type === 'error') {
        setError(res.message);
        setStatus('error');
        return;
      }
      await dispatch(logInServiceAsync(res.credentials));
      setStatus('success');
      return;
    }

    setError('An error occurred during a callback processing.');
    setStatus('error');
  }

  return {
    status,
    error,
    serviceCallback,
  };
}
