import React from 'react';
import { LibraryTabType } from '@store/slices/types';
import { useAppSelector } from '@store/hooks';
import styles from './styles/TabsForm.module.scss';
import TabButton from '../buttons/TabButton';

export type LibraryTabModel = {
  type: LibraryTabType;
  icon: string;
  tabName: string;
  onClick: () => void;
};

type Props = {
  serviceTabs: Array<LibraryTabModel>;
};

const LibraryTabsForm = ({ serviceTabs }: Props) => {
  const selectedTab = useAppSelector((store) => store.library.selectedTab);
  return (
    <div className={styles.container}>
      {serviceTabs
        .map((tab) => (
          <TabButton
            key={tab.tabName}
            icon={tab.icon}
            tabName={tab.tabName}
            onClick={tab.onClick}
            isSelected={tab.type === selectedTab}
          />
        ))}
    </div>
  );
};

export default LibraryTabsForm;
