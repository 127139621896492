import React from 'react';
import { $homePageText } from '@data/text.constants';
import { useMobile } from '@hooks/index';
import { useGlobalModalContext } from 'src/contexts';
import { useAppSelector } from '@store/hooks';
import { EmptyServicesBlank } from '@components/index';
import styles from './styles/LoggedServices.module.scss';
import LoggedServicesList from './LoggedServicesList';

const LoggedServices = () => {
  const { isMobile } = useMobile();
  const { showModal } = useGlobalModalContext();
  const loggedServices = useAppSelector((store) => store.musicServices.loggedServices);

  const show = () => {
    showModal({
      type: 'all_services',
    });
  };

  return (
    // eslint-disable-next-line no-nested-ternary
    isMobile ? (
      (loggedServices.length > 0)
        ? <LoggedServicesList loggedServices={loggedServices} />
        : <EmptyServicesBlank />
    ) : (
      <div className={styles.container}>
        <div className={styles.inner_container}>
          <div className={styles.header_text}>
            {$homePageText.loggedServices}
          </div>
          <LoggedServicesList loggedServices={loggedServices} />
          <div className={styles.show_all_wrapper}>
            <div className={styles.show_all_container}>
              <div className={styles.show_all} onClick={show} role="presentation">
                {$homePageText.allServices}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default LoggedServices;
