import { $confirmPasswordValidation } from '@data/text.constants';
import { useValidation, Validator } from '@hooks/index';

const useEmailValidation = (inputText: string = '') => {
  const validators: Array<Validator> = [{
    validate: (value) => value.length !== 0,
    validationText: 'Email cannot be empty.',
  }, {
    validate: (value) => /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value),
    validationText: 'This is not an email.',
  }];

  const {
    validInput: validEmailField,
    setInputState: setEmailField,
    validation: emailValidation,
    inputState: emailField,
  } = useValidation(validators, inputText);

  return {
    emailField,
    setEmailField,
    validEmailField,
    emailValidation,
  };
};

const usePasswordValidation = (inputText: string = '') => {
  const validators: Array<Validator> = [{
    validate: (value) => value.length !== 0,
    validationText: 'Password cannot be empty.',
  }, {
    validate: (value) => value.length >= 8,
    validationText: 'Password must be at least 8 characters.',
  }, {
    validate: (value) => value.length < 32,
    validationText: 'Password must be no more than 32 characters.',
  }];

  const {
    validInput: validPasswordField,
    setInputState: setPasswordField,
    validation: passwordValidation,
    inputState: passwordField,
  } = useValidation(validators, inputText);

  return {
    passwordField,
    setPasswordField,
    validPasswordField,
    passwordValidation,
  };
};

const useConfirmPasswordValidation = (inputText: string) => {
  const validators: Array<Validator> = [{
    validate: (value) => value === inputText,
    validationText: $confirmPasswordValidation.validationText,
  }];

  const {
    validInput: validConfirmPasswordField,
    setInputState: setConfirmPasswordField,
    validation: confirmPasswordValidation,
    inputState: confirmPasswordField,
  } = useValidation(validators, inputText);

  return {
    confirmPasswordField,
    setConfirmPasswordField,
    validConfirmPasswordField,
    confirmPasswordValidation,
  };
};

export { useEmailValidation, usePasswordValidation, useConfirmPasswordValidation };
