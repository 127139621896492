import React from 'react';
import styles
  from '@features/transfer-page/active-transfers/responsive/styles/MobileActiveTransfersPage.module.scss';
import { SearchFieldMobile } from '@features/home-page';
import MobileCommandsForm from '@features/library-page/components/media-content/MobileCommandsForm';
import { Command } from '@features/library-page/buttons/CommandButton';
import { ActiveTransferState } from '@store/slices/active-transfers/activeTransfers.types';
import { activeTransfersActions } from '@store/slices/active-transfers/activeTransfersSlice';
import { useAppDispatch } from '@store/hooks';
import MobileMediaDataGrid
  from '@features/library-page/components/media-content/MobileMediaDataGrid';
import { Column } from 'react-table';
import $columnIds from '@data/columns.constants';
import arrow from '@assets/arrow.svg';
import { useGlobalModalContext } from '../../../../contexts';

type Props = {
  commands: Command[];
  isAnySelected: boolean;
  isAllSelected: boolean;
  activeTransfers: ActiveTransferState[];
};

const mobileColumns = Array<Column>(
  {
    Header: 'Track',
    accessor: $columnIds.title,
    Cell: ({ row }: any) => {
      const { showModal } = useGlobalModalContext();
      const showTransferProcessModal = () => {
        showModal({ type: 'transfer_process', props: { activeTransferId: row.original.activeTransferId } });
      };
      return (
        <div className={styles.mob_grid_container} role="presentation" onClick={showTransferProcessModal}>
          <div className={styles.text_container}>
            <div className={styles.main_content}>
              <div className={styles.title}>
                {row.original.title}
              </div>
              <div className={styles.logo_container}>
                <img
                  className={styles.service_logo}
                  src={row.original.sourceInfo.serviceLogo}
                  alt="srcLogo"
                />
                <img className={styles.arrow_icon} src={arrow} alt="arrow" />
                <img
                  className={styles.service_logo}
                  src={row.original.destinationInfo.serviceLogo}
                  alt="dstLogo"
                />
              </div>
            </div>
            <div className={styles.subtext_container}>
              <div className={styles.subtext_text}>
                <div>
                  {row.original.totalItems}
                  {' '}
                  tracks
                </div>
                <div>
                  {row.original.type}
                  {' '}
                  type
                </div>
              </div>
              <div className={styles.progress_text}>
                {(!row.original.completedProgress) ? row.original.status : `${row.original.completedProgress.toFixed()}%`}
              </div>
            </div>
          </div>
        </div>
      );
    },
  },
);

const MobileActiveTransfersPage = ({
  commands, isAnySelected, isAllSelected, activeTransfers,
}: Props) => {
  const dispatch = useAppDispatch();
  const rowCheckbox = {
    isVisible: true,
    onCheck: (item: ActiveTransferState) => dispatch(activeTransfersActions.selectTransfer(item)),
  };
  return (
    <div className={styles.container}>
      <div className={styles.header_container}>
        {/* <SearchFieldMobile placeholder={} value={} /> */}
        <MobileCommandsForm
          isAnySelected={isAnySelected}
          toggleCheckbox={() => dispatch(activeTransfersActions.selectAllTransfers())}
          isAllSelected={isAllSelected}
          commands={commands}
          isTransferButtonVisible={false}
        />
      </div>
      <div className={styles.data_container}>
        <MobileMediaDataGrid
          tableColumns={mobileColumns}
          data={activeTransfers}
          rowCheckbox={rowCheckbox}
        />
      </div>
    </div>
  );
};

export default MobileActiveTransfersPage;
