import React from 'react';
import styles
  from './styles/BlankWideButton.module.scss';

type Props = {
  icon: string;
  onClick: () => void;
};

const BlankWideButton = ({ icon, onClick }: Props) => (
  <div
    className={styles.container}
    role="presentation"
    onClick={onClick}
  >
    <img className={styles.icon} src={icon} alt="blank" />
  </div>
);

export default BlankWideButton;
