import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ActiveTransfersState,
  ActiveTransferState,
} from '@store/slices/active-transfers/activeTransfers.types';
import loadActiveTransfersAsync from '@store/slices/active-transfers/activeTransfersThunks';
import { TransferItem } from '@services/types/transfer.types';
import { getTransferStatus } from '@store/slices/transfer/transfer.utils';

const initialState: ActiveTransfersState = {
  items: [],
  isLoading: false,
  isAllSelected: false,
  isAnySelected: false,
};

const activeTransfersSlice = createSlice(
  {
    name: 'active-transfers',
    initialState,
    reducers: {
      selectTransfer(state, action: PayloadAction<ActiveTransferState>) {
        if (!state.items) {
          return;
        }

        const selectedItem = state.items
          .find((e) => e.activeTransferId === action.payload.activeTransferId);
        if (!selectedItem) {
          return;
        }

        selectedItem.isSelected = !selectedItem.isSelected;
        state.isAllSelected = state.items.every(((item) => item.isSelected));
        state.isAnySelected = state.items.some((item) => item.isSelected);
      },
      selectAllTransfers(state) {
        if (!state.items) {
          return;
        }

        state.isAllSelected = !state.isAllSelected;
        state.items.forEach((item) => { item.isSelected = state.isAllSelected; });
        state.isAnySelected = state.items.some((item) => item.isSelected);
      },
      updateTransferInfo(state, action: PayloadAction<TransferItem>) {
        if (!state.items
          .find((transferItem) => transferItem.activeTransferId === action.payload.transferId)) {
          return;
        }

        for (let i = 0; i < state.items.length; i++) {
          if (!(state.items[i].activeTransferId === action.payload.transferId)) {
            continue;
          }

          let totalItems = 0;
          let foundItems = 0;
          let notFoundItems = 0;
          const keys = Object.keys(action.payload.transferUnits);
          for (let j = 0; j < keys.length; j += 1) {
            const key = keys[j];
            totalItems += action.payload.transferUnits[key].itemsCount;
            foundItems += action.payload.transferUnits[key].foundItemsCount;
            notFoundItems += action.payload.transferUnits[key].notFoundItemsCount;
          }
          state.items[i].status = 'Pending';
          state.items[i].completedProgress = ((foundItems + notFoundItems) / totalItems) * 100;
          state.items[i].totalItems = totalItems;
          state.items[i].foundItems = foundItems;
          state.items[i].notFoundItems = notFoundItems;
          state.items[i].actionStatus = getTransferStatus(action.payload.status).text;
          if (action.payload.status.transferStatusCode === 'trialCompleted') {
            state.items[i].trialTransferred = action.payload.status.tracksTransferred;
          }
        }
      },
      cancelActiveTransfer(state, action: PayloadAction<string>) {
        if (!state.items
          .find((transferItem) => transferItem.activeTransferId === action.payload)) {
          return;
        }
        for (let i = 0; i < state.items.length; i++) {
          if (!(state.items[i].activeTransferId === action.payload)) {
            continue;
          }
          state.items[i].status = 'Canceled';
          state.items[i].actionStatus = 'Canceled';
          state.items[i].completedProgress = undefined;
        }
      },
      finishActiveTransfer(state, action: PayloadAction<string>) {
        if (!state.items
          .find((transferItem) => transferItem.activeTransferId === action.payload)) {
          return;
        }
        for (let i = 0; i < state.items.length; i++) {
          if (!(state.items[i].activeTransferId === action.payload)) {
            continue;
          }
          state.items[i].status = 'Finished';
          state.items[i].completedProgress = undefined;
        }
      },
    },
    extraReducers: (builder) => {
      builder.addCase(loadActiveTransfersAsync.pending, (state, action) => {
        state.items = [];
        state.isLoading = true;
      });
      builder.addCase(loadActiveTransfersAsync.fulfilled, (state, action) => {
        state.items = action.payload.transferItems;
        state.isLoading = false;
      });
      builder.addCase(loadActiveTransfersAsync.rejected, (state, action) => {
        state.items = [];
        state.isLoading = false;
      });
    },
  },
);

export const activeTransfersActions = activeTransfersSlice.actions;

export default activeTransfersSlice.reducer;
