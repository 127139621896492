import React from 'react';
import { Tooltip } from 'react-tooltip';
import styles from './styles/CommandButton.module.scss';

export type Command = {
  icon: string;
  name: string;
  onClick?: () => void;
};

const CommandButton = ({ icon, name, onClick }: Command) => (
  <>
    <div
      className={styles.container}
      data-tooltip-id={icon}
      data-tooltip-content={name}
      role="presentation"
      onClick={onClick}
    >
      <img className={styles.icon} src={icon} alt="icon" />
    </div>
    <Tooltip id={icon} className={styles.tooltip} />
  </>
);

export default CommandButton;
