import React, { useState } from 'react';
import useChangeLicense from '@features/admin-page/hooks/useChangeLicense';
import styles from './ChangeLicenseWidget.module.scss';

const licenseOptions = ['Trial', 'Basic', 'Professional', 'Ultimate', 'Lifetime'];

type Props = {
  userEmail: string;
};

const ChangeLicenseWidget = ({ userEmail }: Props) => {
  const [selectedLicense, setSelectedLicense] = useState('Basic');
  const { changeLicense } = useChangeLicense();
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedLicense(event.target.value);
  };

  return (
    <div>
      <select className={styles.dropdown} id="license" value={selectedLicense} onChange={handleChange}>
        {licenseOptions.map((option) => (
          <option className={styles.dropdown_item} key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <div
        className={styles.submit}
        role="presentation"
        onClick={() => changeLicense(userEmail, selectedLicense)}
      >
        Submit
      </div>
    </div>
  );
};

export default ChangeLicenseWidget;
