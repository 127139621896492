import React from 'react';
import styles from './styles/SideBarForm.module.scss';
import MusConvHeader from '../../../../components/MusConvHeader';
import NavigationForm from '../NavigationForm';
import ServiceList from '../ServiceList';
import ConnectServiceButton from '../buttons/ConnectServiceButton';

const SideBarForm = () => (
  <aside className={styles.sidebar_form}>
    <MusConvHeader />
    <NavigationForm />
    <div className={styles.scroller}>
      <ServiceList />
    </div>
    <ConnectServiceButton />
  </aside>
);

export default SideBarForm;
