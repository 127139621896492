import React from 'react';
import { $mainPageText } from '@data/text.constants';
import styles from './styles/ConnectServiceButton.module.scss';
import plusIcon from '../../assets/plus-icon.svg';
import { useGlobalModalContext } from '../../../../contexts';

const ConnectServiceButton = () => {
  const { showModal } = useGlobalModalContext();

  return (
    <div
      className={styles.button}
      role="button"
      tabIndex={0}
      onClick={() => showModal({ type: 'all_services' })}
      onKeyDown={undefined}
    >
      <div className={styles.button_out}>
        <img className={styles.button_img} src={plusIcon} alt="plusIcon" />
      </div>
      <div className={styles.button_text}>{$mainPageText.connectService}</div>
    </div>
  );
};

export default ConnectServiceButton;
