import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TransferStatus } from '@store/slices/transfer/transfer.types';
import {
  LoggedMusicService,
} from '@store/slices/music-services/musicService.types';
import { TransferItem } from '@services/types/transfer.types';
import { getTransferStatus } from '@store/slices/transfer/transfer.utils';

type TransferState = {
  transferId?: string;
  status: TransferStatus;
  sourceService?: LoggedMusicService;
  destinationService?: LoggedMusicService;
  actionStatus: string;
  trialTransferred?: number;
  totalItems: number;
  foundItems: number;
  notFoundItems: number;
  errorMessage?: string;
};

type TransfersState = {
  items: TransferState[];
};

const initialState: TransfersState = {
  items: [],
};

const transferSlice = createSlice(
  {
    name: 'transfer-status',
    initialState,
    reducers: {
      updateTransferInfo(state, action: PayloadAction<TransferItem>) {
        if (!state.items
          .find((transferItem) => transferItem.transferId === action.payload.transferId)) {
          return;
        }

        for (let i = 0; i < state.items.length; i++) {
          if (!(state.items[i].transferId === action.payload.transferId)) {
            continue;
          }
          state.items[i].status = 'inProgress';
          state.items[i].actionStatus = getTransferStatus(action.payload.status).text;
          if (action.payload.status.transferStatusCode === 'trialCompleted') {
            state.items[i].trialTransferred = action.payload.status.tracksTransferred;
          }

          let totalItems = 0;
          let foundItems = 0;
          let notFoundItems = 0;
          const keys = Object.keys(action.payload.transferUnits);
          for (let j = 0; j < keys.length; j += 1) {
            const key = keys[j];
            totalItems += action.payload.transferUnits[key].itemsCount;
            foundItems += action.payload.transferUnits[key].foundItemsCount;
            notFoundItems += action.payload.transferUnits[key].notFoundItemsCount;
          }

          state.items[i].totalItems = totalItems;
          state.items[i].foundItems = foundItems;
          state.items[i].notFoundItems = notFoundItems;
        }
      },
    },
    extraReducers: (builder) => {

      /* builder.addCase(startActiveTransferAsync.fulfilled, (state, action) => {
        if (action.payload.transferStarted) {
          state.status = 'inProgress';
          state.transferId = action.payload.transferId;
          state.sourceService = action.payload.sourceServiceData;
          state.destinationService = action.payload.destinationServiceData;
        }
      });
      builder.addCase(startActiveTransferAsync.rejected, (state, action) => {
        state.status = 'error';
        state.errorMessage = action.payload;
      }); */
    },
  },
);

export const transferActions = transferSlice.actions;
export default transferSlice.reducer;
