import React from 'react';
import { LoginForm, MusConvHeaderAuth } from '@features/authorization';
import styles from './styles/Header.module.scss';

const Login = () => (
  <div>
    <div className={styles.header}>
      <MusConvHeaderAuth height="52" width="170" />
    </div>
    <LoginForm />
  </div>
);

export default Login;
