import React, { useState } from 'react';
import { useMobile } from '@hooks/index';
import { useGlobalModalContext } from 'src/contexts';
import closeIcon from '@features/modal-windows/assets/close-icon.svg';
import { $showReportModal as text } from '@data/text.constants';
import { MediaDataGrid } from '@features/library-page';
import { Column } from 'react-table';
import MobileMediaDataGrid from '@features/library-page/components/media-content/MobileMediaDataGrid';
import { TransferResult } from '@services/types/transfer.types';
import $columnIds from '@data/columns.constants';
import { Track } from '@services/types/music.items.types';
import backIcon from '../../../assets/arrow-back.svg';
import styles from './styles/ShowReportModal.module.scss';

type ShowReportModalProps = {
  transferResult : TransferResult;
};

/**
 * Modal with found and not found tracks in playlists.
 *
 * Show playlist transfer details on click on playlist in history tab.
 *
 * @param playlistTitle - playlist title.
 * @param foundTracks - list of found tracks.
 * @param notFoundTracks - list of not found tracks.
 */

const ShowReportModal = ({ transferResult }: ShowReportModalProps) => {
  const { hideModal } = useGlobalModalContext();
  const [buttonSelected, setButtonSelected] = useState<string>(text.found);
  const { isMobile } = useMobile();
  const columns = Array<Column>(
    {
      Header: 'Track',
      accessor: $columnIds.title,
      width: 280,
    },
    {
      Header: 'Artist',
      accessor: $columnIds.artist,
    },
    {
      Header: 'Album',
      accessor: $columnIds.album,
    },
  );
  const mobileColumns = Array<Column>(
    {
      Header: 'Track',
      accessor: $columnIds.title,
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ row }: any) => (
        <div className={styles.text_container}>
          <div className={styles.title}>
            {row.original.title}
          </div>
          <div className={styles.subtext_container}>
            <div className={styles.artist}>
              {row.original.artist}
            </div>
            <div className={styles.album}>
              {row.original.album}
            </div>
          </div>
        </div>
      ),
    },
  );
  const tracks = buttonSelected === text.found
    ? transferResult.foundTracks : transferResult.notFoundTracks;
  return (
    <div className={styles.modal}>
      {isMobile && <img src={backIcon} className={styles.back_btn} alt="back" role="presentation" onClick={hideModal} />}
      <img className={styles.close_btn} alt="close" src={closeIcon} role="presentation" onClick={hideModal} />
      <div className={styles.playlist_title}>{transferResult.playlistTitle}</div>
      <div className={styles.buttons_container}>
        <div
          className={`${styles.button} ${buttonSelected === text.found ? styles.selected : ''}`}
          role="presentation"
          onClick={() => setButtonSelected(text.found)}
        >
          {text.found}
        </div>
        <div
          className={`${styles.button} ${buttonSelected === text.notFound ? styles.selected : ''}`}
          role="presentation"
          onClick={() => setButtonSelected(text.notFound)}
        >
          {text.notFound}
        </div>
      </div>
      <div className={styles.data_grid}>
        { isMobile
          ? (
            <MobileMediaDataGrid
              tableColumns={mobileColumns}
              data={buttonSelected
                === text.found ? transferResult.foundTracks : transferResult.notFoundTracks}
            />
          )
          : (
            <MediaDataGrid
              tableColumns={columns}
              data={buttonSelected
                === text.found ? transferResult.foundTracks : transferResult.notFoundTracks}
            />
          )}
      </div>

    </div>
  );
};

export default ShowReportModal;
