import spotifyIcon from '@assets/service-icons/spotify-icon.svg';
import youtubeIcon from '@assets/service-icons/youtube-icon.svg';
import youtubeMusicIcon from '@assets/service-icons/youtube-music-icon.svg';
import deezerIcon from '@assets/service-icons/deezer-icon.svg';
import appleIcon from '@assets/service-icons/apple-icon.svg';
import napsterIcon from '@assets/service-icons/napster-icon.svg';
import spotifyLogo from '@assets/service-logos/spotify-logo.svg';
import youtubeLogo from '@assets/service-logos/youtube-logo.svg';
import youtubeMusicLogo from '@assets/service-logos/youtube-music-logo.svg';
import deezerLogo from '@assets/service-logos/deezer-logo.svg';
import appleLogo from '@assets/service-logos/apple-logo.svg';
import napsterLogo from '@assets/service-logos/napster-logo.svg';
import { MusicServiceData } from '@store/slices/music-services/musicService.types';
import MusicService from '@data/enums/musicService';

const $allMusicServices: Array<MusicServiceData> = [
  {
    icon: spotifyIcon,
    logo: spotifyLogo,
    title: 'Spotify',
    type: MusicService.spotify,
    sourceData: ['playlists', 'albums', 'artists', 'tracks'],
    destinationData: ['playlists', 'albums', 'artists', 'tracks'],
  },
  {
    icon: appleIcon,
    logo: appleLogo,
    title: 'Apple Music',
    type: MusicService.appleMusic,
    sourceData: ['playlists', 'albums', 'artists', 'tracks'],
    destinationData: ['playlists', 'albums', 'artists', 'tracks'],
  },
  {
    icon: youtubeIcon,
    logo: youtubeLogo,
    title: 'YouTube',
    type: MusicService.youTube,
    sourceData: ['playlists', 'artists', 'tracks'],
    destinationData: ['playlists'],
  },
  {
    icon: youtubeMusicIcon,
    logo: youtubeMusicLogo,
    title: 'YouTube Music',
    type: MusicService.youTubeMusic,
    sourceData: ['playlists', 'artists', 'tracks'],
    destinationData: ['playlists'],
  },
  {
    icon: deezerIcon,
    logo: deezerLogo,
    title: 'Deezer',
    type: MusicService.deezer,
    sourceData: ['playlists', 'albums', 'artists', 'tracks'],
    destinationData: ['playlists', 'albums', 'artists', 'tracks'],
  },
  {
    icon: napsterIcon,
    logo: napsterLogo,
    title: 'Napster',
    type: MusicService.napster,
    sourceData: ['playlists', 'albums', 'artists', 'tracks'],
    destinationData: ['playlists', 'albums', 'tracks'],
  },
];

export default $allMusicServices;
