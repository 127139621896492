import React, { ChangeEventHandler } from 'react';
import styles from './styles/SearchField.module.scss';

type Props = {
  placeholder: string;
  value: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  isVisible?: boolean;
};

const SearchField = ({
  placeholder, onChange, value, isVisible = true,
}: Props) => (

  <input
    type="text"
    className={isVisible ? styles.search_field : styles.hidden}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
  />
);

export default SearchField;
