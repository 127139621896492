import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './styles/NextPrevButton.module.scss';
import prevIcon from '../../assets/prev-button.svg';

const PreviousButton = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const hasPreviousState = location.key !== 'default';
  return (
    <button
      className={hasPreviousState ? styles.circle : styles.circle_inactive}
      type="button"
      onClick={() => {
        if (hasPreviousState) {
          navigate(-1);
        }
      }}
    >
      <img className={styles.image} alt="next" src={prevIcon} />
    </button>
  );
};

export default PreviousButton;
