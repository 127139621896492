import { useState } from 'react';
import MusicService from '@data/enums/musicService';
import { getMusicAuthService } from '../../../inversify.config';

type Status = 'init' | 'loading' | 'error' | 'success';

export default function useServiceRedirectUri() {
  const [serviceStatus, setServiceStatus] = useState('init' as Status);
  const [error, setError] = useState('');

  async function serviceRedirectUri(musicService: MusicService): Promise<void> {
    setServiceStatus('loading');

    const serviceAuth = getMusicAuthService();
    const res = await serviceAuth.getOauthRedirectUri(musicService);
    if (res.type === 'error') {
      setError(res.message);
      setServiceStatus('error');
      return;
    }
    setTimeout(() => window.open(res.uri));
    setServiceStatus('success');
  }

  return {
    serviceStatus,
    error,
    serviceRedirectUri,
  };
}
