import { $registerSuccessText } from '@data/text.constants';
import { StyledButton } from '@components/index';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import $route from '@data/routing.constants';

const GoToAppButton = () => {
  const navigate = useNavigate();
  return (
    <StyledButton
      title={$registerSuccessText.goToApp}
      onClick={() => navigate($route.main)}
    />
  );
};

export default GoToAppButton;
