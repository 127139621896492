import React from 'react';
import ReactModal from 'react-modal';
import useLibraryPage from '@pages/hooks/useLibraryPage';
import { useTablet } from '@hooks/responsive';
import { ServiceSidebar } from '@features/library-page';
import tempAvatar from '@assets/temp/user-avatar.svg';
import styles from './styles/ServiceSidebarModal.module.scss';
import LibraryTabsForm from '../components/LibraryTabsForm';

type Props = {
  isOpen: boolean;
  isClosing: boolean;
  closeModal: () => void;
  serviceLogo: string;
  userAvatar?: string;
  email?: string;
};

const ServiceSidebarModal = ({
  isOpen, isClosing, closeModal, serviceLogo, userAvatar, email,
}: Props) => {
  const { tabs } = useLibraryPage();
  const { isVerticalTablet } = useTablet();
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      overlayClassName={`${styles.overlay} ${isClosing ? styles.overlay_fade_out : ''}`}
      className={`${styles.modal} ${isClosing ? styles.modal_fade_out : ''}`}
    >
      { !isVerticalTablet
        ? (
          <div className={styles.container}>
            <div className={styles.inner_container}>
              <img className={styles.service_logo} src={serviceLogo} alt="serviceLogo" />
              {email && (
              <div className={styles.user_container}>
                <img
                  className={styles.user_avatar}
                  src={userAvatar || tempAvatar}
                  onError={(e) => {
                    e.currentTarget.src = tempAvatar;
                  }}
                  alt="avatar"
                />
                <div className={styles.user_email}>
                  {email}
                </div>
              </div>
              )}
              <LibraryTabsForm serviceTabs={tabs} />
            </div>
          </div>
        )
        : (
          <div className={styles.wrapper}>
            <ServiceSidebar
              serviceLogo={serviceLogo}
              tabs={tabs}
              email={email}
              userAvatar={userAvatar}
            />
          </div>
        )}
    </ReactModal>
  );
};

export default ServiceSidebarModal;
