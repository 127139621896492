import { useState } from 'react';
import { LibraryTabModel } from '@features/library-page/components/LibraryTabsForm';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { libraryActions } from '@store/slices/library/librarySlice';
import selectTabAsync from '@store/slices/library/libraryThunks';
import { useParams } from 'react-router-dom';
import { MusicServiceData } from '@store/slices/music-services/musicService.types';
import { getLoggedServiceData } from '@store/slices/music-services/musicService.utils';
import { getServiceTabs } from '@store/slices/library/library.helpers';
import { LibraryTabType } from '@store/slices/types';
import { useAppNavigation, useBeforeRender } from '@hooks/index';

const mapLibraryTabs = (
  service: MusicServiceData,
  dispatch: any,
): LibraryTabModel[] => getServiceTabs(service, (tab) => dispatch(selectTabAsync(tab!)));

const mapLibraryItemTabs = (
  service: MusicServiceData,
  dispatch: any,
  toLibrary: (service: string) => void,
): LibraryTabModel[] => getServiceTabs(service, async (tab) => {
  toLibrary(service.type);
  dispatch(await selectTabAsync(tab!));
});

const useLibraryPage = () => {
  const [tabs, setTabs] = useState<LibraryTabModel[]>([]);
  const { service: urlService, type: tab, id: itemId } = useParams();
  const services = useAppSelector((store) => store.musicServices.loggedServices);
  const dispatch = useAppDispatch();
  const { toLibrary } = useAppNavigation();

  useBeforeRender(() => {
    const selectedService = services.find((s) => s.type === urlService);

    if (!selectedService) {
      throw Error(`Service ${urlService} not found.`);
    }
    dispatch(libraryActions.selectService(selectedService));

    const serviceData = getLoggedServiceData(selectedService);
    if (!serviceData) {
      throw Error(`Service ${selectedService.type} not found.`);
    }
    if (tab) {
      dispatch(libraryActions.selectTab(tab as LibraryTabType));
    }
    const mappedTabs: LibraryTabModel[] = tab
      ? mapLibraryItemTabs(serviceData, dispatch, () => toLibrary(serviceData.type))
      : mapLibraryTabs(serviceData, dispatch);

    if (!tab) {
      dispatch(selectTabAsync(serviceData.sourceData[0]));
    }
    setTabs(mappedTabs);
  }, [dispatch, urlService, itemId]);

  return {
    tabs,
  };
};

export default useLibraryPage;
