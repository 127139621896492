import React from 'react';
import styles from './styles/HistoryTabButton.module.scss';

type Props = {
  title: string;
  onClick: () => void;
};

const HistoryTabButton = ({ title, onClick }: Props) => (
  <button className={styles.container} type="button" onClick={onClick}>
    <div className={styles.tab_name_text}>
      {title}
    </div>
  </button>
);

export default HistoryTabButton;
