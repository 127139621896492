import React from 'react';
import { useMobile } from '@hooks/index';
import useActivateAccount from '@pages/hooks/useActivateAccount';
import { Loader } from '@components/index';
import { MusConvHeaderAuth, RegistrationSuccessfullyForm } from '@features/authorization';
import headerStyles from './styles/Header.module.scss';
import styles from './styles/RegistrationSuccessfully.module.scss';

const RegistrationSuccessfully = () => {
  const {
    status,
    error,
  } = useActivateAccount();
  const { isMobile } = useMobile();

  return (
    <div>
      {!isMobile && (
      <div className={headerStyles.header}>
        <MusConvHeaderAuth height="52" width="170" />
      </div>
      )}
      {status === 'loading' && <div className={styles.loader}><Loader /></div>}
      {status === 'success' && <RegistrationSuccessfullyForm />}
      {status === 'error' && <div className={styles.error}>{error}</div>}
    </div>
  );
};
export default RegistrationSuccessfully;
