import React, { useState } from 'react';
import { $homePageText } from '@data/text.constants';
import { LoggedServices, SearchFieldMobile } from '../index';
import styles from './styles/HomePageMobile.module.scss';

const HomePageMobile = () => {
  const [searchQuery, setSearchQuery] = useState('');
  return (
    <>
      <div className={styles.search_field}>
        <SearchFieldMobile
          placeholder={$homePageText.searchService}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <LoggedServices />
    </>
  );
};

export default HomePageMobile;
