import React from 'react';
import { StyledButton } from '@components/index';
import { useGlobalModalContext } from 'src/contexts';
import closeIcon from '../../../assets/close-icon.svg';
import icon from '../../../assets/error-icon.svg';
import styles from './styles/MessageBoxModal.module.scss';

type Props = {
  title: string;
  description: string | JSX.Element;
  additionalOnClick?: () => void;
};
const MessageBoxModal = ({ description, title, additionalOnClick }: Props) => {
  const { hideModal } = useGlobalModalContext();

  const handleButtonClick = () => {
    hideModal();

    if (additionalOnClick) {
      additionalOnClick();
    }
  };

  return (
    <div className={styles.container}>
      <img
        className={styles.close_btn}
        alt="close"
        src={closeIcon}
        role="presentation"
        onClick={hideModal}
      />
      <div className={styles.icon_container}>
        <img className={styles.icon} src={icon} alt="icon" />
      </div>
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>
        {description}
      </div>
      <div className={styles.button}>
        <StyledButton title="OK" onClick={handleButtonClick} />
      </div>
    </div>
  );
};
export default MessageBoxModal;
