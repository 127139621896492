import React from 'react';
import { useAppDispatch } from '@store/hooks';
import { useGlobalModalContext } from 'src/contexts';
import { $confirmActionModalText as text } from '@data/text.constants';
import { actionConfirmationActions as actions } from '@store/slices/action-confirmation/actionConfirmationSlice';
import { StyledButton } from '@components/index';
import { Player } from '@lottiefiles/react-lottie-player';
import closeIcon from '@features/modal-windows/assets/close-icon.svg';
import styles from './styles/ConfirmActionModal.module.scss';

type Props = {
  title: string;
  description: string;
  icon: object | string;
};
const ConfirmActionModal = ({ description, title, icon }: Props) => {
  const dispatch = useAppDispatch();
  const { hideModal } = useGlobalModalContext();
  return (
    <div className={styles.container}>
      <img
        className={styles.close_btn}
        alt="close"
        src={closeIcon}
        role="presentation"
        onClick={hideModal}
      />
      <div className={styles.icon_container}>
        <Player className={styles.icon} src={icon} autoplay loop />
      </div>
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>
        {description}
      </div>
      <div className={styles.btn_wrapper}>
        <div className={styles.btn}>
          <StyledButton
            title={text.cancel}
            outlined
            onClick={() => {
              dispatch(actions.cancelAction());
              hideModal();
            }}
          />
        </div>
        <div className={styles.btn}>
          <StyledButton
            title={text.confirm}
            onClick={() => {
              dispatch(actions.confirmAction());
              hideModal();
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default ConfirmActionModal;
