import $columnIds from '@data/columns.constants';
import {
  ItemWithTracksLibraryModel,
  AlbumLibraryModel,
  LibraryModel,
  PlaylistLibraryModel,
} from '@store/slices/library/library.types';

import { assertUnreachable } from 'src/utils';

export default function getHiddenColumns(
  data: LibraryModel[],
): string[] {
  const dataType = data.length !== 0 ? data[0].type : undefined;
  if (dataType === undefined) {
    return [];
  }

  const hiddenColumns: string[] = [];

  switch (dataType) {
    case 'playlist': {
      const playlists = data as PlaylistLibraryModel[];
      if (playlists.some((p) => p.tracksCount === undefined || p.tracksCount === null)) {
        hiddenColumns.push($columnIds.tracks);
      }
      return hiddenColumns;
    }
    case 'album': {
      const albums = data as AlbumLibraryModel[];
      if (albums.some((album) => album.tracksCount === undefined || album.tracksCount === null)) {
        hiddenColumns.push($columnIds.tracks);
      }
      return hiddenColumns;
    }
    case 'track':
      return [];
    case 'artist':
      return [];
    default:
      assertUnreachable(dataType);
  }

  return [];
}

export const getVisibleItemTracks = (items: ItemWithTracksLibraryModel[]) => items[0]
  .tracks.filter((i) => i.isVisible);
