import React, { useState } from 'react';
import { useGlobalModalContext } from 'src/contexts';
import backIcon from '@features/modal-windows/assets/arrow-back.svg';
import closeIcon from '@features/modal-windows/assets/close-icon.svg';
import { $allServicesModalText as text } from '@data/text.constants';
import IconButton from '@components/IconButton';
import SearchField from '@components/SearchField';
import styles from './styles/AllServicesModal.module.scss';
import SourceServicesList from './components/SourceServicesList';

/**
 * Modal window with list of all source services.
 */
const AllServicesModal = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const { hideModal } = useGlobalModalContext();

  return (
    <div className={styles.modal}>
      <div className={styles.header}>
        <div className={styles.top_panel}>
          <IconButton image={backIcon} onClick={hideModal} />
          <div>
            <div className={styles.first_info_text}>{text.connectService}</div>
          </div>
          <div className={styles.close_container}>
            <IconButton image={closeIcon} onClick={hideModal} />
          </div>
        </div>
        <div className={styles.separate_line} />
      </div>
      <div className={styles.content_wrapper}>
        <div className={styles.content_row_header}>

          <div className={styles.available_text}>
            {text.availableServices}
          </div>

          <div className={styles.search_field}>
            <SearchField
              placeholder={text.searchPlaceholder}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

        </div>
        <div className={styles.scrollable_list}>
          <SourceServicesList query={searchQuery} />
        </div>

      </div>
    </div>
  );
};

export default AllServicesModal;
