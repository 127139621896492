/* eslint-disable no-nested-ternary */

import React, { useEffect } from 'react';
import { $activeTransfersPageText } from '@data/text.constants';
import { Command } from '@features/library-page/buttons/CommandButton';
import cancelTransferCommand from '@assets/command-icons/cancel-transfer-icon.svg';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import loadActiveTransfersAsync from '@store/slices/active-transfers/activeTransfersThunks';
import { cancelTransferAsync } from '@store/slices/begin-transfer/beginTransferThunks';
import EmptyAnimatedBlank from '@components/blank/EmptyAnimatedBlank';
import $route from '@data/routing.constants';
import { useNavigate } from 'react-router-dom';
import { useMobile, useTablet } from '@hooks/responsive';
import DesktopActiveTransfersPage
  from '@features/transfer-page/active-transfers/responsive/DesktopActiveTransfersPage';
import MobileActiveTransfersPage
  from '@features/transfer-page/active-transfers/responsive/MobileActiveTransfersPage';
import SiteLoader from '@components/SiteLoader';
import styles from './styles/ActiveTransfersPage.module.scss';

const ActiveTransfersPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(loadActiveTransfersAsync());
  }, []);

  const {
    items, isAllSelected, isAnySelected, isLoading,
  } = useAppSelector((state) => state.activeTransfers);

  const areAnyActiveTransfers = items.length !== 0;
  const { isMobile } = useMobile();
  const { isTablet } = useTablet();

  const commands: Array<Command> = [
    {
      icon: cancelTransferCommand,
      name: 'Cancel',
      onClick: () => {
        const ids = items.filter((transfer) => transfer.isSelected)
          .map((transfer) => transfer.activeTransferId) as string[] | undefined;
        dispatch(cancelTransferAsync(ids));
      },
    },
    // TODO add endpoint to delete recent transfer from history
    // { icon: commandDelete, name: 'Delete', onClick: () => {} },
  ];
  return (
    isLoading ? <SiteLoader headerText="Loading..." />
      : (areAnyActiveTransfers) ? (
        <div className={styles.wrapper}>
          {isMobile || isTablet ? (
            <MobileActiveTransfersPage
              commands={commands}
              isAnySelected={isAnySelected}
              isAllSelected={isAllSelected}
              activeTransfers={items}
            />
          ) : (
            <DesktopActiveTransfersPage
              commands={commands}
              isAnySelected={isAnySelected}
              isAllSelected={isAllSelected}
              activeTransfers={items}
            />
          )}
        </div>
      )
        : (
          <div className={styles.blank_container}>
            <EmptyAnimatedBlank
              title={$activeTransfersPageText.noTransfersToShow}
              description={$activeTransfersPageText.connectAndTransfer}
              buttonText={$activeTransfersPageText.getStarted}
              onClick={() => navigate($route.main)}
            />
          </div>
        )
  );
};

export default ActiveTransfersPage;
