import React, { ReactNode } from 'react';
import styles from './styles/TransparentButton.module.scss';

type Props = {
  // title: string,
  onClick?: () => void;
  children: ReactNode;
};

const TransparentButton = ({ onClick, children }: Props) => (
  <button className={styles.button} type="button" onClick={onClick}>{children}</button>
);

export default TransparentButton;
