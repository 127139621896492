import { IAdminService } from '@services/admin/AdminService.interface';
import $serviceType from '@data/service.types.constant';
import { useState } from 'react';
import $responseTypes from '@data/response.types.constants';
import { $loginErrorText as text } from '@data/text.constants';
import { UserDataModel } from '@services/admin/AdminService.types';
import services from '../../../inversify.config';

const parseError = (type?: string): string => {
  const types = $responseTypes.auth;
  switch (type) {
    case types.invalidEmail:
      return text.invalidEmail;
    default:
      return text.unknown;
  }
};

type Status = 'init' | 'loading' | 'error' | 'success';

export default function useGetUserData() {
  const [status, setStatus] = useState('init' as Status);
  const [error, setError] = useState('');

  async function getUserData(email: string): Promise<UserDataModel> {
    setStatus('loading');
    const adminService = services.get<IAdminService>($serviceType.admin);
    const response = await adminService.getUserDataByEmail(email);

    if (response.status === 'error') {
      setError(parseError(response.type));
      setStatus('error');
      throw Error(parseError(response.type));
    }
    setStatus('success');
    return response;
  }

  return {
    status,
    error,
    getUserData,
  };
}
