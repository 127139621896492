import React, { useRef, useEffect, useState } from 'react';
import styles from './styles/TransferProgressBar.module.scss';

type TransferProgressBarProps = {
  transferStatus: string;
  completedPercent: number;
};

/**
 * Progress bar of playlist transfer for ActiveTransferModal.
 *
 * @param completedPercent - percent number of progress transfer (0-100).
 */

const TransferProgressBar = ({ completedPercent, transferStatus }
: TransferProgressBarProps) => {
  const [progressWidth, setProgressWidth] = useState(0);
  const statusBodyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const statusBodyWidth = statusBodyRef.current?.clientWidth || 0;
    const width = (completedPercent / 100) * statusBodyWidth;
    setProgressWidth(width);
  }, [completedPercent]);

  return (
    <div className={styles.status_body} ref={statusBodyRef}>
      <div className={styles.transfer_status}>
        <div>
          {/* TODO: change dynamically due to transfer status of playlist */}
          Transfer status...
        </div>
        <div>
          {/* TODO: change dynamically due to transfer status of playlist */}
          {completedPercent}
          %
        </div>
      </div>
      <div className={styles.progress_bar}>
        <div
          className={styles.progress_color}
          style={{ width: progressWidth }}
        />
      </div>
    </div>
  );
};

export default TransferProgressBar;
