import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles/NextPrevButton.module.scss';
import nextIcon from '../../assets/next-button.svg';

const NextButton = () => {
  const navigate = useNavigate();

  return (
    <button
      className={styles.circle}
      type="button"
      onClick={() => {
        navigate(1);
      }}
    >
      <img className={styles.image} alt="next" src={nextIcon} />
    </button>
  );
};

export default NextButton;
