import React from 'react';
import { $transferProcessModalText } from '@data/text.constants';
import { useMobile } from '@hooks/index';
import { useGlobalModalContext } from 'src/contexts';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import store from '@store/index';
import { cancelTransferAsync } from '@store/slices/begin-transfer/beginTransferThunks';
import { getLoggedServiceData } from '@store/slices/music-services/musicService.utils';
import useLibraryModelType from '@features/modal-windows/hooks/useLibraryModelType';
import MediaItemsList from '@components/MediaItemsList';
import { ActiveTransferState } from '@store/slices/active-transfers/activeTransfers.types';
import hideIcon from '../../../assets/hide-modal-icon.svg';
import CancelButton from './components/CancelButton';
import styles from './TransferProcessModal.module.scss';
import ProgressBar from './components/ProgressBar';

type Props = {
  activeTransferId: string;
};

const TransferProcessModal = ({ activeTransferId }: Props) => {
  const { isMobile } = useMobile();
  const { hideModal } = useGlobalModalContext();
  const dispatch = useAppDispatch();
  const activeTransfer = useAppSelector((state) => state.activeTransfers.items
    .find((transfer) => transfer.activeTransferId === activeTransferId))!;

  return (
    <div className={styles.transfer_process_wrapper}>
      <img className={styles.hide_btn} alt="hide" src={hideIcon} role="presentation" onClick={hideModal} />
      <div className={styles.transfer_header_info}>

        <div className={styles.transfer_text}>
          {$transferProcessModalText.transfer}
        </div>
        {isMobile && (
        <div className={styles.transfer_speed_text}>
          {$transferProcessModalText.transferSpeed}
          <br />
          {' '}
          {$transferProcessModalText.internedSpeed}
        </div>
        )}

        <div className={styles.transfer_services}>
          <img alt="srcLogo" src={activeTransfer.sourceInfo?.serviceIcon} />
          <ProgressBar completedPercent={Number.isNaN(activeTransfer.completedProgress)
            ? 0 : activeTransfer.completedProgress ?? 0}
          />
          <img alt="destinationLogo" src={activeTransfer.destinationInfo?.serviceIcon} />
        </div>

        <div className={styles.progress_text}>
          <div className={styles.percent_text}>
            {Number.isNaN(activeTransfer.completedProgress)
              ? 0 : activeTransfer.completedProgress?.toFixed()}
            %
          </div>
          <div className={styles.transfer_status_text}>{activeTransfer.actionStatus}</div>
        </div>

        <div className={styles.horizontal_span} />

        {/* <div className={styles.transfer_info}>
          <div className={styles.items_counter}>
            <div className={styles.transferred_count}>01</div>
            <div>/</div>
            <div>{mediaItems.length}</div>
          </div>
          <div className={styles.items}>
            <MediaItemsList items={mediaItems} />
          </div>
        </div> */}
        <div className={styles.cancel_button}>
          <CancelButton onClick={() => {
            hideModal();
            dispatch(cancelTransferAsync([activeTransfer.activeTransferId!]));
          }}
          />
        </div>
      </div>
    </div>
  );
};

export default TransferProcessModal;
