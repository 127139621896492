import React from 'react';
import store from '@store/index';
import { getLoggedServiceData } from '@store/slices/music-services/musicService.utils';
import closeIcon from '@features/modal-windows/assets/close-icon.svg';
import { $transferStartedModalText } from '@data/text.constants';
import icon from '@assets/transferred-successfully.svg';
import arrow from '@assets/arrow.svg';
import { StyledButton } from '@components/index';
import { useNavigate } from 'react-router-dom';
import $route from '@data/routing.constants';
import styles from './TransferStartedModal.module.scss';
import { useGlobalModalContext } from '../../../../../contexts';

const TransferStartedModal = () => {
  const { hideModal } = useGlobalModalContext();
  const navigate = useNavigate();
  const { sourceService, destinationService } = store.getState().beginTransfer;
  if (!sourceService || !destinationService) {
    throw Error('Source or destination service not selected');
  }
  const sourceServiceIcon = getLoggedServiceData(sourceService).icon;
  const destinationServiceIcon = getLoggedServiceData(destinationService).icon;
  return (
    <div className={styles.modal}>
      <img
        className={styles.close_btn}
        alt="close"
        src={closeIcon}
        role="presentation"
        onClick={hideModal}
      />
      <div className={styles.header}>
        <div className={styles.modal_icon_container}>
          <img className={styles.modal_icon} alt="transferred" src={icon} />
        </div>
        <div className={styles.text_container}>
          <div className={styles.title}>
            {$transferStartedModalText.transferStarted}
          </div>
          <div
            className={styles.description}
          >
            {$transferStartedModalText.transferStartedDescription}
          </div>
        </div>
      </div>
      <div className={styles.services_container}>
        <img
          alt="source"
          src={sourceServiceIcon}
        />
        <img className={styles.arrow} alt="arrow" src={arrow} />
        <img
          alt="dest"
          src={destinationServiceIcon}
        />
      </div>
      <div className={styles.show_progress}>
        <StyledButton
          title={$transferStartedModalText.showActives}
          onClick={() => {
            navigate($route.activeTransfers);
            hideModal();
          }}
        />
      </div>
    </div>
  );
};

export default TransferStartedModal;
