import React from 'react';
import { useAppSelector } from '@store/hooks';
import $route from '@data/routing.constants';
import { getLoggedServiceData } from '@store/slices/music-services/musicService.utils';
import { useNavigate } from 'react-router-dom';
import { useMobile } from '@hooks/responsive';
import { Tooltip } from 'react-tooltip';
import MarqueeText from '@components/MarqueeText';
import formatTrackCountString from 'src/utils/formatTrackCountString';
import styles from './styles/LibraryItemTitleButton.module.scss';

type LibraryItemTitleButtonProps = {
  row: any
};

const LibraryItemTitleButton = ({ row }: LibraryItemTitleButtonProps) => {
  const selectedService = useAppSelector((state) => state.library.selectedService);
  if (!selectedService) {
    throw Error('Service not selected');
  }
  const { selectedTab } = useAppSelector((state) => state.library);
  if (!selectedTab) {
    throw Error('Tab not selected');
  }
  const service = getLoggedServiceData(selectedService);
  const navigate = useNavigate();
  const { isMobile } = useMobile();
  return (
    <>
      <div
        className={styles.button_title}
        role="presentation"
        onClick={() => {
          navigate($route.libraryItem
            .replace(':service', service.type)
            .replace(':type', selectedTab)
            .replace(':id', row.original.id));
        }}
      >
        {isMobile ? (
          <div className={styles.text_container}>
            <div className={styles.marquee_title}>
              <MarqueeText text={row.original.title} />
            </div>
            <div className={styles.subtext_container}>
              {row.original.tracksCount !== null
                  && (row.original.tracksCount || row.original.tracksCount === 0) && (
                  <div>
                    {formatTrackCountString(row.original.tracksCount)}
                  </div>
              )}
              <div className={styles.subtext_artist}>
                <MarqueeText text={row.original.artist} />
              </div>
              <div className={styles.subtext_album}>
                <MarqueeText text={row.original.albumName} />
              </div>
            </div>
          </div>
        ) : (
          <span
            data-tooltip-id={row.original.id}
            data-tooltip-content={row.original.title}
          >
            {row.original.title}
          </span>
        )}
      </div>
      <Tooltip id={row.original.id} className={styles.tooltip} noArrow />
    </>
  );
};

export default LibraryItemTitleButton;
