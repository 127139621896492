import React from 'react';
import {
  ActiveTransferModal,
  AllServicesModal,
  ChooseDestinationModal,
  ConfirmTransferModal,
  ConfirmEmailModal,
  MessageBoxModal,
  ConfirmActionModal,
  ShowReportModal,
  ThankYouModal,
  TransferCanceledModal,
  TransferProcessModal,
  TransferSuccessfulModal,
  TrialVersionModal,
} from '@features/modal-windows';
import TransferStartedModal
  from '@features/modal-windows/components/modals/transfer-started-modal/TransferStartedModal';
import { ModalType } from './ModalContext.types';
import { assertUnreachable } from '../../utils';

const renderModalByType = (modalType: ModalType): JSX.Element => {
  switch (modalType.type) {
    case 'transfer_successful':
      return <TransferSuccessfulModal />;
    case 'transfer_canceled':
      return <TransferCanceledModal />;
    case 'trial_version': {
      const { transferredTracks } = modalType.props;
      return (
        <TrialVersionModal
          transferredTracks={transferredTracks}
        />
      );
    }
    case 'transfer_process': {
      const { activeTransferId } = modalType.props;
      return (<TransferProcessModal activeTransferId={activeTransferId} />);
    }
    case 'confirm_transfer':
      return (<ConfirmTransferModal />);
    case 'choose_destination':
      return (<ChooseDestinationModal />);
    case 'message_box': {
      const { title, description, additionalOnClick } = modalType.props;
      return (
        <MessageBoxModal
          title={title}
          description={description}
          additionalOnClick={additionalOnClick}
        />
      );
    }
    case 'confirm_action': {
      const { title, description, icon } = modalType.props;
      return (
        <ConfirmActionModal title={title} description={description} icon={icon} />
      );
    }
    case 'thank_you':
      return (<ThankYouModal />);
    case 'all_services':
      return (<AllServicesModal />);
    case 'show_report': {
      const { transferResult } = modalType.props;
      return (
        <ShowReportModal
          transferResult={transferResult}
        />
      );
    }
    case 'active_transfer': {
      const { playlistTitle, tracksTransferring } = modalType.props;
      return (
        <ActiveTransferModal
          playlistTitle={playlistTitle}
          tracksTransferring={tracksTransferring}
        />
      );
    }
    case 'confirm_email': {
      const { email } = modalType.props;
      return (
        <ConfirmEmailModal email={email} />
      );
    }
    case 'transfer_started': {
      return <TransferStartedModal />;
    }
    default:
      assertUnreachable(modalType);
      return (<>empty </>);
  }
};

export default renderModalByType;
