//  auth-server endpoints
// for local webapi -> 'http://localhost:5000'
// for local auth -> 'http://localhost:5164'
// for deploy -> 'https://web.musconv.com'
// for test server -> 'http://174.138.34.3'

const authApiUrl = 'https://web.musconv.com';
const authApi = `${authApiUrl}/api`;

const auth = {
  token: `${authApi}/auth/token` as const,
  register: `${authApi}/auth/register` as const,
  googleRegister: `${authApi}/auth/googleRegister` as const,
  googleLogin: `${authApi}/auth/googleLogin` as const,
};

const account = {
  activate: `${authApi}/account/activate` as const,
  forgot: `${authApi}/account/forgot` as const,
  changePassword: `${authApi}/account/changePassword` as const,
};

const admin = {
  getAllUserInfo: `${authApi}/admin/userInfo` as const,
  changeUserLicense: `${authApi}/admin/changeLicense` as const,
};

//  web-api endpoints

const apiUrl = 'https://web.musconv.com';
const api = `${apiUrl}/api`;
const signalr = `${apiUrl}/signalr`;

// web admin

const adminEndpoint = `${api}/admin`;
const webAdmin = {
  resetTrialTracks: `${adminEndpoint}/reset-trial`,
};

const libraryEndpoint = `${api}/library`;
const library = {
  allPlaylists: `${libraryEndpoint}/all-playlists` as const,
  playlists: `${libraryEndpoint}/playlists` as const,
  allTracks: `${libraryEndpoint}/all-tracks` as const,
  tracks: `${libraryEndpoint}/tracks` as const,
  allAlbums: `${libraryEndpoint}/all-albums` as const,
  albums: `${libraryEndpoint}/albums` as const,
  allArtists: `${libraryEndpoint}/all-artists` as const,
  artists: `${libraryEndpoint}/artists` as const,
};

const searchEndpoint = `${api}/search`;
const search = {
  tracks: `${searchEndpoint}/tracks` as const,
  albums: `${searchEndpoint}/albums` as const,
  artists: `${searchEndpoint}/artists` as const,
};

const transferEndpoint = `${api}/transfer`;
const transfer = {
  transferHub: `${signalr}/transfer` as const,
  history: `${transferEndpoint}/history` as const,
  activeTransfers: `${transferEndpoint}/active` as const,
  information: `${transferEndpoint}/information` as const,
  cancel: `${transferEndpoint}/cancel` as const,
  playlists: `${transferEndpoint}/playlists` as const,
  playlistModels: `${transferEndpoint}/playlist-models` as const,
  tracks: `${transferEndpoint}/tracks` as const,
  trackModels: `${transferEndpoint}/track-models` as const,
  albums: `${transferEndpoint}/albums` as const,
  albumModels: `${transferEndpoint}/album-models` as const,
  artists: `${transferEndpoint}/artists` as const,
  artistModels: `${transferEndpoint}/artist-models` as const,
};

const serviceUserEndpoint = `${api}/user`;
const serviceUser = {
  profile: `${serviceUserEndpoint}/profile` as const,
  authorizedServices: `${serviceUserEndpoint}/authorized-services` as const,
  removeCredentials: `${serviceUserEndpoint}/remove-credentials` as const,
  sendCredentials: `${serviceUserEndpoint}/send-credentials` as const,
  receiveCredentials: `${serviceUserEndpoint}/receive-credentials` as const,
};

const settingsEndpoint = `${api}/settings`;
const settings = {
  all: `${settingsEndpoint}/all`,
  update: `${settingsEndpoint}/update`,
  default: `${settingsEndpoint}/default`,
};

// service auth

const serviceAuthEndpoint = `${api}/service-auth`;
const oauth = `${serviceAuthEndpoint}/oauth`;
const loginPassword = `${serviceAuthEndpoint}/login-password`;
const token = `${serviceAuthEndpoint}/token`;
const serviceAuth = {
  oauthRedirectUri: `${oauth}/redirect-uri` as const,
  oauthCallback: `${oauth}/callback` as const,
  loginPasswordAuth: `${loginPassword}/authorize` as const,
  tokenAuth: `${token}/authorize` as const,
};

// keys

const keysEndpoint = `${api}/keys`;
const keys = {
  appleDevToken: `${keysEndpoint}/apple-dev-token`,
};

const $api = {
  auth,
  account,
  admin,

  webAdmin,
  library,
  search,
  transfer,
  serviceUser,
  settings,
  serviceAuth,
  keys,
};

export default $api;
