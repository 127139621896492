import React, { KeyboardEvent, useEffect } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import authLoader from '@assets/animations/auth-loader.json';
import styles from './styles/StyledButton.module.scss';

type Props = {
  title: string;
  onClick: () => void;
  outlined?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  keyBind?: string;
};

const StyledButton = ({
  title, onClick, outlined = false, isLoading = false, isDisabled = false, keyBind,
}: Props) => {
  const onPress = (event: KeyboardEvent) => {
    if (!isDisabled && event.key === keyBind) {
      onClick();
    }
  };

  useEffect(() => () => {
    // @ts-ignore
    document.removeEventListener('keydown', onPress);
  });

  if (keyBind) {
    // @ts-ignore
    document.addEventListener('keydown', onPress);
  }

  return (
    <div
      className={isDisabled ? styles.btn_dis_wrapper : styles.btn_wrapper}
      onClick={isDisabled ? undefined : onClick}
      role="button"
      tabIndex={0}
      onKeyDown={undefined}
    >
      {isLoading
        ? <Player src={authLoader} loop autoplay className={styles.loader} />
        : <div className={styles.btn_title}>{title}</div>}
      <div className={outlined ? styles.btn_outline : styles.btn} />
    </div>
  );
};

export default StyledButton;
