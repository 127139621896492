import React from 'react';
import closeIcon from '@features/modal-windows/assets/close-icon.svg';
import { StyledButton } from '@components/index';
import styles
  from './TransferEndedModal.module.scss';
import { useGlobalModalContext } from '../../../../../../contexts';

type Props = {
  modalIcon: string,
  modalText: string,
  buttonText: string,
};

const TransferEndedModal = ({ modalIcon, modalText, buttonText }: Props) => {
  const { hideModal } = useGlobalModalContext();
  return (
    <div className={styles.transfer_ended_wrapper}>
      <img
        className={styles.close_btn}
        alt="close"
        src={closeIcon}
        role="presentation"
        onClick={hideModal}
      />
      <img className={styles.modal_icon} alt="modalIcon" src={modalIcon} />
      <h1 className={styles.modal_text}>
        {modalText}
      </h1>
      {/* {isMobile && (
          <div className={styles.items}>
            <MediaItemsList items={mediaItems} />
          </div>
          )} */}
      <div className={styles.modal_button}>
        {/* TODO: Add redirect to report page */}
        <StyledButton title={buttonText} onClick={hideModal} />
      </div>
    </div>
  );
};

export default TransferEndedModal;
