import { inject, injectable } from 'inversify';
import { type IHttpClient } from '@lib/index';
import $api from '@data/api.constants';
import $responseTypes from '@data/response.types.constants';
import $serviceType from '@data/service.types.constant';
import { IAuthService } from './AuthService.interface';
import {
  LoginResponse,
  LoginError,
  LoginSuccess,
  RegisterError,
  RegisterResponse,
  LoginModel,
  RegisterModel,
  RegisterSuccess,
  GoogleSignModel,
  GoogleSignResponse,
  GoogleSignSuccess,
  GoogleSignError,
} from './AuthService.types';

type TokenRequest = {
  grantType: 'password';
  email: string;
  password: string;
} | {
  grantType: 'refresh';
  accessToken: string;
  refreshToken: string;
};

@injectable()
export default class AuthService implements IAuthService {
  @inject($serviceType.http) private http: IHttpClient;

  async login(email: string, password: string): Promise<LoginModel> {
    const requestData = { grantType: 'password', email, password } as TokenRequest;
    const response = await this.http.post<LoginResponse>($api.auth.token, requestData);

    if (response.status === 200) {
      const data = response.data as LoginSuccess;
      return {
        status: 'ok',
        refreshToken: data.refreshToken,
        accessToken: data.accessToken,
      };
    }

    const data = response.data as LoginError;
    return {
      status: 'error',
      type: data.type,
    };
  }

  async register(email: string, password: string): Promise<RegisterModel> {
    const requestData = { email, password };
    const response = await this.http.post<RegisterResponse>($api.auth.register, requestData);

    if (response.status === 200) {
      const data = response.data as RegisterSuccess;
      return {
        type: 'ok',
        refreshToken: data.refreshToken,
        accessToken: data.accessToken,
      };
    }

    if (response.status === 400) {
      const data = response.data as RegisterError;
      if (data.type === $responseTypes.auth.accAlreadyExist) {
        return {
          type: 'error',
          message: `Email ${email} already registered.`,
        };
      }
    }

    return {
      type: 'error',
      message: 'Unexpected error',
    };
  }

  async googleRegister(token: string): Promise<GoogleSignModel> {
    const requestData = { token };
    const response = await this.http.post<GoogleSignResponse>(
      $api.auth.googleRegister,
      requestData,
    );

    if (response.status === 200) {
      const data = response.data as GoogleSignSuccess;
      return {
        type: 'ok',
        email: data.userEmail,
        refreshToken: data.refreshToken,
        accessToken: data.accessToken,
      };
    }

    if (response.status === 400) {
      const data = response.data as GoogleSignError;
      if (data.type === $responseTypes.auth.accAlreadyExist) {
        return {
          type: 'error',
          message: data.detail,
        };
      }
    }

    return {
      type: 'error',
      message: 'Google Authentication Failed',
    };
  }

  async googleLogin(token: string): Promise<GoogleSignModel> {
    const requestData = { token };
    const response = await this.http.post<GoogleSignResponse>(
      $api.auth.googleLogin,
      requestData,
    );

    if (response.status === 200) {
      const data = response.data as GoogleSignSuccess;
      return {
        type: 'ok',
        email: data.userEmail,
        refreshToken: data.refreshToken,
        accessToken: data.accessToken,
      };
    }

    if (response.status === 400) {
      const data = response.data as GoogleSignError;
      if (data.type === $responseTypes.auth.accAlreadyExist) {
        return {
          type: 'error',
          message: 'Something went wrong with Google sign in.',
        };
      }
    }

    return {
      type: 'error',
      message: 'Google Authentication Failed',
    };
  }
}
