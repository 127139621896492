import { inject, injectable } from 'inversify';
import $serviceType from '@data/service.types.constant';
import { type IHttpClient } from '@lib/index';
import {
  PlaylistExtended,
  Track,
  AlbumSimplified,
  Artist,
  MusicItemMinimal,
} from '@services/types/music.items.types';
import { CredentialsModel, ResponseError } from '@services/types/webapi.types';
import $api from '@data/api.constants';
import { IApiResponse } from '@lib/http/HttpClient.types';
import MusicItemType from '@data/enums/musicItemType';
import { ITransferService } from './TransferService.interface';
import {
  InitTransferAlbumsRequest,
  InitTransferArtistsRequest,
  InitTransferPlaylistsRequest,
  InitTransferResponse,
  InitTransferSuccess,
  InitTransferModel,
  InitTransferTracksRequest,
  InitTransferPlaylistModelsRequest,
  InitTransferTrackModelsRequest,
  InitTransferAlbumModelsRequest,
  InitTransferArtistModelsRequest,
  CancelTransferModel,
  TransferItemModel,
  TransferItemsModel,
  TransferItemRequest,
  TransferItemResponse,
  TransferItemSuccess,
  CancelTransferResponse,
  TransferItemsResponse,
  TransferItemsSuccess,
  TransferHistoryRequest,
} from './TransferService.types';

@injectable()
export default class TransferService implements ITransferService {
  @inject($serviceType.http) private http: IHttpClient;

  async getTransferItem(
    transferId: string,
  ): Promise<TransferItemModel> {
    const requestData = {
      transferId,
    } as TransferItemRequest;

    const response = await this.http.post<TransferItemResponse>(
      $api.transfer.information,
      requestData,
    );

    if (response.status === 200) {
      const data = response.data as TransferItemSuccess;
      return {
        type: 'ok',
        transferItem: data.transferItem,
      };
    }

    const data = response.data as ResponseError;
    return {
      type: 'error',
      message: data.title,
    };
  }

  async cancelTransfer(
    transferId: string,
  ): Promise<CancelTransferModel> {
    const requestData = {
      transferId,
    } as TransferItemRequest;

    const response = await this.http.post<CancelTransferResponse>(
      $api.transfer.cancel,
      requestData,
    );

    if (response.status === 200) {
      return {
        type: 'ok',
      };
    }

    const data = response.data as ResponseError;
    return {
      type: 'error',
      message: data.title,
    };
  }

  async getActiveTransfers(): Promise<TransferItemsModel> {
    const response = await this.http.post<TransferItemsResponse>(
      $api.transfer.activeTransfers,
    );

    if (response.status === 200) {
      const data = response.data as TransferItemsSuccess;
      return {
        type: 'ok',
        transferItems: data.transferItems,
      };
    }

    const data = response.data as ResponseError;
    return {
      type: 'error',
      message: data.title,
    };
  }

  async getTransferHistory(
    transferType?: MusicItemType,
  ): Promise<TransferItemsModel> {
    const requestData = {
      transferType,
    } as TransferHistoryRequest;

    const response = await this.http.post<TransferItemsResponse>(
      $api.transfer.history,
      requestData,
    );

    if (response.status === 200) {
      const data = response.data as TransferItemsSuccess;
      return {
        type: 'ok',
        transferItems: data.transferItems,
      };
    }

    const data = response.data as ResponseError;
    return {
      type: 'error',
      message: data.title,
    };
  }

  /* Init transfer */

  private getTransferTaskModel(
    response: IApiResponse<InitTransferResponse>,
  ): InitTransferModel {
    if (response.status === 200) {
      const data = response.data as InitTransferSuccess;
      return {
        type: 'ok',
        transferId: data.transferId,
      };
    }

    const data = response.data as ResponseError;
    return {
      type: 'error',
      message: data.title,
    };
  }

  private async transferItems<ItemType, RequestType>(
    endpoint: string,
    sourceCredentials: CredentialsModel,
    destinationCredentials: CredentialsModel,
    items: Array<ItemType>,
  ): Promise<InitTransferModel> {
    const requestData = {
      sourceCredentials,
      destinationCredentials,
      items,
    } as RequestType;

    const response = await this.http.post<InitTransferResponse>(
      endpoint,
      requestData,
    );

    return this.getTransferTaskModel(response);
  }

  transferPlaylists(
    sourceCredentials: CredentialsModel,
    destinationCredentials: CredentialsModel,
    playlists: MusicItemMinimal[],
  ): Promise<InitTransferModel> {
    return this.transferItems<MusicItemMinimal, InitTransferPlaylistsRequest>(
      $api.transfer.playlists,
      sourceCredentials,
      destinationCredentials,
      playlists,
    );
  }

  transferPlaylistModels(
    sourceCredentials: CredentialsModel,
    destinationCredentials: CredentialsModel,
    playlists: PlaylistExtended[],
  ): Promise<InitTransferModel> {
    return this.transferItems<PlaylistExtended, InitTransferPlaylistModelsRequest>(
      $api.transfer.playlistModels,
      sourceCredentials,
      destinationCredentials,
      playlists,
    );
  }

  transferTracks(
    sourceCredentials: CredentialsModel,
    destinationCredentials: CredentialsModel,
    tracks: MusicItemMinimal[],
  ): Promise<InitTransferModel> {
    return this.transferItems<MusicItemMinimal, InitTransferTracksRequest>(
      $api.transfer.tracks,
      sourceCredentials,
      destinationCredentials,
      tracks,
    );
  }

  transferTrackModels(
    sourceCredentials: CredentialsModel,
    destinationCredentials: CredentialsModel,
    tracks: Track[],
  ): Promise<InitTransferModel> {
    return this.transferItems<Track, InitTransferTrackModelsRequest>(
      $api.transfer.trackModels,
      sourceCredentials,
      destinationCredentials,
      tracks,
    );
  }

  transferAlbums(
    sourceCredentials: CredentialsModel,
    destinationCredentials: CredentialsModel,
    albums: MusicItemMinimal[],
  ): Promise<InitTransferModel> {
    return this.transferItems<MusicItemMinimal, InitTransferAlbumsRequest>(
      $api.transfer.albums,
      sourceCredentials,
      destinationCredentials,
      albums,
    );
  }

  transferAlbumModels(
    sourceCredentials: CredentialsModel,
    destinationCredentials: CredentialsModel,
    albums: AlbumSimplified[],
  ): Promise<InitTransferModel> {
    return this.transferItems<AlbumSimplified, InitTransferAlbumModelsRequest>(
      $api.transfer.albumModels,
      sourceCredentials,
      destinationCredentials,
      albums,
    );
  }

  async transferArtists(
    sourceCredentials: CredentialsModel,
    destinationCredentials: CredentialsModel,
    artists: MusicItemMinimal[],
  ): Promise<InitTransferModel> {
    return this.transferItems<MusicItemMinimal, InitTransferArtistsRequest>(
      $api.transfer.artists,
      sourceCredentials,
      destinationCredentials,
      artists,
    );
  }

  async transferArtistModels(
    sourceCredentials: CredentialsModel,
    destinationCredentials: CredentialsModel,
    artists: Artist[],
  ): Promise<InitTransferModel> {
    return this.transferItems<Artist, InitTransferArtistModelsRequest>(
      $api.transfer.artistModels,
      sourceCredentials,
      destinationCredentials,
      artists,
    );
  }
}
