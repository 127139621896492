import React, { useEffect, useMemo, useState } from 'react';
import getSortedServices from '@store/slices/music-services/musicService.utils';
import dotsIcon from '@features/main-page/assets/triple-dots.svg';
import BlankWideButton
  from '@features/modal-windows/components/modals/all-services-modal/components/BlankWideButton';
import { $availableServicesText } from '@data/text.constants';
import AvailableServicesDescriptionComponent
  from '@features/main-page/components/other/AvailableServicesDescriptionComponent';
import $urls from '@data/url.constants';
import { useGlobalModalContext } from '../../../../../../contexts';
import SourceServiceButton from './SourceServiceButton';
import styles from './styles/SourceServicesList.module.scss';

type Props = {
  query: string;
};

/**
 * List of source service-icons.
 *
 * @param query - to show service-icons due to search query.
 */
const SourceServicesList = ({ query }: Props) => {
  const { showModal } = useGlobalModalContext();
  const sortedServices = useMemo(() => getSortedServices(), []);

  const [sourceList, setSourceList] = useState(sortedServices);
  useEffect(() => {
    const filteredServices = sortedServices.filter(
      (service) => service.data.title.toLowerCase()
        .includes(query.toLowerCase()),
    );
    setSourceList(filteredServices);
  }, [query]);
  const services = sourceList.map((service) => (
    <SourceServiceButton
      key={service.data.title}
      service={service.data}
      isConnected={service.isConnected}
      email={service.isConnected ? service.loggedData.email : undefined}
    />
  ));
  return (
    <div className={styles.grid_tiles}>
      {services}
      <BlankWideButton
        icon={dotsIcon}
        onClick={() => showModal(
          {
            type: 'message_box',
            props: {
              title: $availableServicesText.thanksForUsingMusConv,
              description: <AvailableServicesDescriptionComponent />,
              additionalOnClick: () => window.open($urls.download),
            },
          },
        )}
      />
    </div>
  );
};
export default SourceServicesList;
