import React from 'react';
import arrowIcon from '@assets/arrow.svg';
import successIcon from '@assets/status-icons/successfully.svg';
import warningIcon from '@assets/status-icons/warning.svg';
import errorIcon from '@assets/status-icons/error.svg';
import { RecentTransfer } from '@store/slices/recent-transfers/recentTransfers.types';
import { getMusicService } from '@store/slices/music-services/musicService.utils';
import defaultServiceIcon from '@assets/service-icons/default-service-icon.svg';
import styles from './styles/RecentTransferButton.module.scss';
import chevronImg from '../../main-page/assets/next-button.svg';

type Props = {
  recentTransfer: RecentTransfer;
};

const RecentTransferButton = ({
  recentTransfer,
}: Props) => {
  const sourceIcon = getMusicService(recentTransfer.sourceService)?.icon;
  const destinationIcon = getMusicService(recentTransfer.destinationService)?.icon;
  let transferResult;
  let transferResultIcon;
  if (recentTransfer.isSuccessful) {
    transferResult = 'Successful';
    transferResultIcon = successIcon;
  } else if (recentTransfer.isCanceled) {
    transferResult = 'Cancelled';
    transferResultIcon = warningIcon;
  } else {
    transferResult = 'Failed';
    transferResultIcon = errorIcon;
  }
  return (
    <button className={styles.container} type="button">
      <div className={styles.transfer_result_container}>
        <img
          className={styles.transfer_result_icon}
          src={transferResultIcon}
          alt="transferResultIcon"
          data-tooltip-id="transfer-result-icon"
          data-tooltip-content={transferResult}
        />
      </div>
      <img
        className={styles.service_icon}
        src={sourceIcon ?? defaultServiceIcon}
        alt="serviceIcon"
      />
      <img className={styles.arrow_icon} src={arrowIcon} alt="arrowIcon" />
      <img
        className={styles.service_icon}
        src={destinationIcon ?? defaultServiceIcon}
        alt="serviceIcon"
      />
      <div className={styles.count_text}>
        {recentTransfer.countOfTransferred}
        {' '}
        {recentTransfer.countOfTransferred === 1 ? recentTransfer.type : `${recentTransfer.type}s`}
      </div>
      <div className={styles.date_text}>{recentTransfer.date}</div>
      {/* <div className={styles.chevron_img_container}>
        <img className={styles.chevron_img} src={chevronImg} alt="chevron" />
      </div> */}
    </button>
  );
};

export default RecentTransferButton;
