import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ItemWithTracksLibraryModel,
} from '@store/slices/library/library.types';
import { RootState } from '@store/index';
import { MusicItemBaseWithTracks, MusicItemMinimal } from '@services/types/music.items.types';
import { LibraryTabType } from '@store/slices/types';
import { $libraryItemText as text } from '@data/text.constants';
import { CredentialsModel } from '@services/index';
import { ItemsModel } from '@services/library/LibraryService.types';
import { getLibraryService } from '../../../../inversify.config';
import { tryGetLargeImage, tryGetSmallImage } from '../library.helpers';

const mapTab = async (
  type: LibraryTabType,
  creds: CredentialsModel,
  ids: Array<MusicItemMinimal>,
): Promise<ItemsModel<MusicItemBaseWithTracks>> => {
  const libraryService = getLibraryService();
  switch (type) {
    case 'playlists':
      return libraryService.getPlaylists(creds, ids);
    case 'albums':
      return libraryService.getAlbums(creds, ids);
    case 'artists':
      return libraryService.getArtists(creds, ids);
    default:
      return libraryService.getPlaylists(creds, ids);
  }
};

const getItemTypeFromTab = (type: LibraryTabType) : string => {
  switch (type) {
    case 'playlists':
      return text.playlist;
    case 'albums':
      return text.album;
    case 'artists':
      return text.artist;
    default:
      return text.playlist;
  }
};

type FetchLibraryConfig = {
  rejectValue: string;
  state: RootState;
};

type ReturnType = {
  itemDetails: ItemWithTracksLibraryModel;
};

type LibraryItemType = {
  id: string;
  selectedTab: LibraryTabType;
};

const getLibraryItemByIdAsync = createAsyncThunk<
ReturnType,
LibraryItemType,
FetchLibraryConfig
>(
  'library/fetchLibraryItemById',
  async (arg, thunkAPI) => {
    const checkRequestId = () => {
      if (thunkAPI.getState().libraryItem.lastRequestId !== thunkAPI.requestId) {
        thunkAPI.abort();
      }
    };
    const { selectedService } = thunkAPI.getState().library;

    if (!selectedService) {
      throw Error('Service not selected');
    }

    if (!selectedService || !selectedService.userId) {
      return thunkAPI.rejectWithValue('error from logged service');
    }
    const creds = {
      musicService: selectedService.type,
      serviceUserId: selectedService.userId,
    };
    const itemIds = Array<MusicItemMinimal>({ id: arg.id });
    checkRequestId();

    const result = await mapTab(arg.selectedTab, creds, itemIds);
    if (result.type === 'error') {
      return thunkAPI.rejectWithValue('error from webapi');
    }

    checkRequestId();
    const mediaItem = result.items[0];
    return {
      itemDetails: {
        id: mediaItem.id,
        imageLink: tryGetLargeImage(mediaItem.imageLinks),
        title: mediaItem.title,
        tracksCount: mediaItem.tracksCount,
        isSelected: false,
        isVisible: true,
        description: mediaItem.description,
        url: mediaItem.url,
        type: getItemTypeFromTab(arg.selectedTab),
        tracks: mediaItem.tracks.map((track) => ({
          id: track.id,
          imageLink: getItemTypeFromTab(arg.selectedTab) !== 'album'
            ? tryGetSmallImage(track.imageLinks)
            : tryGetSmallImage(mediaItem.imageLinks),
          isSelected: false,
          isVisible: true,
          title: track.title,
          artist: track.artist,
          artistId: track.artistId,
          albumName: track.album,
          duration: track.duration,
          url: track.url,
          type: 'track',
        })),
      } as ItemWithTracksLibraryModel,
    };
  },
);
export default getLibraryItemByIdAsync;
