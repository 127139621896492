import React from 'react';
import { StyledButton, StyledInput } from '@components/index';
import { $forgottenPasswordText } from '@data/text.constants';
import animation from '@assets/animations/forgot_password.json';
import { useForgotPassword } from '@features/authorization/hooks';
import { useEmailValidation } from '../../hooks/validations';
import IconContainer from '../IconContainer';
import styles from './styles/ForgotPasswordForm.module.scss';

const ForgotPasswordForm = () => {
  const {
    emailField,
    setEmailField,
    validEmailField,
    emailValidation,
  } = useEmailValidation();

  const {
    send,
  } = useForgotPassword();

  const resetPassword = async () => {
    if (validEmailField()) {
      await send(emailField);
    }
  };

  return (
    <IconContainer icon={animation} title={$forgottenPasswordText.title}>
      <div className={styles.forgot_password_container}>
        <StyledInput
          type="email"
          value={emailField}
          title={$forgottenPasswordText.enterEmail}
          onChange={(e) => setEmailField(e.target.value)}
          placeholder={$forgottenPasswordText.enterEmail}
          validation={emailValidation}
        />
        <div className={styles.lower_panel}>
          <StyledButton title={$forgottenPasswordText.resetPassword} onClick={resetPassword} />
          <hr />
          <div className={styles.lower_text}>
            {$forgottenPasswordText.lowerMessage}
          </div>
        </div>
      </div>
    </IconContainer>
  );
};

export default ForgotPasswordForm;
