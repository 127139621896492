import React from 'react';
import styles from './EmojiButton.module.scss';

type Props = {
  icon: string,
  grade: number,
  onClick: (grade: number) => void
};

/**
 * Emoji button.
 *
 * @param icon - string - path to emoji icon.
 * @param grade - number - number of grade for button (1-5).
 * @param onClick - delegate to handle click and set selected grade.
 */

const EmojiButton = ({
  icon, grade, onClick,
}: Props) => (
  <button className={styles.btn} type="button" onClick={() => onClick(grade)}>
    <img src={icon} alt="icon" />
  </button>
);
export default EmojiButton;
