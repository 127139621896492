import React from 'react';
import BlankComponent from '@components/blank/BlankComponent';
import icon from '@assets/icons/empty-library-icon.svg';
import { $emptyLibraryBlankText } from '@data/text.constants';

const EmptyLibraryBlank = () => (
  <BlankComponent
    icon={icon}
    title={$emptyLibraryBlankText.title}
    description={$emptyLibraryBlankText.description}
  />
);

export default EmptyLibraryBlank;
